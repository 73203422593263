import React from 'react'

import { FormattedMessage } from 'react-intl'

import { Button, CustomDialog, CustomDialogHeader } from '@components/ui'

import { CancelButtonMessage, FilingButtonMessage } from '@messages'
import { ListActionsDialogActions, ListActionsDialogBody } from './styles'

interface BulkFilingDialogProps {
  children: React.ReactNode
  handleFiling: VoidFunction
  onClose: VoidFunction
  open: boolean
}

export function BulkFilingDialog({ children, handleFiling, onClose, open }: BulkFilingDialogProps) {
  return (
    <CustomDialog open={open} onClose={onClose}>
      <CustomDialogHeader
        title={<FormattedMessage id="dialogs.filing.confirmTitle" defaultMessage="Biztosan iktatod?" />}
        borderless
      />
      <ListActionsDialogBody>{children}</ListActionsDialogBody>
      <ListActionsDialogActions borderless>
        <Button variant="primaryContained" onClick={handleFiling}>
          {FilingButtonMessage}
        </Button>
        <Button variant="primaryText" onClick={onClose}>
          {CancelButtonMessage}
        </Button>
      </ListActionsDialogActions>
    </CustomDialog>
  )
}
