// always sync with ExpenseDetailsStatus in invoice.d.ts
export enum InvoiceStatus {
  INITIALIZE = 'INITIALIZE',
  CREATE_ARTIFACT = 'CREATE_ARTIFACT',
  CLASSIFY = 'CLASSIFY',
  ASSIGN = 'ASSIGN',
  CLASSIFIED = 'CLASSIFIED',
  UPDATE = 'UPDATE',
  CHECKED = 'CHECKED',
  EXPORTED = 'EXPORTED',
}

export enum InvoiceDownloadFlow {
  WITHOUT_ATTACHMENTS = 'without',
  MERGE_ATTACHMENTS = 'merge',
  WITH_ATTACHMENTS = 'with',
}

// copy from BE
export const INCOME_TYPE_INVOICE = 1
// export const INCOME_TYPE_AID = 2
// export const INCOME_TYPE_COMPENSATION = 3
// export const INCOME_TYPE_SEVERANCE_PAY = 4
// export const INCOME_TYPE_DISCOUNT = 5
export const INCOME_TYPE_CASH_REGISTER_VOUCHER = 6
export const INCOME_TYPE_NYUGTA_OSSZESITO = 7
