import React from 'react'

export function useIntercomProps<Element = HTMLButtonElement | HTMLAnchorElement>(
  className?: string,
  onClick?: React.MouseEventHandler<Element>
) {
  return React.useMemo(() => {
    if (!window.intercomAppId && process.env.NODE_ENV === 'development') {
      return {
        className,
        onClick: (event: React.MouseEvent<Element>) => {
          alert('[Development Mode] Launch Intercom')
          onClick?.(event)
        },
      }
    } else {
      return {
        className,
        onClick,
      }
    }
  }, [className, onClick])
}
