import React from 'react'

import { Link, To } from 'react-router-dom'
import styled from 'styled-components'

import { RightArrowLineIcon } from '@components/ui/svgIcons'

import { Button, StyleOnlyProps } from './Button'

//! Implements this: https://www.figma.com/file/pINtxgS5BQYJFEbApKOykH/QUiCK-Design-System-1.0?type=design&node-id=1135-2176&t=vM93XaGvMU4uuR6g-0

const StyledButton = styled(Button)<{ $gap: number }>`
  display: flex;
  align-items: center;
  gap: ${({ $gap }) => `${$gap}px`};
`

const IconWrapperSpan = styled.span`
  display: inherit;
  align-items: inherit;
`

interface CommonProps extends StyleOnlyProps {
  children: React.ReactNode
  className?: string
  icon?: JSX.Element
  iconPosition?: 'start' | 'end'
  onClick?: VoidFunction
  title?: string
  withoutIcon?: boolean
}

export interface LinkButtonAnchorProps extends CommonProps {
  href: string
  target?: '_blank' | '_self' | '_parent' | '_top'
  to?: never
}

interface LinkButtonLinkProps extends CommonProps {
  href?: never
  target?: never
  to: To
}

export type LinkButtonProps = LinkButtonAnchorProps | LinkButtonLinkProps

/**
 * Renders a button that can be used as a Link or an anchor tag.
 *
 * The 'to' prop is used for the Link component.
 * The 'href' prop is used for the 'a' tag (the 'target' and the 'rel' props can be overwritten too).
 * The 'withoutIcon' prop is used for hide icon
 * Other props are passed to the Button component.
 *
 * The button displays an icon to the right of the button text. By default, it shows a right arrow icon.
 * An optional custom icon can be specified using the 'icon' prop.
 *
 * @param {object} props - The properties for the LinkButton component.
 * @returns {React.ReactNode} - The rendered LinkButton component.
 */
export function LinkButton({ icon, iconPosition = 'end', children, withoutIcon = false, ...rest }: LinkButtonProps) {
  const tag = rest.href ? 'a' : Link

  return (
    <StyledButton
      as={tag}
      {...(rest.href && { target: '_blank', rel: 'noopener noreferrer' })}
      {...rest}
      $gap={iconPosition === 'start' ? 4 : 12}
    >
      <>
        {!withoutIcon && icon && iconPosition === 'start' && <IconWrapperSpan>{icon}</IconWrapperSpan>}
        {children}
        {!withoutIcon && iconPosition === 'end' && <IconWrapperSpan>{icon ?? <RightArrowLineIcon />}</IconWrapperSpan>}
      </>
    </StyledButton>
  )
}
