import { QuickUserFeaturePermissions } from '@constants'

import { FeaturePermissons, isPlanPermissionEnabled, permissionDeniedForUser } from '@permissions'

//* Company advanced settings
function isCompanySettingEnabled(state: Store | Company, feature: FeaturePermissons, isCompanyState = false) {
  const isEnabled = isPlanPermissionEnabled(state, feature, isCompanyState)

  if (isEnabled) {
    if (isCompanyState) return (state as Company)?.[feature] ?? false
    return (state as Store).auth.company.data?.[feature] ?? false
  }

  return false
}

//* available in all tiers
export function isAdvancedAccountingAvailableForUser(state: Store) {
  return (
    isCompanySettingEnabled(state, FeaturePermissons.ADVANCED_ACCOUNTING) &&
    !permissionDeniedForUser(state, QuickUserFeaturePermissions.denyAccountingPermission)
  )
}

//* only available in pro tier
export function isRequireApprovalForPaymentEnabled(state: Store) {
  return isCompanySettingEnabled(state, FeaturePermissons.REQUIRE_APPROVAL_FOR_PAYMENT)
}

export function isAdvancedApprovalEnabled(state: Store) {
  return isCompanySettingEnabled(state, FeaturePermissons.ADVANCED_APPROVAL)
}
