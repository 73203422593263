import React from 'react'

import __partition from 'lodash/partition'
import { defineMessages, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { getSelectableOptions, orderSelectOptions } from '../helpers'
import { BaseSelectInput } from './BaseSelectInput'
import { AdvancedApprovalTagSelectInputProps } from './types'

const messages = defineMessages({
  approverOptionsLabel: {
    id: 'select.advancedApproval.labels.approverOptionsLabel',
    defaultMessage: 'Jóváhagyásra jogosultak',
  },
  optionsLabel: {
    id: 'select.advancedApproval.labels.optionsLabel',
    defaultMessage: 'Létező címkék',
  },
})

const LABEL_KEY: keyof CommonIdAndNameType = 'name'
const VALUE_KEY: keyof CommonIdAndNameType = 'id'
const IS_MULTI = true

export function PureAdvancedApprovalTagSelectInput({
  approverUsers,
  options,
  skipSorting = false,
  value,
  ...rest
}: AdvancedApprovalTagSelectInputProps<Tag>) {
  const { formatMessage } = useIntl()
  const [inputValue, setInputValue] = React.useState('')

  const onInputChange = React.useCallback(
    (newValue: string) => {
      setInputValue(newValue)
    },
    [setInputValue]
  )

  //! Problem - performance issue when Tag list is large
  // incasesensitive order
  const orderedOptions = React.useMemo(
    () => orderSelectOptions(options, LABEL_KEY, skipSorting),
    [options, skipSorting]
  )

  // @metion feature is active (inputValue does start with '@')
  const mentionFeatureAvailable = inputValue.startsWith('@')
  const selectOptions = React.useMemo(() => {
    // @mention feature can not work without approverUsers
    if (approverUsers.length === 0 || !mentionFeatureAvailable) {
      return orderedOptions
    }

    const approverNamesSet = new Set(approverUsers.map(({ name }) => `@${name}`))

    //! Problem - performance issue when Tag list is large
    const [existingApproverTags, existingTags] = __partition(orderedOptions, (tag: Tag) =>
      approverNamesSet.has(tag.name)
    )

    //* @mention feature
    const existingApproverNamesSet = new Set(existingApproverTags.map(({ name }) => name))
    const newApprovers = approverUsers
      .filter(({ name }) => !existingApproverNamesSet.has(`@${name}`))
      .map(
        ({ name }) =>
          ({
            id: `@${name}`, // this value will be used when create new tag
            name: `@${name}`,
            needToCreate: true, // notify onChange function to create before select it
          } as unknown as Tag)
      )

    return [
      {
        label: formatMessage(messages.approverOptionsLabel),
        options: orderSelectOptions([...newApprovers, ...existingApproverTags], LABEL_KEY, skipSorting),
      },
      {
        label: formatMessage(messages.optionsLabel),
        options: existingTags,
      },
    ]
  }, [approverUsers, formatMessage, mentionFeatureAvailable, orderedOptions, skipSorting])

  const getValue = React.useCallback(
    value => {
      const selectableOptions = getSelectableOptions(options)

      // only multi select available
      return orderSelectOptions(
        selectableOptions.filter(option => value.includes(option[VALUE_KEY])),
        LABEL_KEY,
        skipSorting
      )
    },
    [options, skipSorting]
  )

  return (
    <BaseSelectInput
      {...rest}
      inputValue={inputValue}
      isMulti={IS_MULTI}
      labelKey={LABEL_KEY}
      onInputChange={onInputChange}
      selectOptions={selectOptions}
      selectValue={getValue(value)}
      useWindowedMenuList={options.length > 9}
      valueKey={VALUE_KEY}
    />
  )
}

export const AdvancedApprovalTagSelectInput = connect((state: Store) => ({
  approverUsers: state.dashboard.approverUsers,
}))(PureAdvancedApprovalTagSelectInput)

AdvancedApprovalTagSelectInput.displayName = 'AdvancedApprovalTagSelectInput'
