import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { applyOpacity, getPulseRedirectPreferredForCompanyType, getRouteUrl } from '@helpers'

import { useSubscription } from '@contexts'

import { PulseDialogs } from '@components/pages/PulsePage'
import { Accordion, Button, CustomDialog, CustomDialogBody, ExternalLink } from '@components/ui'
import { CircleCheckIcon, CircleXmarkRegularIcon } from '@components/ui/svgIcons'
import { Typography } from '@components/ui/Typography'
import { FlexColumn } from '@components/ui/Wrappers'

import { LINKS, RouteKeys } from '@constants'

import BraintreeContainer from '../BraintreeContainer'
import { PriceBox } from '../SubscriptionCard'
import { SubscriptionPlanChange } from './SubscriptionPlanChange'
import { SubscriptionTrial } from './SubscriptionTrial'

import { DialogHeader } from '@components/ui/Dialogs/styles'
import { CloseButtonMessage, FinishButtonMessage, GoToQuickButtonMessage } from './messages'

const StyledCustomDialog = styled(CustomDialog)`
  & > * {
    width: 375px;
  }
`
const StyledDialogHeader = styled(DialogHeader)`
  padding: 0 0 8px;
`

const StyledCustomDialogBody = styled(CustomDialogBody)`
  min-height: 144px;
`

const StyledHeaderContainer = styled.div<{ $successView?: boolean }>`
  min-height: ${({ $successView }) => ($successView ? 198 : 144)}px;
  background: ${({ theme }) => theme.colors.blue[30]};
  position: relative;
  padding: 20px 20px 56px;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.colors.gray[0]};
  z-index: 0;
  &:before {
    content: '';
    position: absolute;
    inset: 0;
    background: ${({ theme }) => `linear-gradient(180deg, transparent, ${applyOpacity(theme.colors.gray[0], 30)})`};
    z-index: -1;
  }

  ${({ $successView }) =>
    $successView &&
    css`
      & > ${Typography}:first-child {
        margin-bottom: 24px;
      }
    `}
`

const StyledPriceBox = styled(PriceBox)`
  position: absolute;
  bottom: -32px;
  left: 50%;
  transform: translateX(-50%);
`

const StyledAccordion = styled(Accordion)`
  margin-top: 32px;
`

const InfoContainerDiv = styled.div`
  display: inline-block;
`

const StyledExternalLink = styled(ExternalLink)`
  margin-top: 8px;
`

const InfoHeadingTypography = styled(Typography).attrs({
  size: '700-sm',
  tag: 'h4',
})`
  margin-top: 24px;
  margin-bottom: 12px;
`

const StyledCircleCheckIcon = styled(CircleCheckIcon)`
  display: block;
  margin: 16px auto 40px;
  color: ${({ theme }) => theme.colors.success[50]};
`

const StyledCircleXmarkRegularIcon = styled(CircleXmarkRegularIcon)`
  display: block;
  margin: 16px auto 40px;
  color: ${({ theme }) => theme.colors.gray[50]};
`

const ButtonsContainer = styled(FlexColumn)`
  width: 300px;
  margin: 0 auto;
  gap: 8px;
`

const ICON_SIZE = 80

interface SubscribePaymentDialogContentProps {
  companyId: number
  isPulsePreferred: boolean
}

function SubscribePaymentDialogContent({ companyId, isPulsePreferred }: SubscribePaymentDialogContentProps) {
  const {
    clearTier,
    isCurrentCard,
    isSubscriptionActive,
    isTrialAvailable,
    selectedPlan,
    showSuccessView,
    submitting,
    variant,
  } = useSubscription()
  const finishButtonTo =
    variant === 'onboarding'
      ? getRouteUrl(RouteKeys.ONBOARDING_CHECK, companyId)
      : isPulsePreferred
      ? {
          pathname: getRouteUrl(RouteKeys.PULSE, companyId),
          state: {
            showDialog: showSuccessView === 'trial' ? PulseDialogs.WELCOME_TO_TASK_LIST : undefined,
          },
        }
      : getRouteUrl(RouteKeys.TRANSACTIONS, companyId)

  if (showSuccessView) {
    return (
      <>
        <StyledCircleCheckIcon size={ICON_SIZE} />
        <ButtonsContainer>
          <Button as={Link} data-testid="finish-btn" fullWidth variant="primaryContained" to={finishButtonTo}>
            {variant === 'onboarding' ? FinishButtonMessage : GoToQuickButtonMessage}
          </Button>
        </ButtonsContainer>
      </>
    )
  }
  if (!selectedPlan) {
    return null
  }
  if (isTrialAvailable) {
    return <SubscriptionTrial />
  }
  if (isSubscriptionActive) {
    if (isCurrentCard(selectedPlan)) {
      return (
        <>
          <StyledCircleXmarkRegularIcon size={ICON_SIZE} />
          <ButtonsContainer>
            <Button as={Link} data-testid="finish-btn" fullWidth variant="primaryContained" to={finishButtonTo}>
              {variant === 'onboarding' ? FinishButtonMessage : GoToQuickButtonMessage}
            </Button>
            <Button variant="primaryText" onClick={clearTier} fullWidth>
              {CloseButtonMessage}
            </Button>
          </ButtonsContainer>
        </>
      )
    }
    return <SubscriptionPlanChange />
  }
  return (
    <BraintreeContainer>
      <Button variant="secondaryText" onClick={clearTier} disabled={submitting}>
        {CloseButtonMessage}
      </Button>
    </BraintreeContainer>
  )
}

SubscribePaymentDialogContent.propTypes = {
  companyId: PropTypes.number.isRequired,
  isPulsePreferred: PropTypes.bool.isRequired,
}

function getDialogTitle(successView: Nullable<'trial' | 'change' | 'payment'>, isCurrent: boolean) {
  switch (successView) {
    case 'payment':
      return (
        <Typography align="center" tag="h3" size="heading-4">
          <FormattedMessage id="dialogs.subscribePayment.finishText.payment" defaultMessage="Sikeresen előfizettél." />
        </Typography>
      )
    case 'change':
      return (
        <Typography align="center" tag="h3" size="heading-4">
          <FormattedMessage
            id="dialogs.subscribePayment.finishText.change"
            defaultMessage="Sikeresen csomagot váltottál."
          />
        </Typography>
      )
    case 'trial':
      return (
        <Typography align="center" tag="h3" size="heading-4">
          <FormattedMessage
            id="dialogs.subscribePayment.finishText.trial"
            defaultMessage="Sikeresen elindítottad a próbaidőszakod."
          />
        </Typography>
      )
    default:
      if (isCurrent) {
        return (
          <Typography align="center" tag="h3" size="heading-4">
            <FormattedMessage
              id="dialogs.subscribePayment.finishText.noChange"
              defaultMessage="Csomagod nem módosult!"
            />
          </Typography>
        )
      }
      return (
        <Typography align="center" tag="h3" size="400-xs">
          <FormattedMessage id="dialogs.subscribePayment.selectedPackage" defaultMessage="Választott csomag" />
        </Typography>
      )
  }
}

interface SubscribePaymentDialogProps {
  companyId: number
  isPulsePreferred: boolean
}

function PureSubscribePaymentDialog({ companyId, isPulsePreferred }: SubscribePaymentDialogProps) {
  const { selectedPlan, clearTier, isSubscriptionActive, showSuccessView, isTrialAvailable, isCurrentCard } =
    useSubscription()

  const open = Boolean(selectedPlan)

  const handleDialogClose = React.useCallback(() => {
    clearTier()
  }, [clearTier])

  // only show it when display braintreee form
  const showPaymentInfo = !(isSubscriptionActive || isTrialAvailable || showSuccessView)
  const isCurrent = !!selectedPlan && isCurrentCard(selectedPlan)
  const dialogTitle = getDialogTitle(showSuccessView, isCurrent)

  return (
    <StyledCustomDialog
      open={open}
      onClose={handleDialogClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      testId="subscribe-dialog"
    >
      <StyledDialogHeader $borderless>
        <StyledHeaderContainer $successView={Boolean(showSuccessView || isCurrent)}>
          {dialogTitle}
          {selectedPlan && (
            <>
              <Typography align="center" size="heading-3">
                {selectedPlan.name}
              </Typography>

              <StyledPriceBox
                currency={selectedPlan.currency}
                discountedPrice={selectedPlan.discounted_price}
                isCurrent={!showSuccessView && isCurrent}
                isNew
                isSubscriptionActive={isSubscriptionActive}
                price={selectedPlan.price}
                subscriptionPeriod={selectedPlan.period}
              />
            </>
          )}
        </StyledHeaderContainer>
      </StyledDialogHeader>
      <StyledCustomDialogBody>
        <SubscribePaymentDialogContent companyId={companyId} isPulsePreferred={isPulsePreferred} />
        {showPaymentInfo && (
          <StyledAccordion
            headerContent={
              <Typography size="navigation-md" color="gray-70">
                <FormattedMessage id="pages.subscribe.infoTitle" defaultMessage="További információk a fizetésről" />
              </Typography>
            }
            variant="plain"
          >
            <InfoContainerDiv>
              <InfoHeadingTypography>
                <FormattedMessage id="pages.subscribe.paymentProviderSubTitle" defaultMessage="Fizetési szolgáltató" />
              </InfoHeadingTypography>
              <Typography size="400-xs">
                <FormattedMessage
                  id="pages.subscribe.paymentProviderInfo"
                  defaultMessage="Fizetési szolgáltatónk a Braintree, a PayPal csoport tagja. A fizetés a PayPal által garantált kiemelt biztonságú csatornákon zajlik. A Riport Applications Kft. a Security Metrics által validált PCI DSS (Payment Card Industry Data Security Standards) kereskedőkre vonatkozó megfelelőségi tanusítvánnyal rendelkezik."
                />
              </Typography>
              <InfoHeadingTypography>
                <FormattedMessage id="pages.subscribe.personalSubTitle" defaultMessage="Személyes adatok tárolása" />
              </InfoHeadingTypography>
              <Typography size="400-xs">
                <FormattedMessage
                  id="pages.subscribe.personalDataInfo"
                  defaultMessage="A vásárlás során az adatvédelmi nyilatozatunkban felsorolt személyes adatokon kívül más személyes adatot, például a kártyád adatait mi nem tároljuk, azokhoz nem férünk hozzá."
                />
              </Typography>
              <StyledExternalLink
                href={LINKS.eula}
                text={
                  <FormattedMessage
                    id="pages.subscribe.quick.eula"
                    defaultMessage="Bővebben az adatvédelmi nyilatkozatról"
                  />
                }
                typographySize="400-xs"
              />
            </InfoContainerDiv>
          </StyledAccordion>
        )}
      </StyledCustomDialogBody>
    </StyledCustomDialog>
  )
}
PureSubscribePaymentDialog.propTypes = {
  companyId: PropTypes.number.isRequired,
}

const SubscribePaymentDialog = connect((state: Store) => ({
  companyId: state.auth.company.data.id,
  isPulsePreferred: getPulseRedirectPreferredForCompanyType(state.auth.company.data?.company_type ?? null),
}))(PureSubscribePaymentDialog)

SubscribePaymentDialog.displayName = 'SubscribePaymentDialog'

export default SubscribePaymentDialog
