import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import { Field, useForm, useFormState } from 'react-final-form'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { commonActions } from '@services'

import { bindActionToPromise, cancellablePromise } from '@helpers'

import { AsyncStatus, useAsyncStatus } from '@hooks/useAsyncStatus'
import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { FirstColumn, SecondColumn } from '@components/ui'
import { AsyncInputAdornment, FieldLabelWithInfoTooltip } from '@oldComponents/ui'
import { RenderFormFieldSelect, RenderTextField } from '@oldComponents/ui/form'

import { FIELD_SUBSCRIPTION, FOUR_DIGIT_REGEX } from '@constants'

import { CompanyOwnerFormFieldNames } from '../../constants'

import { formSelectMessages } from '@messages'

const messages = defineMessages({
  unknownZipCode: {
    id: 'taxation.form.companyOwner.unknownZipCode',
    defaultMessage: 'Nem ismert irányítószám',
  },
  citiesApiFailed: {
    id: 'taxation.form.companyOwner.zipCodeApiFailed',
    defaultMessage: 'Nem sikerült a város lekérdezés',
  },
})

// TODO: take this into consideration once refactoring TextField and delete this
// need to overwrite styled due to inconsistent input adornment styling with RenderTextField
const StyledAsyncInputAdornment = styled(AsyncInputAdornment)`
  position: absolute;
  right: 0;
  width: 18px;
  height: 18px;
  padding: 0;
  margin: 0 10px;
`

const CITY_COLUMN_CLASS_KEYS = {
  single: 'single',
}

const CityColumn = styled(SecondColumn)`
  /* Select input needs to act as a text input when we're not using multiple cities */
  &.${CITY_COLUMN_CLASS_KEYS.single} .react-select__indicators {
    display: none;
  }
`

interface CityOption {
  label: string
  key: string
}

interface CompanyOwnerCityFieldsProps {
  cityLabel: string
  getCities: AsyncFunction<string | number, BackendCitiesResponse>
  initialCity: Nullable<string>
  initialZipCode: Nullable<string | number>
  zipCodeLabel: string
}

function PureCompanyOwnerCityFields({
  cityLabel,
  zipCodeLabel,
  getCities,
  initialCity,
  initialZipCode,
}: CompanyOwnerCityFieldsProps) {
  const { submitting } = useFormState({ subscription: { submitting: true } })
  const { change } = useForm()
  const [asyncStatus, setAsyncStatus] = useAsyncStatus({ disableErrorAlert: true })
  const [cities, setCities] = React.useState<CityOption[]>(
    initialCity ? [{ key: initialCity, label: initialCity }] : []
  )
  // need to store last fetched zip and its result to avoid multiple api calls but get the same validation results
  const lastFetchedZipCodeRef = React.useRef<{ zipCode: Nullable<string>; result?: string }>({
    zipCode: initialZipCode ? String(initialZipCode) : null,
  })
  const { formatMessage } = useIntl()
  const cancellablePromiseRef = useCancellablePromiseRef<BackendCitiesResponse>()

  //! Note: zipCode currently can be a number because of the backend implementation but RenderTextField is not typed correctly
  const asyncZipCodeValidator = React.useCallback(
    async (zipCode: Nullable<string>) => {
      if (!zipCode) return

      function clearCitiesAndMarkError() {
        setCities([])
        change(CompanyOwnerFormFieldNames.COMPANY_CITY, null)
        setAsyncStatus(AsyncStatus.ERROR)
      }

      // need to ensure zip code is string - backend might try to trick us here
      const stringZipCode = String(zipCode)
      // validate zipCode which needs to be 4 digits
      const isZipValid = Boolean(stringZipCode.match(FOUR_DIGIT_REGEX))

      try {
        if (isZipValid && lastFetchedZipCodeRef.current.zipCode !== stringZipCode) {
          setAsyncStatus(AsyncStatus.WIP)
          // check if there is any ongoing request and cancel it
          if (cancellablePromiseRef.current) {
            cancellablePromiseRef.current.cancel()
          }
          // call cities api
          cancellablePromiseRef.current = cancellablePromise(getCities(stringZipCode))
          const { cities: newCities } = await cancellablePromiseRef.current.promise

          if (newCities.length) {
            lastFetchedZipCodeRef.current = {
              zipCode: stringZipCode,
            }
            setCities(newCities.map(city => ({ key: city, label: city })))
            // if there is only 1 city, make it the selected value, otherwise just reset it and set the cities array
            if (newCities.length === 1) {
              change(CompanyOwnerFormFieldNames.COMPANY_CITY, newCities[0])
            } else {
              change(CompanyOwnerFormFieldNames.COMPANY_CITY, null)
            }
            setAsyncStatus(AsyncStatus.DONE)

            return
          } else {
            // store error message as result in ref to prepare for next validation round of form
            const message = formatMessage(messages.unknownZipCode)
            lastFetchedZipCodeRef.current = {
              zipCode: stringZipCode,
              result: message,
            }

            // if the result does not contain a single city, mark the field as error and show error icon
            clearCitiesAndMarkError()

            return message
          }
        } else if (lastFetchedZipCodeRef.current.zipCode === stringZipCode) {
          // need to return last result as validator is called multiple times
          return lastFetchedZipCodeRef.current.result
        }

        // check if there is any ongoing request and cancel it because if we're here that means zipCode has changed from before and it is not valid
        if (cancellablePromiseRef.current) {
          cancellablePromiseRef.current.cancel()
        }
      } catch (error) {
        if (!Object.prototype.hasOwnProperty.call(error, 'isCanceled')) {
          // if API fails mark the field in error as well
          console.error(error)
          clearCitiesAndMarkError()

          return formatMessage(messages.citiesApiFailed)
        }
      }
    },
    [cancellablePromiseRef, change, formatMessage, getCities, setAsyncStatus]
  )

  const isSingleCityMode = cities.length < 2

  return (
    <>
      <FirstColumn>
        <Field
          component={RenderTextField}
          disabled={submitting}
          label={zipCodeLabel}
          name={CompanyOwnerFormFieldNames.COMPANY_ZIP_CODE}
          subscription={FIELD_SUBSCRIPTION}
          InputProps={{
            endAdornment: <StyledAsyncInputAdornment status={asyncStatus} />,
          }}
          validate={asyncZipCodeValidator}
          required
        />
      </FirstColumn>
      <CityColumn className={cx({ [CITY_COLUMN_CLASS_KEYS.single]: isSingleCityMode })}>
        <Field
          component={RenderFormFieldSelect}
          options={cities}
          isDisabled={submitting || isSingleCityMode}
          label={
            <FieldLabelWithInfoTooltip
              label={cityLabel}
              tooltipText={
                <FormattedMessage
                  id="taxation.form.companyOwner.tooltip.city"
                  defaultMessage="A település megadásához kérjük add meg az irányítószámot."
                />
              }
            />
          }
          valueKey="key"
          labelKey="label"
          placeholder={!isSingleCityMode && formatMessage(formSelectMessages.selectPlaceholder)}
          name={CompanyOwnerFormFieldNames.COMPANY_CITY}
          subscription={FIELD_SUBSCRIPTION}
          required
        />
      </CityColumn>
    </>
  )
}

PureCompanyOwnerCityFields.propTypes = {
  cityLabel: PropTypes.string.isRequired,
  getCities: PropTypes.func.isRequired,
  initialCity: PropTypes.string.isRequired,
  initialZipCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  zipCodeLabel: PropTypes.string.isRequired,
}

export const CompanyOwnerCityFields = connect(null, dispatch => ({
  getCities: bindActionToPromise(dispatch, commonActions.fetchCities.request),
}))(PureCompanyOwnerCityFields)

CompanyOwnerCityFields.displayName = 'CompanyOwnerCityFields'
