import styled from 'styled-components'

//* Invoice list status icons
const iconOuterSize = '20px'

export const StatusIconWrapper = styled.span`
  display: inline-block;
  font-size: ${iconOuterSize};
  width: ${iconOuterSize};
  height: ${iconOuterSize};
  color: ${({ theme }) => theme.colors.gray[50]};
  margin: 2px; // same as paid status indicator
  text-align: center;
`
