import React from 'react'

import styled from 'styled-components'

import { StyledLabelInfoIcon } from '@components/pages/PulsePage/TaxationTasks/elements'
import { SelectableCardButton, Typography } from '@components/ui'
import { LightTooltip } from '@oldComponents/ui'

const InfoIconWrapper = styled.div``

const SelectableFeatureButton = styled(SelectableCardButton)`
  ${InfoIconWrapper} {
    position: absolute;
    top: 17px;
    right: 12px;
  }
`

interface SelectableFeatureProps {
  description?: StringOrMessage
  label: StringOrMessage
  onSelect: VoidFunction
  selected: boolean
  tooltip?: StringOrMessage
}

export function SelectableFeature({ description, label, onSelect, selected, tooltip }: SelectableFeatureProps) {
  return (
    <SelectableFeatureButton $isSelected={selected} onClick={onSelect}>
      <Typography size="navigation-md" color="gray-80">
        {label}
      </Typography>
      {description && (
        <Typography size="400-sm" color="gray-70">
          {description}
        </Typography>
      )}
      {tooltip && (
        <LightTooltip title={tooltip} placement="right" PopperProps={{ disablePortal: true }}>
          <InfoIconWrapper>
            <StyledLabelInfoIcon />
          </InfoIconWrapper>
        </LightTooltip>
      )}
    </SelectableFeatureButton>
  )
}
