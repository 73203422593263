import { serialize } from 'object-to-formdata'
import Qs from 'qs'

import { callUrl } from '@services/common/api'

import { injectCompanyToURL } from '@helpers'

import Api from '../api'
import {
  API_BULK_CATEGORIZATION_URL,
  API_EXPENSE_ARTIFACT_DELETE_URL,
  API_EXPENSE_ARTIFACT_DOWNLOAD_URL,
  API_EXPENSE_DELETE_URL,
  API_EXPENSE_DETAILS_URL,
  API_EXPENSE_DUPLICATE_REMOVE_URL,
  API_EXPENSE_EVENTS_LOG_URL,
  API_EXPENSE_LIST_URL,
  API_EXPENSE_MULTI_UPLOAD_URL,
  API_EXPENSE_STATUS_CHANGE_URL,
  API_EXPENSE_STOP_RECOGNITION_URL,
  API_EXPENSE_TYPE_URL,
  API_EXPENSE_USER_VIEW_URL,
  API_FILING_NUMBER_URL,
  API_INVOICE_NUMBER_CHECK_URL,
  API_INVOICE_RECOMMENDATION_URL,
  API_JOB_NUMBER_LIST_URL,
  API_JOB_NUMBER_URL,
  API_MISSING_INVOICES_URL,
  V2_API_EXPENSE_CHARTS_URL,
  V2_API_EXPENSE_COUNT_URL,
  V2_API_EXPENSE_DUPLICATE_URL,
  V2_API_EXPENSE_LIST_URL,
  V2_API_EXPENSES_BACKGROUND_ACTION_URL,
  V2_API_INIT_EXPENSE_DOWNLOAD_URL,
} from './urls'

// status change by accountant
export function expenseStatusChange(companyId: number, expenseId: number) {
  return Api.post(
    injectCompanyToURL(API_EXPENSE_STATUS_CHANGE_URL, companyId).replace('{expense_id}', String(expenseId))
  )
}

export function fetchExpenseDetails(companyId: number, expenseId: number) {
  return Api.get(injectCompanyToURL(API_EXPENSE_DETAILS_URL, companyId).replace('{expense_id}', String(expenseId)))
}

export function userViewExpense(companyId: number, expenseId: number) {
  return Api.post(injectCompanyToURL(API_EXPENSE_USER_VIEW_URL, companyId).replace('{expense_id}', String(expenseId)))
}

// recommendation
export function updateExpenseWithRecommendation({
  company,
  ...payload
}: Record<string, unknown> & { company: number }) {
  return Api.post(injectCompanyToURL(API_INVOICE_RECOMMENDATION_URL, company), payload)
}

// CREATE EXPENSE
// by file upload
export function uploadExpense(companyId: number, { invoice, ...data }: Record<string, unknown> & { invoice: number }) {
  // handle both create and re-upload
  const isUpdate = Boolean(invoice)
  const method = isUpdate ? Api.patch : Api.post
  const url = isUpdate ? API_EXPENSE_DETAILS_URL.replace('{expense_id}', String(invoice)) : API_EXPENSE_LIST_URL
  return method(injectCompanyToURL(url, companyId), data, {
    timeout: 5 * 60000, // 5 * 60sec
    transformRequest: [
      function (data) {
        return serialize(data)
      },
    ],
  })
}

export function expenseStopRecognition({ company_id, invoice_id }: { company_id: number; invoice_id: number }) {
  return Api.post(
    injectCompanyToURL(API_EXPENSE_STOP_RECOGNITION_URL, company_id).replace('{expense_id}', String(invoice_id))
  )
}

export function removeExpenseArtifact(companyId: number, data: Record<string, unknown>) {
  return Api.post(injectCompanyToURL(API_EXPENSE_ARTIFACT_DELETE_URL, companyId), data)
}

// by form post
export function createExpense(companyId: number, data: Record<string, unknown>) {
  return Api.post(injectCompanyToURL(API_EXPENSE_LIST_URL, companyId), data)
}

// update
export function updateExpense(companyId: number, { id, ...data }: Record<string, unknown>) {
  return Api.patch(injectCompanyToURL(API_EXPENSE_DETAILS_URL, companyId).replace('{expense_id}', String(id)), data)
}

// expense bulk upload
export function uploadMultipleExpense({ company, ...data }: Record<string, unknown> & { company: number }) {
  return Api.post(injectCompanyToURL(API_EXPENSE_MULTI_UPLOAD_URL, company), data, {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 5 * 60000, // 5 * 60sec
    transformRequest: [
      function (data) {
        // do it like in Yeri
        const formData = new FormData()
        Object.keys(data.files).forEach(key => {
          formData.append('files', data.files[key])
        })
        return formData
      },
    ],
  })
}

// expense duplication
export function checkExpenseDuplication(
  companyId: number,
  { expenseId, partnerId, invoiceNumber }: { expenseId: number; partnerId: number; invoiceNumber: string }
) {
  return Api.post(
    injectCompanyToURL(V2_API_EXPENSE_DUPLICATE_URL, companyId).replace('{expense_id}', String(expenseId)),
    {
      partner_id: partnerId,
      invoice_number: invoiceNumber,
    }
  )
}

export function deleteExpenseDuplication(companyId: number, { id }: { id: number }) {
  return Api.delete(injectCompanyToURL(API_EXPENSE_DUPLICATE_REMOVE_URL, companyId).replace('{id}', String(id)))
}

export function removeExpense(
  companyId: number,
  { expenseId, ...data }: { expenseId: number; source: ExpenseDeleteSource }
) {
  return Api.post(
    injectCompanyToURL(API_EXPENSE_DELETE_URL, companyId).replace('{expense_id}', String(expenseId)),
    data
  )
}

export function fetchExpenseTypes(companyId: number) {
  return Api.get(injectCompanyToURL(API_EXPENSE_TYPE_URL, companyId))
}

export function createExpenseType(data: Record<string, unknown> & { company: number }) {
  return Api.post(injectCompanyToURL(API_EXPENSE_TYPE_URL, data.company), data)
}

// BULK UPDATE CHECK
export function bulkInvoiceUpdateCheck(
  companyId: number,
  { partner_id, payment_method, id: invoiceId }: Record<string, number>
) {
  return Api.get(
    injectCompanyToURL(API_MISSING_INVOICES_URL, companyId)
      .replace('{partner_id}', String(partner_id))
      .replace('{payment_method}', String(payment_method))
      .replace('{invoice_id}', String(invoiceId))
  )
}

export function bulkInvoiceUpdate({ count, company_id, partner_id, payment_method, ...data }: Record<string, number>) {
  return Api.patch(
    injectCompanyToURL(API_MISSING_INVOICES_URL, company_id)
      .replace('{partner_id}', String(partner_id))
      .replace('{payment_method}', String(payment_method))
      .replace('{invoice_id}', String(data.id)),
    data
  )
}

// EVENTS LOG

export function fetchEventsLog(companyId: number, expenseId: number) {
  return Api.get(injectCompanyToURL(API_EXPENSE_EVENTS_LOG_URL, companyId).replace('{expense_id}', String(expenseId)))
}

// CATEGORIZATION
export function bulkCategorization(companyId: number, data: Record<string, unknown>) {
  return Api.post(injectCompanyToURL(API_BULK_CATEGORIZATION_URL, companyId), data)
}

// DOWNLOADS
export function downloadExpenseArtifact(companyId: number, { id, ...data }: Record<string, unknown>) {
  return Api.post(
    injectCompanyToURL(API_EXPENSE_ARTIFACT_DOWNLOAD_URL, companyId).replace('{invoice_id}', String(id)),
    data
  )
}

export function invoiceNumberCheck(companyId: number, data: Record<string, unknown>) {
  return Api.post(injectCompanyToURL(API_INVOICE_NUMBER_CHECK_URL, companyId), data)
}

//* V2 API

export function fetchExpenseListV2(companyId: number, params: Partial<ExpenseListStoreFilters>) {
  return Api.get(injectCompanyToURL(V2_API_EXPENSE_LIST_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params)
      },
    },
  })
}

export function fetchExpenseCountV2(companyId: number, params: Partial<ExpenseListStoreFilters>) {
  return Api.get(injectCompanyToURL(V2_API_EXPENSE_COUNT_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params)
      },
    },
  })
}

export function fetchExpenseCharts(companyId: number, params: Partial<ExpenseListStoreFilters>) {
  return Api.get(injectCompanyToURL(V2_API_EXPENSE_CHARTS_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params)
      },
    },
  })
}

//* V2
export function fetchExpenseListByPagingV2(url: string) {
  return callUrl({ method: 'get', url })
}

export function initDuplication(
  companyId: number,
  { expenseId, ...params }: { expenseId: number; partnerId?: number }
) {
  return Api.get(
    injectCompanyToURL(V2_API_EXPENSE_DUPLICATE_URL, companyId).replace('{expense_id}', String(expenseId)),
    {
      params,
      paramsSerializer: {
        serialize(params) {
          return Qs.stringify(params, { indices: false })
        },
      },
    }
  )
}

export function initDownloadV2(companyId: number, params: unknown) {
  return Api.get(injectCompanyToURL(V2_API_INIT_EXPENSE_DOWNLOAD_URL, companyId), {
    params,
    paramsSerializer: {
      serialize(params) {
        return Qs.stringify(params, { indices: false })
      },
    },
  })
}

//* SPECIAL BACKGROUND-ACTION API
// TODO payload type is ReturnType<typeof generateBackgroundProcessActionPayload> - service-v2 helper
export function expensesBackgroundAction(companyId: number, payload: unknown) {
  return Api.post<BackgroundActionResponse>(
    injectCompanyToURL(V2_API_EXPENSES_BACKGROUND_ACTION_URL, companyId),
    payload
  )
}

export function callUrlByPostMethod(url: string) {
  return callUrl({ method: 'post', url })
}

export function fetchLastFilingNumber(companyId: number) {
  return Api.get(injectCompanyToURL(API_FILING_NUMBER_URL, companyId))
}

//* JobNumber
export function createJobNumber(companyId: number, name: string) {
  return Api.post(injectCompanyToURL(API_JOB_NUMBER_URL, companyId), { name })
}

export function searchJobNumbers(companyId: number, search: string) {
  return Api.get(injectCompanyToURL(API_JOB_NUMBER_LIST_URL, companyId), { params: { search } })
}
