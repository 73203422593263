import React from 'react'

import { GroupBase, SelectInstance } from 'react-select'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef } from './useCancellablePromiseRef'

type CreateTagHandlerFunction = (newOption: Tag, selectRef: SelectInstance<Tag, boolean, GroupBase<Tag>>) => void

/**
 * Custom hook to handle the creation of a new tag.
 *
 * @param {Company['id']} company - The ID of the company for which the tag is being created.
 * @param {CreateCommonIdAndNameTypeCallback} createNewTag - Service function to create a new tag.
 * @returns {Object} An object containing:
 * - `isLoading` (boolean): Indicates if the tag creation process is in progress.
 * - `onCreateHandler` (CreateTagHandlerFunction): Function to handle the creation of a new tag.
 *
 * @example
 * const { isLoading, onCreateHandler } = useCreateTagHandler(companyId, createNewTag);
 *
 * Usage in a component
 * <SelectInput isLoading={isLoading} onCreate={onCreateHandler}>
 */
export function useCreateTagHandler(company: Company['id'], createNewTag: CreateCommonIdAndNameTypeCallback) {
  const [isLoading, setIsLoading] = React.useState(false)
  const { setErrorAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef<CommonIdAndNameType>()

  const onCreateHandler = React.useCallback<CreateTagHandlerFunction>(
    async (newOption, selectRef) => {
      setIsLoading(true)
      try {
        cPromiseRef.current = cancellablePromise(createNewTag({ ...newOption, company }))
        const { id, name } = await cPromiseRef.current.promise
        // select it
        selectRef.selectOption({ id, name })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
      }
      setIsLoading(false)
    },
    [cPromiseRef, company, createNewTag, setErrorAlert]
  )

  return React.useMemo(() => ({ isLoading, onCreateHandler }), [isLoading, onCreateHandler])
}
