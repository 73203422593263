import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { authActions, subscriptionActions } from '@services'

import { bindActionToPromise, calculatePlanPrices, cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useSubscription } from '@contexts'

import { useCancellablePromiseRef } from '@hooks'

import { Accordion, AsyncButton, Button, ExternalLink } from '@components/ui'
import { Typography } from '@components/ui/Typography'
import { FlexColumn } from '@components/ui/Wrappers'
import { TierFeatureList } from '@oldComponents/SubscriptionCard'

import { LINKS, SubscriptionPlanTiers } from '@constants'

import { isDowngradeFlow, reducer } from './helpers'

import { SubscriptionChangeMoreInfoMessage } from './messages'

const StyledExternalLink = styled(ExternalLink)`
  width: max-content;
  margin: 12px auto 0;
`

const ButtonsContainer = styled(FlexColumn)`
  width: 300px;
  margin: 32px auto 0;
  gap: 8px;
`

const StyledFeatureList = styled(TierFeatureList)`
  margin-top: 32px;

  & + ${ButtonsContainer} {
    margin-top: 0;
  }
`

const InnerFeatureList = styled(TierFeatureList)`
  margin-bottom: 0;
`

interface SubscriptionPlanChangeProps {
  updateSubscription: AsyncFunction<
    {
      id: Nullable<string>
      new_plan_id: number
      new_plan_price: Decimal
      trial: boolean
    },
    CompanySubscription
  >
  setActiveSubscription: (payload: CompanySubscription) => void
}

function PureSubscriptionPlanChange({ updateSubscription, setActiveSubscription }: SubscriptionPlanChangeProps) {
  const {
    clearTier,
    selectedPlan,
    subscription: { id: subscriptionId, trial, plan: currentPlan },
    setSuccessView,
  } = useSubscription()
  const cPromiseRef = useCancellablePromiseRef<CompanySubscription>()
  const [{ loading, error }, dispatch] = React.useReducer(reducer, { loading: false, error: null })

  const handlePlanChange = React.useCallback(() => {
    if (!selectedPlan) {
      throw Error('Missing selected subscription plan in change view')
    }
    dispatch({ type: 'request' })

    const payload = {
      id: subscriptionId,
      new_plan_id: selectedPlan.id,
      new_plan_price: calculatePlanPrices(selectedPlan),
      trial, // first attempt, trial package change
    }

    cPromiseRef.current = cancellablePromise(updateSubscription(payload))
    cPromiseRef.current.promise
      .then(newSubscription => {
        setSuccessView('change')
        setActiveSubscription(newSubscription)
      })
      .catch(error => {
        const errorMsg = parseApiErrorMessage(error)
        if (errorMsg) {
          dispatch({ type: 'failure', payload: errorMsg })
        }
      })
  }, [subscriptionId, selectedPlan, trial, cPromiseRef, updateSubscription, setActiveSubscription, setSuccessView])

  if (!currentPlan || !selectedPlan) {
    throw Error('Missing data from subscription plan change view')
  }

  const isDowngrade = isDowngradeFlow(currentPlan.tier, selectedPlan.tier)

  return (
    <>
      <Typography color="gray-80" size="700-md" align="center">
        <FormattedMessage
          id="dialogs.subscription.change.description"
          defaultMessage="Biztosan módosítani szeretnéd a jelenlegi csomagodat?"
        />
      </Typography>
      {!trial && (
        <StyledExternalLink
          href={LINKS.subscriptionChange}
          text={SubscriptionChangeMoreInfoMessage}
          typographySize="400-sm"
        />
      )}
      <StyledFeatureList tier={isDowngrade ? currentPlan.tier : selectedPlan.tier} isExcluded={isDowngrade} />
      {isDowngrade &&
        selectedPlan.tier === SubscriptionPlanTiers.BASE &&
        currentPlan.tier === SubscriptionPlanTiers.PRO && (
          <Accordion
            headerContent={
              <Typography size="navigation-md" color="gray-80">
                <FormattedMessage
                  id="subscription.featureList.additional.excludedTitle"
                  defaultMessage="További funkciók elvesztése"
                />
              </Typography>
            }
            variant="plain"
          >
            <InnerFeatureList tier={SubscriptionPlanTiers.FINANCE} isExcluded withoutTitle />
          </Accordion>
        )}
      {error && (
        <Typography align="center" color="error">
          {error}
        </Typography>
      )}
      <ButtonsContainer>
        <AsyncButton fullWidth variant="primaryContained" onClick={handlePlanChange} loading={loading}>
          <FormattedMessage id="dialogs.subscribePayment.buttons.change" defaultMessage="Igen, csomagot váltok" />
        </AsyncButton>
        <Button fullWidth variant="secondaryText" onClick={clearTier}>
          <FormattedMessage id="dialogs.subscribePayment.buttons.cancel" defaultMessage="Mégsem" />
        </Button>
      </ButtonsContainer>
    </>
  )
}

PureSubscriptionPlanChange.propTypes = {
  companyId: PropTypes.number.isRequired,
  updateSubscription: PropTypes.func.isRequired,
  setActiveSubscription: PropTypes.func.isRequired,
}

export const SubscriptionPlanChange = connect(
  (state: Store) => ({
    companyId: state.auth.company.data.id,
  }),
  dispatch => ({
    updateSubscription: bindActionToPromise(dispatch, subscriptionActions.updateSubscription.request),
    setActiveSubscription: bindActionCreators(authActions.setActiveSubscription.request, dispatch),
  })
)(PureSubscriptionPlanChange)

SubscriptionPlanChange.displayName = 'SubscriptionPlanChange'
