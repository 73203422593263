import React from 'react'

import { useFormContext } from 'react-hook-form'

import { ReactHookFormBusinessSearchField } from '@components/ui'
import { ReactHookFormSearchSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSearchSelectField'

type CreateBusinessSearchFieldProps = Omit<
  ReactHookFormSearchSelectFieldProps<CegjelzoBusinessOption>,
  'label' | 'onCreateCallback' | 'onCreateOption' | 'onSelect' | 'OptionComponent' | 'searchOptions' | 'SelectComponent'
>

export function CreateBusinessSearchField(props: CreateBusinessSearchFieldProps) {
  const { setValue } = useFormContext()

  const onCreateCallback = React.useCallback(
    (option: CegjelzoBusinessOption) => {
      if (!option.tax_number) {
        setValue('tax_account_number', '', { shouldTouch: true, shouldValidate: false })
      }
    },
    [setValue]
  )

  return <ReactHookFormBusinessSearchField {...props} onCreateCallback={onCreateCallback} />
}
