import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { dokumentsActions } from '@services'

import { bindActionToPromise, userHasEditorPermission } from '@helpers'

import { useFilingAction, UseFilingActionProps } from '@hooks'

import { QuickUserFeaturePermissions } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { DetailsFiling } from './DetailsFiling'

interface DokumentDetailsFilingProps
  extends Pick<UseFilingActionProps<BackgroundActionResponse>, 'callFiling' | 'callLastFilingNumber'> {
  actionEnabled: boolean
  filingNumber?: Nullable<string>
  isEditorUser: boolean
  itemId?: ItemIdType
}

function PureDokumentDetailsFiling({
  actionEnabled,
  callFiling,
  callLastFilingNumber,
  filingNumber,
  isEditorUser,
  itemId,
}: DokumentDetailsFilingProps) {
  const { handleClose, handleFiling, handleOpen, lastKnownFilingNumber, loading, open } = useFilingAction({
    callFiling,
    callLastFilingNumber,
    payload: { ids: [itemId as ItemIdType], isAllSelected: false },
  })

  if (!itemId) {
    console.error('Unexpected error: filing action is missing itemId!')
    return null
  }

  return (
    <DetailsFiling
      dialogInfoMessage={
        <FormattedMessage
          id="dialogs.filing.dokument.confirmText"
          defaultMessage="A legutolsó kiosztott iktatószám a(z) {value} volt. Ha ezt a dokumentumot iktatod, akkor később már nem fogod tudni visszavonni ezt a műveletet."
          values={{ value: <strong>{lastKnownFilingNumber}</strong> }}
        />
      }
      handleClose={handleClose}
      handleFiling={handleFiling}
      handleOpen={handleOpen}
      isActionAvailable={actionEnabled && !filingNumber && isEditorUser}
      loading={loading}
      open={open}
    >
      {filingNumber ? (
        <FormattedMessage
          id="form.dokument.text.filingNumber"
          defaultMessage="Ez a dokumentum már iktatva van a(z) {value} iktatószámon."
          values={{ value: <strong>{filingNumber}</strong> }}
        />
      ) : (
        <FormattedMessage
          id="form.dokument.text.filingNumber.notSet"
          defaultMessage="Ez a dokumentum még nincs iktatva."
        />
      )}
    </DetailsFiling>
  )
}

PureDokumentDetailsFiling.propTypes = {
  actionEnabled: PropTypes.bool.isRequired,
  callFiling: PropTypes.func.isRequired,
  callLastFilingNumber: PropTypes.func.isRequired,
  filingNumber: PropTypes.string,
  isEditorUser: PropTypes.bool.isRequired,
  itemId: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
}

export const DokumentDetailsFiling = connect(
  (state: Store) => ({
    actionEnabled: !permissionDeniedForUser(state, QuickUserFeaturePermissions.denyDocumentFilePermission),
    itemId: state.dokuments.details.data?.id,
    filingNumber: state.dokuments.details.data?.filingNumber,
    isEditorUser: userHasEditorPermission(state.auth.company.data.role),
  }),
  dispatch => ({
    callFiling: bindActionToPromise(dispatch, dokumentsActions.dokumentFiling.request),
    callLastFilingNumber: bindActionToPromise(dispatch, dokumentsActions.fetchLastFilingNumber.request),
  })
)(PureDokumentDetailsFiling)

DokumentDetailsFiling.displayName = 'DokumentDetailsFiling'
