export const INITIAL_ORIGIN_FILTER: string[] = []

export const INITIAL_PAYMENT_METHOD_FILTER: string[] = []

export const INITIAL_VAT_AREA_FILTER: string[] = []

export const INITIAL_PAID_THROUGH_FILTER: CommonIdAndNameType[] = []

export const INITIAL_PAID_STATUS_FILTERS: PaidStatusFilterValues = {
  expiredDays: [],
  expiringDays: [],
  isExpired: false,
  isExpiring: false,
  isPaid: false,
}

// quarantine expense, income
export const DEFAULT_INVOICE_SEARCH_FILTERS = ['partner_name', 'invoice_number', 'secondary_id']

export const ALL_INCOME_SEARCH_FILTERS = [...DEFAULT_INVOICE_SEARCH_FILTERS, 'income_item_name', 'post_it_note']

// extended expense list
export const DEFAULT_EXPENSE_SEARCH_FILTERS = [...DEFAULT_INVOICE_SEARCH_FILTERS, 'filing_number']

export const ALL_EXPENSE_SEARCH_FILTERS = [
  ...DEFAULT_INVOICE_SEARCH_FILTERS,
  'invoice_item_name',
  'post_it_note',
  'filing_number',
  'ledger_number', // only when isAdvancedAccountingAvailableForUser
  'job_number', // only when isAdvancedAccountingAvailableForUser
]
