import { PartiallyApprovedIcon } from './PartiallyApprovedIcon'

import {
  AdvancedApprovalApprovedStatusMessage,
  AdvancedApprovalNotApprovedStatusMessage,
  AdvancedApprovalPartiallyApprovedStatusMessage,
} from '@messages'
import { ApprovedIcon, NotApprovedIcon } from './styles'

export function getStatusComponents({
  isApproved = false,
  status,
}: {
  isApproved?: boolean
  status: ExpenseApprovalStatus | InvoiceApprovalStateValue | undefined
}): [React.ComponentType<IconComponentProps>, StringOrMessage] {
  // Do not check status when invoice is approved (company has no advanced approval feature)
  if (isApproved) {
    return [ApprovedIcon, AdvancedApprovalApprovedStatusMessage]
  }

  switch (status) {
    case 2:
    case 'approved':
      return [ApprovedIcon, AdvancedApprovalApprovedStatusMessage]
    case 'partiallyApproved':
    case 1:
      return [PartiallyApprovedIcon, AdvancedApprovalPartiallyApprovedStatusMessage]
    case 'notApproved':
    case 0:
    default:
      return [NotApprovedIcon, AdvancedApprovalNotApprovedStatusMessage]
  }
}
