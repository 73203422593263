import React from 'react'
import PropTypes from 'prop-types'

import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { cegjelzoActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { ReactHookFormSearchSelectField } from '@components/ui/FormElements'
import { ReactHookFormSearchSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSearchSelectField'

import { CegjelzoOption } from './CegjelzoOption'

function onCreateOption(name: string): Promise<CegjelzoBusinessOption> {
  return Promise.resolve({
    address: '',
    name,
    tax_number: '',
  })
}

type PureReactHookFormBusinessSearchFieldProps = Omit<
  ReactHookFormSearchSelectFieldProps<CegjelzoBusinessOption>,
  'label' | 'onCreateOption' | 'onSelect' | 'SelectComponent' | 'OptionComponent'
>

function PureReactHookFormBusinessSearchField(props: PureReactHookFormBusinessSearchFieldProps) {
  const { setValue } = useFormContext()

  const onSelectCallback = React.useCallback(
    (option: CegjelzoBusinessOption) => {
      if (option.tax_number) {
        setValue('tax_account_number', option.tax_number, { shouldTouch: true, shouldValidate: true })
      }
    },
    [setValue]
  )

  return (
    <ReactHookFormSearchSelectField
      {...props}
      label={<FormattedMessage id="form.label.businessName" defaultMessage="Vállalkozás elnevezése" />}
      onCreateOption={onCreateOption}
      onSelect={onSelectCallback}
      OptionComponent={CegjelzoOption}
    />
  )
}

PureReactHookFormBusinessSearchField.propTypes = {
  onCreateCallback: PropTypes.func,
  searchOptions: PropTypes.func.isRequired,
}

export const ReactHookFormBusinessSearchField = connect(null, dispatch => ({
  searchOptions: bindActionToPromise(dispatch, cegjelzoActions.searchCompanies.request),
}))(PureReactHookFormBusinessSearchField)

ReactHookFormBusinessSearchField.displayName = 'ReactHookFormBusinessSearchField'
