import React from 'react'

import { useIntl } from 'react-intl'
import {
  ActionMeta,
  GetOptionLabel,
  GetOptionValue,
  GroupBase,
  MenuListProps,
  SelectComponentsConfig,
  SelectInstance,
} from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { cancellablePromise, customFilterOption, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts'

import { useCancellablePromiseRef, usePromptTextCreator } from '@hooks'

import { Typography } from '@components/ui/Typography'
import { WindowedMenuList } from '@oldComponents/ui/WindowedMenuList'

import { CustomReactSelectOption } from '../elements'
import { useIsValidNewOption } from '../useIsValidNewOption'
import { AsyncSelectInputProps } from './types'

import { selectFormStyles } from '@styles'
import { formSelectMessages } from '@messages'

const SEARCH_CHAR_MIN_LENGTH = 1

export function AsyncSelectInput<Option extends Record<string, unknown>>({
  className,
  defaultOptions = false,
  isClearable = false,
  isDisabled = false,
  labelKey = 'name',
  menuShouldBlockScroll = false,
  name,
  onBlur,
  onChange,
  onCreateCallback, // optional callback after onCreateOption
  onCreateOption,
  onEmptyCreateText, // optional overwrite for no results text when onCreate property is passed
  onSelect,
  OptionComponent = CustomReactSelectOption,
  searchOptions,
  useWindowedMenuList,
  value,
  valueKey = 'name',
}: AsyncSelectInputProps<Option>) {
  const [inputValue, setInputValue] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const cPromiseRef = useCancellablePromiseRef<Option[]>()
  const cPromiseCreateRef = useCancellablePromiseRef<Option>()
  const { setErrorAlert } = useAlertDispatch()
  const { formatMessage } = useIntl()

  const selectRef = React.useRef<SelectInstance<Option, false, GroupBase<Option>>>(null)

  const promptTextCreator = usePromptTextCreator()

  const isValidNewOption = useIsValidNewOption(labelKey)

  const isCreatable = Boolean(onCreateOption)
  const SelectComponent = isCreatable ? AsyncCreatableSelect : AsyncSelect

  //* customize react-select components
  const componentOverridesProp = React.useMemo(() => {
    const components: SelectComponentsConfig<Option, boolean, GroupBase<Option>> = {
      Option: OptionComponent,
    }

    // use react-window when has more than 9 options
    if (useWindowedMenuList) {
      components.MenuList = WindowedMenuList as React.ComponentType<MenuListProps<Option, boolean, GroupBase<Option>>> // still a JS component
    }

    return components
  }, [OptionComponent, useWindowedMenuList])

  const onInputChange = React.useCallback(
    (newValue: string) => {
      setInputValue(newValue)
    },
    [setInputValue]
  )

  //* search feature
  const loadOptions: AsyncFunction<string, Option[]> = React.useCallback(
    async inputValue => {
      // previously it was inputValue === undefined, but defualt by inputValue sems to be empty string
      const searchOnMount = defaultOptions && !inputValue
      const searchValue = String(searchOnMount ? value : inputValue).trim()

      if (searchValue.length >= SEARCH_CHAR_MIN_LENGTH) {
        try {
          cPromiseRef.current = cancellablePromise(searchOptions(searchValue))
          return await cPromiseRef.current.promise
        } catch (error) {
          const errorMessage = parseApiErrorMessage(error)
          if (errorMessage) {
            setErrorAlert(errorMessage)
          }
        }
      }
      return Promise.resolve([] as Option[])
    },
    [cPromiseRef, defaultOptions, searchOptions, setErrorAlert, value]
  )

  function loadingMessage() {
    return formatMessage(formSelectMessages.asyncLoadingText)
  }

  //* react-select getters
  const noOptionsMessage = React.useCallback(() => {
    if (onEmptyCreateText) {
      return (
        <Typography size="400-xs" color="inherit" tag="span">
          {onEmptyCreateText}
        </Typography>
      )
    }

    if (inputValue) {
      return formatMessage(formSelectMessages.selectNoResultsText)
    }

    return formatMessage(formSelectMessages.asyncNoResultsText)
  }, [formatMessage, onEmptyCreateText, inputValue])

  const getOptionLabel = React.useCallback<GetOptionLabel<Option>>(option => String(option[labelKey]), [labelKey])

  const getOptionValue = React.useCallback<GetOptionValue<Option>>(option => String(option[valueKey]), [valueKey])

  const getNewOptionData = React.useCallback(
    (inputValue, optionLabel) => ({
      [valueKey]: inputValue,
      [labelKey]: optionLabel,
    }),
    [labelKey, valueKey]
  )

  const selectValue = React.useMemo(() => {
    return value ? { [valueKey]: value, [labelKey]: value } : null
  }, [labelKey, value, valueKey])

  //* custom functions
  const handleCreateOption = React.useCallback(
    async (newValue: string) => {
      if (onCreateOption && selectRef.current) {
        setIsLoading(true)
        try {
          cPromiseCreateRef.current = cancellablePromise(onCreateOption(newValue))
          const option = await cPromiseCreateRef.current.promise

          selectRef.current?.selectOption(option)
          onCreateCallback?.(option)
        } catch (error) {
          const errorMessage = parseApiErrorMessage(error)
          if (errorMessage) {
            setErrorAlert(errorMessage)
          }
        }
        setIsLoading(false)
      }
    },
    [cPromiseCreateRef, onCreateCallback, onCreateOption, setErrorAlert]
  )

  const handleChange = React.useCallback(
    onChange =>
      (option: Option, { action }: ActionMeta<Option>) => {
        switch (action) {
          case 'select-option':
            onChange((option as Option)[valueKey] ?? '')
            onSelect?.(option as Option)
            break
          case 'remove-value':
            onChange((option as Option)[valueKey] ?? '')
            break
          case 'clear':
            onChange('')
            break
          default:
            break
        }
      },
    [onSelect, valueKey]
  )

  return (
    <SelectComponent
      ref={selectRef}
      className={className}
      classNamePrefix="react-select"
      defaultOptions={defaultOptions}
      instanceId={`rs-${name}`}
      inputId={name}
      isClearable={isClearable}
      isDisabled={isDisabled}
      value={selectValue}
      onBlur={onBlur}
      onChange={handleChange(onChange)}
      noOptionsMessage={noOptionsMessage}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      loadingMessage={loadingMessage}
      loadOptions={loadOptions}
      openMenuOnFocus
      menuPortalTarget={document.body}
      menuShouldBlockScroll={menuShouldBlockScroll}
      placeholder={formatMessage(formSelectMessages.creatableSelectPlaceholder)}
      // only for AsyncCreatableSelect
      getNewOptionData={getNewOptionData}
      onCreateOption={handleCreateOption}
      formatCreateLabel={promptTextCreator}
      filterOption={customFilterOption}
      isValidNewOption={isValidNewOption}
      isLoading={isLoading}
      {...componentOverridesProp}
      // custom style
      styles={selectFormStyles}
      // create disabled need to control to change noOpionsMessage
      {...(!isCreatable && { inputValue, onInputChange })}
    />
  )
}
