import React from 'react'

import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { Typography } from '@components/ui'

import {
  getActiveAmountFilters,
  getActiveCurrencyFilters,
  getActiveIncludeExcludeFilters,
  getActiveMultiChoiceFilters,
  getActivePaidStatusFilters,
  getActivePaidThroughFilters,
  getActiveSelectableFilters,
  getActiveStatusFilters,
  getDateLabel,
} from './helpers'

import {
  DisabledDateFilterValueMessage,
  OriginLabelMessage,
  PaymentMethodLabelMessage,
  VatAreaLabelMessage,
} from '@messages'
import {
  ExcludeApproverLabelMessage,
  ExcludeCategoryLabelMessage,
  ExcludeTagLabelMessage,
  IncludeApproverLabelMessage,
  IncludeCategoryLabelMessage,
  IncludeTagLabelMessage,
} from '../messages'

const WrapperDiv = styled.div`
  display: flex;
  align-items: flex-start;
  max-height: 40px;
  overflow: hidden;
`

export const ClampedTypography = styled(Typography)`
  -webkit-line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
`

const Separator = styled.span`
  margin-right: 5px;

  &::before {
    display: inline-block;
    content: ';';
  }
`

interface ActiveFiltersProps<CustomStatusFilterConfig extends StatusFilterConfig> {
  amountFilterProps?: AmountFilterProps
  approversFilterProps?: IncludeExcludeFilterProps
  categoryFilterProps?: IncludeExcludeFilterProps
  className?: string
  currencyFilterProps?: CurrencyFilterProps
  dateField?: string
  fromDate: Nullable<string>
  isDateFilterDisabled: boolean
  originFilterProps?: FilterDialogMultiChoiceFilterProps
  paidStatusFilterProps?: PaidStatusFilterProps
  paidThroughFilterProps?: FilterDialogPaidThroughFilterProps
  paymentMethodFilterProps?: FilterDialogMultiChoiceFilterProps
  selectableFilterProps?: SelectableFilterProps
  statusFilterProps?: StatusFilterProps<CustomStatusFilterConfig>
  tagFilterProps?: IncludeExcludeFilterProps
  toDate: Nullable<string>
  vatAreaFilterProps?: FilterDialogMultiChoiceFilterProps
}

/**
 * Component to display the active filters in a given page or subpage
 *
 * @param {ActiveFiltersProps} props {
 *   filters: { fromDate, toDate, selectableFilterValues, specialFilterValues },
 *   className,
 *   selectableFilters,
 *   isDateFilterDisabled,
 * }
 */
function PureActiveFilters<CustomStatusFilterConfig extends StatusFilterConfig>({
  amountFilterProps,
  approversFilterProps,
  categoryFilterProps,
  className,
  currencyFilterProps,
  dateField,
  fromDate,
  isDateFilterDisabled,
  originFilterProps,
  paidStatusFilterProps,
  paidThroughFilterProps,
  paymentMethodFilterProps,
  selectableFilterProps,
  statusFilterProps,
  tagFilterProps,
  toDate,
  vatAreaFilterProps,
}: ActiveFiltersProps<CustomStatusFilterConfig>) {
  const { formatDate, formatMessage, formatNumber } = useIntl()

  const activeDateFilters =
    !isDateFilterDisabled && fromDate != null && toDate != null
      ? [
          {
            label: getDateLabel(dateField),
            values: [`${formatDate(fromDate)} - ${formatDate(toDate)}`],
          },
        ]
      : [
          {
            label: getDateLabel(dateField),
            values: [DisabledDateFilterValueMessage],
          },
        ]
  const activeStatusFilters = React.useMemo(() => getActiveStatusFilters(statusFilterProps), [statusFilterProps])
  const activePaidStatusFilters = React.useMemo(
    () => getActivePaidStatusFilters(formatMessage, paidStatusFilterProps),
    [formatMessage, paidStatusFilterProps]
  )
  const activeAmountFilters = getActiveAmountFilters(formatNumber, amountFilterProps)
  const activeCurrencyFilters = getActiveCurrencyFilters(currencyFilterProps)
  const activePaidThroughFilters = getActivePaidThroughFilters(paidThroughFilterProps)
  // selectable filters
  const activeSelectableFilters = React.useMemo(
    () => getActiveSelectableFilters(selectableFilterProps),
    [selectableFilterProps]
  )
  // include-exclude filters
  const activeCategoryFilters = React.useMemo(
    () =>
      getActiveIncludeExcludeFilters(
        { exclude: ExcludeCategoryLabelMessage, include: IncludeCategoryLabelMessage },
        categoryFilterProps
      ),
    [categoryFilterProps]
  )
  const activeTagFilters = React.useMemo(
    () =>
      getActiveIncludeExcludeFilters(
        { exclude: ExcludeTagLabelMessage, include: IncludeTagLabelMessage },
        tagFilterProps
      ),
    [tagFilterProps]
  )
  const activeApprovalFilters = React.useMemo(
    () =>
      getActiveIncludeExcludeFilters(
        { exclude: ExcludeApproverLabelMessage, include: IncludeApproverLabelMessage },
        approversFilterProps
      ),
    [approversFilterProps]
  )
  // multi choice filters
  const activeOriginFilters = React.useMemo(
    () => getActiveMultiChoiceFilters(OriginLabelMessage, originFilterProps),
    [originFilterProps]
  )
  const activePaymentMethodFilters = React.useMemo(
    () => getActiveMultiChoiceFilters(PaymentMethodLabelMessage, paymentMethodFilterProps),
    [paymentMethodFilterProps]
  )
  const activeVatAreaFilters = React.useMemo(
    () => getActiveMultiChoiceFilters(VatAreaLabelMessage, vatAreaFilterProps),
    [vatAreaFilterProps]
  )

  const activeFilters = [
    ...activeDateFilters,
    ...activeStatusFilters,
    ...activeCategoryFilters,
    ...activeTagFilters,
    ...activePaidStatusFilters,
    ...activeSelectableFilters,
    ...activeOriginFilters,
    ...activePaidThroughFilters,
    ...activeAmountFilters,
    ...activeCurrencyFilters,
    ...activeVatAreaFilters,
    ...activePaymentMethodFilters,
    ...activeApprovalFilters,
  ]

  return (
    <WrapperDiv className={className} data-testid="active-filters">
      <ClampedTypography size="700-sm">
        {activeFilters.map(({ label, values }, index) => (
          <React.Fragment key={`active-filter-${index}`}>
            {/* as `label` can be a react component, we should not transform it into a string using string interpolation  */}
            {label}
            {': '}
            {values.map((value, valueIndex) => (
              <React.Fragment key={`active-filter-${index}-value-${valueIndex}`}>
                <Typography color="blue" tag="span">
                  {value}
                </Typography>
                <Separator />
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
      </ClampedTypography>
    </WrapperDiv>
  )
}

export const ActiveFilters = connect(({ filters: { fromDate, toDate } }: Store) => ({
  fromDate,
  toDate,
}))(PureActiveFilters)

ActiveFilters.displayName = 'ActiveFilters'
