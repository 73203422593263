import React from 'react'

import { FormattedDate, FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { AsyncTwoColumnDataTable } from '@components/ui/AsyncTwoColumnDataTable'
import { Currency } from '@components/ui/FormattedTexts'
import { Typography } from '@components/ui/Typography'

import { PAID_INSTALLMENT, PAID_YES } from '@constants'

const SummaryContainerTypography = styled(Typography)`
  padding: 5px 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
  display: flex;
  justify-content: space-between;
`

interface PaidStatusHistoryProps {
  data: BackendInvoicePaymentHistoryResults
  fetched: boolean
  invoiceCurrencyId: number
  loading: boolean
  value: InvoicePaidStatusValue
}

export function PaidStatusHistory({ data, fetched, invoiceCurrencyId, loading, value }: PaidStatusHistoryProps) {
  const headingText = React.useMemo(() => {
    if (value === PAID_INSTALLMENT) {
      return <FormattedMessage id="statuses.paid.partially" defaultMessage="Részben fizetve" />
    }
    if (value === PAID_YES) {
      return <FormattedMessage id="statuses.paid.fully" defaultMessage="Fizetve" />
    }
    return <FormattedMessage id="statuses.paid.no" defaultMessage="Fizetendő" />
  }, [value])

  const [tableData, calculatedTotal] = React.useMemo(() => {
    let total = 0
    const tableData = data.map(payment => {
      total += Number(payment.amount)

      return {
        label: <FormattedDate value={payment.date} />,
        value: (
          <Typography color="blue" size="700-xs" tag="span">
            <Currency value={Number(payment.amount)} currencyId={invoiceCurrencyId} />
          </Typography>
        ),
      }
    })

    return [tableData, total]
  }, [data, invoiceCurrencyId])

  return (
    <AsyncTwoColumnDataTable
      data={tableData}
      emptyDataText={<FormattedMessage id="statuses.paid.noData" defaultMessage="Nincs fizetési előzmény." />}
      fetched={fetched}
      headingText={headingText}
      loading={loading}
    >
      {data.length > 1 && (
        <SummaryContainerTypography size="700-xs">
          <Typography color="gray-80" tag="span">
            <FormattedMessage id="statuses.paid.summary" defaultMessage="Összesen" />
          </Typography>
          <Typography color="blue" tag="span" align="right">
            <Currency value={calculatedTotal} currencyId={invoiceCurrencyId} />
          </Typography>
        </SummaryContainerTypography>
      )}
    </AsyncTwoColumnDataTable>
  )
}
