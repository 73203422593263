import React from 'react'

import { Grid } from '@material-ui/core'
import { useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { getFirstDayOfMonth } from '@helpers'

import { ReactHookFormConditionalField, ReactHookFormDateField } from '@components/ui/FormElements'

import { SPACING } from '@oldComponents/forms/DetailsForm/styles'
import messages from '../messages'
import { StyledReactHookFormCheckboxField } from './styles'

interface AccountingPeriodFieldsProps {
  disabled: boolean
  isAdvancedAccounting?: boolean
  isLabelHighlighted?: boolean
  isMissing?: boolean
}

export function AccountingPeriodFields({
  disabled,
  isAdvancedAccounting = false,
  isLabelHighlighted,
  isMissing = false,
}: AccountingPeriodFieldsProps) {
  const { formatMessage } = useIntl()
  const { setValue, getFieldState, trigger } = useFormContext()
  const [needAccountingPeriod, accountingPeriodStart] = useWatch({
    name: ['need_accounting_period', 'accounting_period_start'],
  })

  //* clear fields' values when they are not visible
  React.useEffect(() => {
    if (!isAdvancedAccounting && !needAccountingPeriod) {
      setValue('accounting_period_start', null)
      setValue('accounting_period_end', null)
    }
  }, [isAdvancedAccounting, needAccountingPeriod, setValue])

  const onBlurStartFieldHandler = React.useCallback<React.FocusEventHandler<HTMLInputElement>>(() => {
    trigger('accounting_period_end')
  }, [trigger])

  //* fill empty accounting_period_start with first day of the month when accounting_period_end is valid
  const onBlurEndFieldHandler = React.useCallback<React.FocusEventHandler<HTMLInputElement>>(
    event => {
      const { invalid } = getFieldState('accounting_period_end')
      const accountingPeriodEndValue = event.target.value
      if (accountingPeriodEndValue && !invalid && !accountingPeriodStart) {
        const populatedStartValue = getFirstDayOfMonth(event.target.value)

        setValue('accounting_period_start', populatedStartValue, { shouldValidate: true })
      }
      trigger('accounting_period_start')
    },
    [accountingPeriodStart, getFieldState, setValue, trigger]
  )

  return (
    <Grid container spacing={SPACING}>
      <Grid item xs={4}>
        {isAdvancedAccounting ? (
          <ReactHookFormConditionalField name="reverse_tax_subject">
            <ReactHookFormDateField
              disabled={disabled}
              highlighted={isMissing}
              isLabelHighlighted={isLabelHighlighted}
              label={formatMessage(messages.deliveredAtDateLabel)}
              name="delivered_at"
              placeholder={formatMessage(messages.dateFieldPlaceholder)}
              required
            />
          </ReactHookFormConditionalField>
        ) : (
          <StyledReactHookFormCheckboxField
            disabled={disabled}
            isLabelHighlighted={isLabelHighlighted}
            label={formatMessage(messages.accountingPeriodCheckboxLabel)}
            name="need_accounting_period"
          />
        )}
      </Grid>
      <ReactHookFormConditionalField isVisible={isAdvancedAccounting} name="need_accounting_period">
        <Grid item xs={4}>
          <ReactHookFormDateField
            disabled={disabled}
            isLabelHighlighted={isLabelHighlighted}
            label={formatMessage(messages.accountingStartDateLabel)}
            name="accounting_period_start"
            placeholder={formatMessage(messages.dateFieldPlaceholder)}
            required={!isAdvancedAccounting}
            onBlur={onBlurStartFieldHandler}
          />
        </Grid>
        <Grid item xs={4}>
          <ReactHookFormDateField
            disabled={disabled}
            isLabelHighlighted={isLabelHighlighted}
            label={formatMessage(messages.accountingEndDateLabel)}
            name="accounting_period_end"
            onBlur={onBlurEndFieldHandler}
            placeholder={formatMessage(messages.dateFieldPlaceholder)}
            required={!isAdvancedAccounting}
          />
        </Grid>
      </ReactHookFormConditionalField>
    </Grid>
  )
}
