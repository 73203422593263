export enum BackgroundProcessActions {
  //* Expense V2 actions
  ACCOUNTING_EXPORT_EXPENSES = 'ACCOUNTING_EXPORT_EXPENSES',
  DELETE_EXPENSES = 'DELETE_EXPENSES', // fake BgJob
  DOWNLOAD_EXPENSES = 'DOWNLOAD_EXPENSES',
  EXPORT_EXPENSES = 'EXPORT_EXPENSES',
  FILE_EXPENSES = 'FILE_EXPENSES', // fake BgJob
  SZAMLAZZHU_SYNC_JOB_EXPENSES = 'SYNC_JOB_EXPENSES', // fake BgJob
  ACOUNTO_SYNC_JOB_EXPENSES = 'ACTION_ACOUNTO_UPLOAD_EXPENSES', // fake BgJob
  TOGGLE_APPROVE_EXPENSES = 'TOGGLE_APPROVE_EXPENSES', // fake BgJob
  TOGGLE_BOOK_EXPENSES = 'TOGGLE_BOOK_EXPENSES', // fake BgJob
  //* Quarantine V2 actions
  QUARANTINE_APPROVE = 'QUARANTINE_APPROVE', // fake BgJob
  QUARANTINE_EXPORT = 'QUARANTINE_EXPORT',
  QUARANTINE_REJECT = 'QUARANTINE_REJECT', // fake BgJob
  //* Incomes V2 actions
  DELETE_INCOMES = 'DELETE_INCOMES', // fake BgJob
  DOWNLOAD_INCOMES = 'DOWNLOAD_INCOMES',
  EXPORT_INCOMES = 'EXPORT_INCOMES',
  //* Salary V2 actions
  DELETE_SALARIES = 'DELETE_SALARIES', // fake BgJob
  EXPORT_SALARIES = 'EXPORT_SALARIES',
  //* Tax V2 actions
  DELETE_TAXES = 'DELETE_TAXES', // fake BgJob
  EXPORT_TAXES = 'EXPORT_TAXES',
  //* MonthlyStatement V2 actions
  EXPORT_MONTHLY_STATEMENT = 'EXPORT_MONTHLY_STATEMENT',
  //* Payment V2 actions
  BANK_TRANSACTIONS_EXPORT = 'TRANSACTIONS_EXPORT',
  PAYMENT_TRANSACTIONS_EXPORT = 'export',
  PAYMENT_TRANSACTIONS_BULK_DELETE = 'delete',
  //* Dokuments actions
  DOKUMENTS_AI_RECOGNITION = 'BULK_AI_RECOGNITION',
  FILE_DOCUMENTS = 'FILE_DOCUMENTS', // fake BgJob
}
