import { serviceActionsGenerator } from '../serviceGenerator'

const actionTypes = [
  '!ABORT_DOWNLOAD_DOKUMENTS',
  '!ATTACH_DOKUMENT_FROM_DETAILS',
  '!BULK_UPLOAD_DOKUMENTS',
  '!CONTINUE_AI_RECOGNITION',
  '!DETACH_DOKUMENT',
  '!DOWNLOAD_DOKUMENTS',
  '!FETCH_DOKUMENT_DETAILS_BY_PAGING',
  '!FETCH_LAST_FILING_NUMBER',
  '!INIT_DOKUMENT_LIST_PAGE_LOAD',
  '!REFRESH_PAGE',
  '!REMOVE_DOKUMENT',
  '!RESET_PAGINATION',
  '!SEARCH_EXPENSES_BY_URL',
  '!SEARCH_EXPENSES',
  '!SEARCH_INCOMES_BY_URL',
  '!SEARCH_INCOMES',
  '!SEARCH_PARTNERS',
  '!START_AI_RECOGNITION',
  '!STOP_AI_RECOGNITION',
  '!USER_VIEW_DOKUMENT',
  'ATTACH_DOKUMENTS',
  'BULK_AI_RECOGNITION',
  'BULK_FILING',
  'BULK_REMOVE_DOKUMENTS',
  'BULK_TAGGING',
  'CLEAR_DOKUMENT_DETAILS',
  'CREATE_DOKUMENT_TYPE',
  'CREATE_WEBLINK',
  'DOKUMENT_FILING',
  'DOWNLOAD_DOKUMENT',
  'FETCH_DOKUMENT_DETAILS',
  'FETCH_DOKUMENT_TYPES',
  'FETCH_DOKUMENTS_BY_PAGING',
  'FETCH_DOKUMENTS',
  'RENAME_DOKUMENT',
  'STATUS_CHECK',
  'UPDATE_DOKUMENT_DETAILS',
  'UPDATE_ORDER',
  'UPDATE_ROWS_PER_PAGE',
] as const

export default serviceActionsGenerator<typeof actionTypes>('dokuments', actionTypes)
