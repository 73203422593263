import { FORM_ERROR } from 'final-form'
import __find from 'lodash/find'
import __isArray from 'lodash/isArray'
import __isObject from 'lodash/isObject'
import __mapValues from 'lodash/mapValues'
import { Action, Dispatch } from 'redux'

import { getDateFilter } from './cookie'
import { getFirstDayOfTheYear, getLastDayOfTheYear } from './date'

export function getIdsFromList(list: Array<{ id: ItemIdType }>) {
  return list.map(d => d.id)
}

export function bindActionToPromise<Payload, Results>(
  dispatch: Dispatch,
  action: (
    payload: Payload,
    meta: { resolve: (value: Results | PromiseLike<Results>) => void; reject: (reason?: unknown) => void }
  ) => Action
) {
  return function actionCreator(payload: Payload) {
    return new Promise<Results>((resolve, reject) => dispatch(action(payload, { resolve, reject })))
  }
}

type BackendErrorResponse = {
  message?: string
  response: { data: BackendErrorResponseData; status: number }
}

// handle both v1 ("_error") and v2 ("Error") api errors
function getErrorMessageFromData<Data extends BackendErrorResponseData>(data?: Data) {
  return data?.['_error'] || data?.['non_field_errors'] || data?.Error || data?.nonFieldErrors || null
}

export function getErrorMessage<Data extends BackendErrorResponseData>({
  message,
  response,
}: {
  message?: string
  response?: { data: Data }
}) {
  const responseErrorData = getErrorMessageFromData(response?.data)

  if (responseErrorData) {
    return responseErrorData
  }
  return message
}

export function getErrorMessageFromBlob({
  message,
  response: { data: blob },
}: {
  message?: string
  response: { data: Blob }
}) {
  try {
    const promise = new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.addEventListener('abort', reject)
      reader.addEventListener('error', reject)
      reader.addEventListener('loadend', () => {
        resolve(reader.result)
      })
      reader.readAsText(blob)
    })

    return promise.then(json => {
      const data = JSON.parse(String(json))
      if (data && __isObject(data)) {
        return getErrorMessageFromData(data)
      }
      return message
    })
  } catch (e) {
    // ignore error
    return message
  }
}

function parseFormErrors(data: BackendErrorResponseData) {
  return __mapValues(data, group => {
    if (__isArray(group) && group.length > 0) {
      const tmp = group[0]
      if (__isObject(tmp)) {
        // A) data.assignments = [{key: [error]}] - group = assignments
        return group.map(field => {
          return __mapValues(field as Record<string, string[]>, innerGroup => {
            if (__isArray(innerGroup) && innerGroup.length > 0) {
              return innerGroup[0]
            }
            // create payment: data.notificationEmails: [{ emails: {0: [error]}}]
            if (__isObject(innerGroup)) {
              return Object.values(innerGroup)
            }
            return innerGroup
          })
        })
      }
      // B) data.assignments = [error]
      return tmp
    }
    // C) data = {_error: errorMsg} - group = errorMsg
    return group
  }) as Record<string, string | string[] | undefined>
}

export function getFormErrors(error: unknown): Record<string, string | string[] | undefined> | string | undefined {
  if (__isObject(error) && Object.prototype.hasOwnProperty.call(error, 'response')) {
    const {
      message,
      response: { data, status },
    } = error as BackendErrorResponse
    if (status === 400 && __isObject(data)) {
      return parseFormErrors(data)
    }
    const errorMsg = getErrorMessage({ message, response: { data } })
    return { _error: errorMsg }
  } else if (typeof error === 'string') {
    return error
  } else if (error instanceof Error) {
    return error.message
  } else {
    return 'Unknown error'
  }
}

function parseRFFFormErrors(errors: string | Record<string, string | string[] | undefined> | undefined) {
  if (typeof errors === 'string') {
    return { [FORM_ERROR]: errors }
  }
  // this is the old way that backend handles non field errors
  if (__isObject(errors)) {
    if (errors['_error']) {
      errors[FORM_ERROR] = errors['_error']
      delete errors['_error']
    }
    // this is the new way that backend handles non field errors
    if (errors['non_field_errors']) {
      errors[FORM_ERROR] = errors['non_field_errors']
      delete errors['non_field_errors']
    }
    if (errors['Error']) {
      errors[FORM_ERROR] = errors['Error']
      delete errors['Error']
    }
  }
  return errors
}

//* UTIL for migrate redux-form > react-final-form
export function getRFFFormErrors(error: unknown) {
  const errors = getFormErrors(error)

  return parseRFFFormErrors(errors)
}

function getPaginationOptionsFromStore<StoreState extends { page: number; pageSize: number }>({
  page,
  pageSize,
}: StoreState) {
  return {
    page,
    pageSize,
  }
}

// TODO: del this once all services are migrated to service v2
function getOrderingFromStore<StoreState extends { order: OrderOptions; orderBy: string }>({
  order,
  orderBy,
}: StoreState) {
  const prefix = order === 'asc' ? '' : '-'
  return prefix + orderBy
}

//! NOTE: mixed with v1 and v2 services - using v2 UI with v1 API
export function getPartnerParamsFromStore(state: Store, { withType = false, withPage = false } = {}) {
  const {
    fromDate,
    toDate,
    partnerList: { search, type },
  } = state.filters

  const { page, pageSize } = getPaginationOptionsFromStore(state.partners)
  const ordering = getOrderingFromStore(state.partners)
  const params: Record<string, unknown> = {
    fromDate,
    toDate,
    search,
    pageSize,
    ordering,
  }

  if (withType) {
    params.type = type
  }

  if (withPage) {
    params.page = page
  }

  return cleanQueryParams(params)
}

function cleanQueryParams(params: Record<string, unknown>) {
  if (__isObject(params)) {
    const tmp = {} as Record<string, unknown>
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key) && params[key] !== '' && params[key] !== false) {
        tmp[key] = params[key]
      }
    }
    return tmp
  }
  return params
}

export function getCompanyType(state: Store, id: number) {
  const company_types = state?.dashboard?.common?.company_types || []
  return __find(company_types, ['id', id])?.name
}

export function getIndustry(state: Store, id: number) {
  const industries = state?.dashboard?.common?.industries || []
  return __find(industries, ['id', id])?.name
}

function getDate(dates: Array<string | null | undefined>) {
  return dates.find(date => date != null)
}

// this method is pass filters to dashboard and datepickers
export function getInitialDateFilters() {
  return {
    fromDate: getDate([getDateFilter('fromDate'), getFirstDayOfTheYear()]),
    toDate: getDate([getDateFilter('toDate'), getLastDayOfTheYear()]),
  }
}

export function generateBulkRemoveAPIPayload(
  payload:
    | { selectedInvoices: number[]; isAllInvoiceSelected: boolean }
    | { selectedSalaries: number[]; isAllSalarySelected: boolean }
    | { selectedTaxes: number[]; isAllTaxSelected: boolean },
  filters: Record<string, unknown>,
  dataType: 'expense' | 'salary' | 'tax'
) {
  const { tags, ...params } = filters

  const apiPayload = {} as { data: unknown; params: Record<string, unknown> }

  if (dataType === 'expense') {
    const { selectedInvoices, isAllInvoiceSelected } = payload as {
      selectedInvoices: number[]
      isAllInvoiceSelected: boolean
    }
    apiPayload.data = { invoice_ids: isAllInvoiceSelected ? 'all' : selectedInvoices }
    // only send filters when isAllInvoiceSelected is true
    if (isAllInvoiceSelected) {
      apiPayload.params = params
    }
  } else if (dataType === 'salary') {
    const { selectedSalaries, isAllSalarySelected } = payload as {
      selectedSalaries: number[]
      isAllSalarySelected: boolean
    }
    apiPayload.data = { monthly_salary_ids: isAllSalarySelected ? 'all' : [], salary_ids: selectedSalaries }
    // only send filters when isAllInvoiceSelected is true
    if (isAllSalarySelected) {
      apiPayload.params = params
    }
  } else {
    // tax
    const { selectedTaxes, isAllTaxSelected } = payload as { selectedTaxes: number[]; isAllTaxSelected: boolean }
    apiPayload.data = { monthly_tax_ids: isAllTaxSelected ? 'all' : [], tax_ids: selectedTaxes }
    // only send filters when isAllInvoiceSelected is true
    if (isAllTaxSelected) {
      apiPayload.params = params
    }
  }

  return apiPayload
}

export function getActiveCompanyId(state: Store) {
  return state.auth.company.data.id
}

export function getActiveCompany(state: Store): Company {
  return state.auth.company.data
}

export function getDefaultUserCompanyId(state: Store) {
  const companies = state.auth.companies
  // select when user has only one company
  if (companies.length === 1) {
    return companies[0].id
  }
  return null
}

//* INCOME
export function getIncomeTypes(state: Store) {
  return state.dashboard.common.income_types
}
export function getIncomeTypeText({ income_type }: IncomeDetailsFrontendValues, options: CommonValueAndLabelType[]) {
  return __find(options, { value: income_type })?.label || null
}

// type: based on WorkoutFilterProps
// used for filter workout data (partner, cluster [optional]) and details data (cluster [optional])
export function transformWorkoutFiltersToParams(filters: {
  partner?: string
  cluster: { value: { min: number; max: number } }
}) {
  const params = {
    partner_name: filters?.partner,
    exp_min: filters.cluster?.value.min,
    exp_max: filters.cluster?.value.max,
  }
  return cleanQueryParams(params)
}

// make a flat object from nested errors
export function parseErrors(
  errors: Record<string, any> | undefined,
  parentKey = '',
  result: Record<string, string> = {}
) {
  if (!errors) {
    return result
  }

  Object.keys(errors).forEach(key => {
    const value = errors[key]
    const newKey = parentKey ? `${parentKey}.${key}` : key

    if (__isArray(value)) {
      const tmp = value[0]
      if (__isObject(tmp)) {
        value.forEach((item, index) => {
          parseErrors(item, `${newKey}[${index}]`, result)
        })
      }
    } else if (typeof value === 'object' && value !== null) {
      parseErrors(value, newKey, result)
    } else {
      result[newKey] = value
    }
  })

  return result
}

export function parseRHFInitialErrors(initialErrors: Nullable<BackendErrorResponseData> | undefined) {
  if (!initialErrors) {
    return null
  }

  const formErrors = parseFormErrors(initialErrors)
  return parseErrors(formErrors)
}
