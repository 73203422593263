import React from 'react'

import { connect } from 'react-redux'

import { expenseActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { ReactHookFormSearchSelectField } from '@components/ui/FormElements'
import { ReactHookFormSearchSelectFieldProps } from '@components/ui/FormElements/ReactHookFormSearchSelectField'

import { QuickUserFeaturePermissions } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { JobNumberMessage } from '@messages'

interface JobNumberSearchFieldProps
  extends Omit<
    ReactHookFormSearchSelectFieldProps<{ name: string }>,
    'label' | 'onSelect' | 'SelectComponent' | 'OptionComponent'
  > {
  isJobNumberCreateDeniedForUser: boolean
}

function PureJobNumberSearchField({
  isJobNumberCreateDeniedForUser,
  onCreateOption,
  ...rest
}: JobNumberSearchFieldProps) {
  return (
    <ReactHookFormSearchSelectField
      {...rest}
      label={JobNumberMessage}
      isClearable
      // controlled creatable behavior
      {...(!isJobNumberCreateDeniedForUser && { onCreateOption })}
    />
  )
}

export const JobNumberSearchField = connect(
  (state: Store) => ({
    isJobNumberCreateDeniedForUser: permissionDeniedForUser(
      state,
      QuickUserFeaturePermissions.denyJobNumberWritePermission
    ),
  }),
  dispatch => ({
    onCreateOption: bindActionToPromise(dispatch, expenseActions.createJobNumber.request),
    searchOptions: bindActionToPromise(dispatch, expenseActions.searchJobNumbers.request),
  })
)(PureJobNumberSearchField)

JobNumberSearchField.displayName = 'JobNumberSearchField'
