import React from 'react'

import { connect } from 'react-redux'

import { isAdvancedApprovalEnabled } from '@helpers'

import { AdvancedApprovalTagSelectInput } from '@components/ui/FormElements'

import { ReactHookFormTagField, ReactHookFormTagFieldProps } from './ReactHookFormTagField'

interface ReactHookFormApprovalTagFieldProps
  extends Omit<
    ReactHookFormTagFieldProps,
    'company' | 'isTagCreateDeniedForUser' | 'isTagsDeniedForUser' | 'options' | 'createNewTag'
  > {
  isAdvancedApproval: boolean
}

export function PureReactHookFormApprovalTagField({ isAdvancedApproval, ...rest }: ReactHookFormApprovalTagFieldProps) {
  return (
    <ReactHookFormTagField
      {...rest}
      SelectComponent={isAdvancedApproval ? AdvancedApprovalTagSelectInput : undefined}
    />
  )
}

export const ReactHookFormApprovalTagField = connect((state: Store) => ({
  isAdvancedApproval: isAdvancedApprovalEnabled(state),
}))(PureReactHookFormApprovalTagField)

ReactHookFormApprovalTagField.displayName = 'ReactHookFormApprovalTagField'
