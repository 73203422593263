import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { NavLink } from '@components/navigations'

import { QuickUserPagePermissions, RouteKeys, ROUTES } from '@constants'

import { useStyles } from '../styles'

interface PartnersNavLinksProps {
  hasSubscription: boolean
  lastVisitedSubPage?: string
}

function PurePartnersNavLinks({ hasSubscription, lastVisitedSubPage }: PartnersNavLinksProps) {
  const classes = useStyles()

  return (
    <NavLink
      to={lastVisitedSubPage ?? ROUTES.partners.replace(':partner_type', 'providers')}
      className={classes.navLink}
      permission={QuickUserPagePermissions.denyPartnerPermission}
      noSubscription={!hasSubscription}
    >
      <span className={classes.link}>
        <FormattedMessage id="navLink.partners" defaultMessage="Partnerek" />
      </span>
    </NavLink>
  )
}

PurePartnersNavLinks.propTypes = {
  hasSubscription: PropTypes.bool.isRequired,
  lastVisitedSubPage: PropTypes.string,
}

export const PartnersNavLinks = connect((state: Store) => ({
  lastVisitedSubPage: state.controls.lastVisitedSubPage[RouteKeys.PARTNERS],
}))(PurePartnersNavLinks)

PartnersNavLinks.displayName = 'PartnersNavLinks'
