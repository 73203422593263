import React from 'react'

import { FormattedMessage } from 'react-intl'

import { NavLink, NoLinkTooltip } from '@components/navigations'
import { NavLinkTooltipVariant } from '@components/navigations/constants'

import { QuickUserPagePermissions, ROUTES } from '@constants'

import { PagePermissions, PlanPermission } from '@permissions'

import { CashFlowNavLinks } from './CashFlowNavLinks'
import { PartnersNavLinks } from './PartnersNavLinks'
import { TransactionsNavLink } from './TransactionsNavLink'

import { LiquidityPageMessage, MonthlyStatementPageMessage } from '@messages'
import { useStyles } from '../styles'

interface MainMenuProps {
  hasSubscription: boolean
}

export function MainMenu({ hasSubscription }: MainMenuProps) {
  const classes = useStyles()
  return (
    <>
      <NavLink
        to={ROUTES.pulse}
        className={classes.navLink}
        permission={QuickUserPagePermissions.denyReportPermission}
        noSubscription={!hasSubscription}
      >
        <span className={classes.link}>
          <FormattedMessage id="navLink.reports" defaultMessage="Pulzus" />
        </span>
      </NavLink>
      <TransactionsNavLink hasSubscription={hasSubscription} />
      <PlanPermission
        perform={PagePermissions.LIQUIDITY}
        yes={() => (
          <NavLink
            to={ROUTES.liquidity}
            className={classes.navLink}
            permission={QuickUserPagePermissions.denyLiquidityPermission}
            noSubscription={!hasSubscription}
          >
            <span className={classes.link}>{LiquidityPageMessage}</span>
          </NavLink>
        )}
        no={() => (
          <NoLinkTooltip variant={NavLinkTooltipVariant.UPSELL}>
            <span className={classes.link}>{LiquidityPageMessage}</span>
          </NoLinkTooltip>
        )}
      />
      <PartnersNavLinks hasSubscription={hasSubscription} />
      <PlanPermission
        perform={PagePermissions.MONTHLY_STATEMENT}
        yes={() => (
          <NavLink
            to={ROUTES.monthlyStatement}
            className={classes.navLink}
            permission={QuickUserPagePermissions.denyAnalyticsPermission}
            noSubscription={!hasSubscription}
          >
            <span className={classes.link}>{MonthlyStatementPageMessage}</span>
          </NavLink>
        )}
        no={() => (
          <NoLinkTooltip variant={NavLinkTooltipVariant.UPSELL}>
            <span className={classes.link}>{MonthlyStatementPageMessage}</span>
          </NoLinkTooltip>
        )}
      />
      <CashFlowNavLinks hasSubscription={hasSubscription} />
    </>
  )
}
