import { serialize } from 'object-to-formdata'

import { injectCompanyToURL } from '@helpers'

import { AttachmentUploadFormValues } from '@components/Attachments/types'

import Api from '../api'
import {
  API_ATTACHMENT_DOWNLOAD_URL,
  API_ATTACHMENT_URL,
  API_ATTACHMENTS_URL,
  API_BACKGROUND_JOB_EXPORT_EMAIL_URL,
} from './urls'

function createAttachmentUrl(
  url: string,
  {
    companyId,
    documentId,
    documentType,
  }: { companyId: Company['id']; documentId: number; documentType: AttachmentDocumentType }
) {
  return injectCompanyToURL(url, companyId)
    .replace('{document_type}', documentType)
    .replace('{document_id}', String(documentId))
}

export function fetchAttachments(
  companyId: Company['id'],
  { documentId, documentType }: { documentId: number; documentType: AttachmentDocumentType }
) {
  return Api.get(createAttachmentUrl(API_ATTACHMENTS_URL, { companyId, documentType, documentId }))
}

export function createAttachment(
  companyId: Company['id'],
  { documentId, documentType, ...data }: AttachmentUploadFormValues
) {
  return Api.post(createAttachmentUrl(API_ATTACHMENTS_URL, { companyId, documentType, documentId }), data, {
    timeout: 5 * 60000, // 5 * 60sec
    transformRequest: [
      function (data) {
        return serialize(data)
      },
    ],
  })
}

export function removeAttachment(
  companyId: Company['id'],
  { documentId, attachmentId, documentType }: AttachmentAsyncActionPayload
) {
  return Api.delete(
    createAttachmentUrl(API_ATTACHMENT_URL, { companyId, documentType, documentId }).replace(
      '{attachment_id}',
      String(attachmentId)
    )
  )
}

export function downloadAttachment(
  companyId: Company['id'],
  { documentId, attachmentId, documentType }: AttachmentAsyncActionPayload
) {
  return Api.get(
    createAttachmentUrl(API_ATTACHMENT_DOWNLOAD_URL, { companyId, documentType, documentId }).replace(
      '{attachment_id}',
      String(attachmentId)
    )
  )
}

// public PAGE to download export files - send email with download link
export function downloadExport({ hash }: { hash: string }) {
  return Api.get(API_BACKGROUND_JOB_EXPORT_EMAIL_URL.replace('{hash}', hash))
}
