import React from 'react'

import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components'

import ContentWithLoadingOverlay from './ContentWithLoadingOverlay'
import { Table, TableBody, TableCell } from './TableElements'
import { Typography } from './Typography'

const HistoryTitleTypography = styled(Typography)<{ $hasDetails: boolean }>`
  border-bottom: ${({ theme, $hasDetails }) => ($hasDetails ? `1px solid ${theme.colors.common.paperStroke}` : 'none')};
  padding: 10px;
`

const HistoryTableCell = styled(TableCell)`
  border: 0;

  &:first-of-type {
    padding: 5px 10px 0;
  }
  &:last-of-type {
    padding: 5px 10px 0;
  }
`

const NoDataTypography = styled(Typography)`
  padding: 0 10px;
`

const StyledProgress = styled(CircularProgress).attrs({ size: 16, color: 'primary' })`
  font-size: 14px;
  margin: 8px;
`

const HistoryTableContainer = styled.div`
  padding: 5px 0 0;
  text-align: center;
`

interface AsyncTwoColumnDataTableProps {
  children?: React.ReactNode
  data: Record<'label' | 'value', React.ReactNode>[]
  emptyDataText: StringOrMessage
  fetched: boolean
  headingText: StringOrMessage
  loading: boolean
}

export function AsyncTwoColumnDataTable({
  children,
  data,
  emptyDataText,
  fetched,
  loading,
  headingText,
}: AsyncTwoColumnDataTableProps) {
  return (
    <>
      <HistoryTitleTypography color="blue" size="700-xs" $hasDetails={fetched}>
        {headingText}
      </HistoryTitleTypography>
      <HistoryTableContainer>
        {fetched && (
          <ContentWithLoadingOverlay loading={loading} size={16}>
            <Table>
              <TableBody>
                {data.map(({ label, value }, index) => (
                  <tr key={index}>
                    <HistoryTableCell>
                      <Typography color="gray-80" tag="span" size="700-xs">
                        {label}
                      </Typography>
                    </HistoryTableCell>
                    <HistoryTableCell align="right">{value}</HistoryTableCell>
                  </tr>
                ))}
              </TableBody>
            </Table>
            {children}
            {data.length === 0 && (
              <NoDataTypography size="400-xs" color="gray-80" align="left">
                {emptyDataText}
              </NoDataTypography>
            )}
          </ContentWithLoadingOverlay>
        )}
        {!fetched && <StyledProgress />}
      </HistoryTableContainer>
    </>
  )
}
