import { Theme } from '@material-ui/core/styles'
import { StyleRulesCallback } from '@material-ui/styles'

import { formStyles } from '@styles'

export const SPACING = 4

export const HIGHLIGHT_STYLES = {
  rightCell: {
    marginTop: -1,
    padding: '0 24px 16px 8px',
    marginLeft: -8,
    marginRight: -26,
  },
  middleCell: {
    marginTop: -1,
    padding: '0 8px 16px',
    marginLeft: -8,
    marginRight: -8,
  },
  row: {
    marginBottom: '-1rem',
  },
  leftCell: {
    marginTop: -1,
    padding: '0 8px 16px 24px',
    marginLeft: -26,
    marginRight: -8,
  },
  formBlock: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}

const styles: StyleRulesCallback<Theme, Record<never, never>> = theme => ({
  // InvoiceAssignments
  asssignmentBlock: {
    borderTop: '1px solid #ddd',
    margin: '0 -1rem',
    padding: '1rem',
    // eslint-disable-next-line max-len
    '&:not(.disabled):not(.highlighted):nth-child(odd), &.highlighted:nth-child(even)': {
      backgroundColor: '#FAFAFA',

      '& input:not(:disabled), & .MuiInput-root:NOT(.Mui-disabled), & .react-select__control:not(.react-select__control--is-disabled)':
        {
          backgroundColor: '#fff',
        },
    },
    '&.highlighted:first-child': {
      paddingTop: 0,
      borderTop: 'none',
    },
    '&.highlighted': {
      margin: '0 -24px',
      padding: '16px 24px',
    },
  },
  assignmentBlockTitle: {
    '& h4': {
      margin: 0,
    },
  },
  assignmentButtonBlock: {
    borderTop: '1px solid #D5E0EB',
    margin: '0 -1rem',
    padding: '.5rem 1rem 0',
    '&.highlighted': {
      margin: '0 -24px',
      padding: '.5rem 24px 0',
    },
  },
  blockDeleteContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 15,
  },
  blockButtonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 20,
    paddingBottom: 15,
  },
  clearButton: {
    minHeight: 0,
    minWidth: 0,
    width: 22,
    height: 22,
    lineHeight: 18,
    borderRadius: '50%',
    padding: 2,
  },
  // DetailsForm
  detailsForm: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    '& .form-control': {
      marginTop: 6,
      marginBottom: 0,
    },
  },
  detailsFormButtonsContainer: {
    marginTop: theme.sizes.medium,
    flexGrow: 1,
  },
  refreshButton: {
    width: 20,
    height: 'auto',
    opacity: 0.25,
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      opacity: 1,
    },
  },
  refreshRoot: {
    border: `1px solid ${theme.palette.grayscale.light}`,
    backgroundColor: theme.palette.grayscale.inputBackground,
    borderRadius: 4,
    paddingLeft: 10,
    paddingRight: 4,
    '&:not(:focus-within).Mui-error': {
      borderColor: theme.palette.error.main,
    },
  },
  refreshDisabled: {
    backgroundColor: theme.palette.grayscale.disabledInputBackground,

    '.readonly &': {
      backgroundColor: theme.palette.grayscale.inputBackground,
    },
  },
  refreshInput: {
    border: 'none',
    paddingLeft: 0,
    paddingRight: 0,
  },
  refreshFocused: {
    borderColor: theme.palette.tertiary.outlineBlue,
  },
  buttonContainer: {
    marginTop: 16,
    paddingTop: '1em',
    height: 'calc(1em + 34px)',
    display: 'flex',
    alignItems: 'center',
  },
  accountingPeriodCheckbox: {
    height: 56,
    justifyContent: 'flex-end',
  },
  formBlock: {
    padding: '1rem',
    margin: '0 -1rem',
    '& + &': {
      borderTop: `1px solid ${theme.palette.tertiary.workoutDefaultGray}`,
    },
  },
  blockTitle: {
    marginTop: 0,
    marginBottom: -10,
    color: theme.palette.grayscale.xxxDark,
  },
  isEditDisabled: {
    color: theme.palette.grayscale.inputLabel,
  },
  highlightedLabel: {
    color: theme.palette.grayscale.xxxDark,
    fontWeight: 700,
  },
  extraText: {
    marginTop: 3,
    fontSize: 12,

    '&>button': {
      height: 'auto',
      fontSize: 12,
      lineHeight: '16px',
      color: theme.palette.grayscale.inputLabel,
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      letterSpacing: -0.4,
      textAlign: 'left',
      fontStyle: 'italic',
    },
  },
  extraTextError: {
    marginTop: 3,
  },
  lightTooltip: {
    ...theme.lightTooltip,
    fontSize: 14,
  },
  smartTagTooltip: {
    display: 'flex',
    '&:hover': {
      cursor: 'help',
    },
    '& > svg': {
      transform: 'translate(3px, -3px)',
    },
  },
  extraButtonContainer: {
    flex: 1,
    textAlign: 'right',
    '& > * + *': { marginLeft: 15 },
  },
  autoTagSelect: {
    '& .react-select__multi-value': {
      backgroundColor: theme.palette.tertiary.lightPurple,
    },
    '& .react-select__multi-value__label, & .react-select__multi-value__remove': {
      color: theme.palette.tertiary.darkPurple,
    },
  },
})

const mergedStyles: StyleRulesCallback<Theme, Record<never, never>> = theme => {
  const form = formStyles(theme)
  const extra = styles(theme)
  return {
    ...form,
    ...extra,
  }
}

export default mergedStyles
