import React from 'react'
import PropTypes from 'prop-types'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import uniqueId from 'lodash/uniqueId'
import styled, { css, DefaultTheme, ThemedStyledProps } from 'styled-components'

import { applyOpacity, unreachable } from '@helpers'

import { useScrollIntoView } from '@hooks'

import { Collapse, CollapseControlButton, CollapseControlButtonProps } from '../CollapseElements'

type IconSize = 'small' | 'medium'
type Variant = 'default' | 'plain' | 'card' | 'message'

interface IconWrapperProps {
  iconSize: IconSize
}

const TRANSITION_TIMEOUT_MS = 300

const getIconWrapperStyles = (iconSize: IconWrapperProps['iconSize'] = 'small') => {
  switch (iconSize) {
    case 'small':
      return css`
        width: 24px;
        height: 24px;
      `
    case 'medium':
      return css`
        width: 32px;
        height: 32px;
      `
    default:
      return unreachable(iconSize)
  }
}

const IconWrapper = styled.span<IconWrapperProps>`
  color: ${({ theme }) => applyOpacity(theme.colors.gray[100], 54)};
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transform: rotate(0deg);
  transition: transform var(--accordion-transition-config), opacity var(--accordion-transition-config);
  ${({ iconSize }) => getIconWrapperStyles(iconSize)}
`

const StyledAccordionHeader = styled(CollapseControlButton)`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  display: flex;
  font-size: inherit;
  gap: 8px;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  border: none;
  border-radius: 0;

  &:hover:not(:disabled),
  &:active:not(:disabled),
  &:focus:not(:disabled) {
    box-shadow: none;
  }

  &:hover,
  &[aria-expanded='true'] {
    path {
      transition: stroke var(--accordion-transition-config);
    }
  }

  &[aria-expanded='true'] {
    ${IconWrapper} {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    opacity: 1;
    color: ${({ theme }) => theme.colors.gray[40]};

    ${IconWrapper} {
      opacity: 0;
    }
  }
`

interface AccordionHeaderProps extends CollapseControlButtonProps {
  contentId: string
  disabled: boolean
  handleClick: (event: React.MouseEvent<HTMLElement>) => void
  handleMouseEnter: (event: React.MouseEvent<HTMLElement>) => void
  handleMouseLeave: (event: React.MouseEvent<HTMLElement>) => void
  headerContent: React.ReactChild
  iconSize: IconSize
  id: string
  isExpanded: boolean
}

function AccordionHeader({
  contentId,
  disabled,
  handleClick,
  handleMouseEnter,
  handleMouseLeave,
  headerContent,
  iconSize,
  id,
  isExpanded,
}: AccordionHeaderProps) {
  return (
    <StyledAccordionHeader
      contentId={contentId}
      data-testid="accordion-header"
      disabled={disabled}
      id={id}
      isExpanded={isExpanded}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* span is to ensure hover opacity always applies to the correct part of the header section */}
      <span>{headerContent}</span>
      <IconWrapper iconSize={iconSize} data-testid="accordion-icon">
        <ExpandMoreIcon />
      </IconWrapper>
    </StyledAccordionHeader>
  )
}

AccordionHeader.propTypes = {
  contentId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
  headerContent: PropTypes.node.isRequired,
  iconSize: PropTypes.oneOf(['small', 'medium']).isRequired,
  id: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
}

interface AccordionWrapperProps {
  $isExpanded: boolean
  $isHovered: boolean
  $variant: Variant
}

function getAccordionWrapperVariantStyles({
  theme,
  $isExpanded,
  $isHovered,
  $variant,
}: ThemedStyledProps<AccordionWrapperProps, DefaultTheme>) {
  switch ($variant) {
    case 'default':
      return css`
        border: 1px solid ${theme.colors.common.paperStroke};
        box-shadow: 0px 5px 15px ${applyOpacity(theme.colors.secondary[50], 10)};
        outline: 2px solid ${$isHovered ? theme.colors.common.cardStroke2 : 'transparent'};
        outline-offset: -1px;
        background-color: ${theme.colors.gray[0]};

        & > ${StyledAccordionHeader} {
          border-bottom: 1px solid ${$isExpanded ? theme.colors.gray[30] : 'transparent'};
          padding-right: 16px;

          & > *:first-child {
            transition: opacity var(--accordion-transition-config);
            opacity: ${$isHovered ? `var(--accordion-hover-opacity)` : 1};
          }
        }
      `
    case 'card':
      return css`
        border: 2px solid ${theme.colors.common.paperStroke};
        border-radius: 8px;

        & > ${StyledAccordionHeader} {
          padding-right: 16px;

          & > *:first-child {
            transition: opacity var(--accordion-transition-config);
            opacity: ${$isHovered ? `var(--accordion-hover-opacity)` : 1};
          }
        }
      `
    case 'message':
      return css`
        border: 1px solid ${theme.colors.common.paperStroke};
        background-color: ${$isExpanded ? theme.colors.gray[0] : theme.colors.gray[10]};
      `
    case 'plain':
      return
    default:
      return unreachable($variant)
  }
}

export const AccordionWrapper = styled.section<AccordionWrapperProps>`
  --accordion-transition-config: ${TRANSITION_TIMEOUT_MS}ms ease-out;
  --accordion-hover-opacity: 0.7;

  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  transition: all var(--accordion-transition-config);

  ${props => getAccordionWrapperVariantStyles(props)}
`

export interface AccordionChildrenExtraProps {
  // optional props as these are assigned programmatically
  isExpanded?: boolean
  scrollIntoView?: (delay?: number) => void
}

interface BaseAccordionProps {
  children: React.ReactNode | ((props: Required<AccordionChildrenExtraProps>) => React.ReactElement)
  className?: string
  disabled?: boolean
  headerContent: React.ReactChild
  iconSize?: IconSize
  open?: boolean
  variant?: Variant
  openHandler?: never
}

interface ControlledAccordionProps extends Omit<BaseAccordionProps, 'openHandler'> {
  open: boolean
  openHandler: (isExpanding: boolean) => void
}

export type AccordionProps = BaseAccordionProps | ControlledAccordionProps

/**
 * Generic accordion component with all the logic and generic styling requirements.
 *
 * Use in controlled mode with passing `openHandler` otherwise it uses its own open handling logic.
 *
 * @param {AccordionProps} {
 *   children,
 *   className,
 *   disabled = false,
 *   headerContent,
 *   iconSize = 'medium',
 *   openHandler: onToggleExpansionCallback,
 *   open = false,
 *   variant = 'default',
 * }
 * @returns
 */
export function Accordion({
  children,
  className,
  disabled = false,
  headerContent,
  iconSize = 'medium',
  openHandler,
  open = false,
  variant = 'default',
}: AccordionProps) {
  const [isExpanded, setIsExpanded] = React.useState(open)
  const [isHovered, setIsHovered] = React.useState(false)
  const elementId = React.useMemo(() => uniqueId(), [])
  const selfRef = React.useRef<HTMLElement>(null)
  const mountedRef = React.useRef(false)

  // set mounted on first render
  React.useEffect(() => {
    if (!mountedRef.current) {
      mountedRef.current = true
    }
  }, [])

  // scroll the accordion in the view when it gets expanded but not on initial render
  const scrollIntoView = useScrollIntoView(selfRef.current, mountedRef.current && isExpanded, TRANSITION_TIMEOUT_MS)

  function handleMouseEnter() {
    setIsHovered(true)
  }

  function handleMouseLeave() {
    setIsHovered(false)
  }

  function handleClick() {
    if (openHandler) {
      openHandler(!open)
    } else {
      setIsExpanded(!isExpanded)
    }
  }

  const contentId = `accordion-content-${elementId}`
  const isAccordionOpen = typeof openHandler === 'undefined' ? isExpanded : open
  const extraProps = { isExpanded: isAccordionOpen, scrollIntoView }

  // Pass the isExpanded and scrollIntoView props to the children component.
  const childrenWithExtraProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, extraProps)
    }
    return child
  })

  return (
    <AccordionWrapper
      className={className}
      $variant={variant}
      $isHovered={isHovered}
      $isExpanded={isAccordionOpen}
      ref={selfRef}
    >
      <AccordionHeader
        id={`accordion-header-${elementId}`}
        contentId={contentId}
        iconSize={iconSize}
        isExpanded={isAccordionOpen}
        headerContent={headerContent}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        handleClick={handleClick}
        disabled={disabled}
      />
      <Collapse id={contentId} isExpanded={isAccordionOpen} data-testid="accordion-content">
        {typeof children === 'function' ? children(extraProps) : childrenWithExtraProps}
      </Collapse>
    </AccordionWrapper>
  )
}

Accordion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  headerContent: PropTypes.node.isRequired,
  iconSize: PropTypes.oneOf(['small', 'medium']),
  open: PropTypes.bool,
  openHandler: PropTypes.func,
  variant: PropTypes.oneOf(['plain', 'default', 'card', 'message']),
}
