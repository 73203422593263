import React from 'react'

import styled from 'styled-components'

function BaseHourGlassStartIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M4.16636 0C3.52122 0 3 0.558594 3 1.25C3 1.94141 3.52122 2.5 4.16636 2.5V2.92969C4.16636 4.58594 4.78235 6.17578 5.87581 7.34766L8.34704 10L5.87581 12.6523C4.78235 13.8242 4.16636 15.4141 4.16636 17.0703V17.5C3.52122 17.5 3 18.0586 3 18.75C3 19.4414 3.52122 20 4.16636 20H5.33273H14.6636H15.83C16.4751 20 16.9964 19.4414 16.9964 18.75C16.9964 18.0586 16.4751 17.5 15.83 17.5V17.0703C15.83 15.4141 15.214 13.8242 14.1205 12.6523L11.6493 10L14.1242 7.34766C15.2177 6.17578 15.8336 4.58594 15.8336 2.92969V2.5C16.4788 2.5 17 1.94141 17 1.25C17 0.558594 16.4788 0 15.8336 0H14.6636H5.33273H4.16636ZM13.4973 17.0703V17.5H6.49909V17.0703C6.49909 16.0742 6.86722 15.1211 7.5233 14.418L9.99818 11.7695L12.4731 14.4219C13.1291 15.125 13.4973 16.0781 13.4973 17.0742V17.0703Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const HourGlassStartIcon = styled(BaseHourGlassStartIcon)``
