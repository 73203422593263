import { EditorStateProps } from '@hooks'

import { PaidThroughType } from '@constants'

export enum PaidThroughDialogVariant {
  LIST_ITEM = 'list_item',
  SELECT_OPTION = 'select_option',
}

export type PaidThroughFormValues = {
  account_number: Nullable<string> | undefined
  add_new_balance: boolean
  balance?: Nullable<string>
  currency?: Nullable<number>
  id?: number
  is_autokassza: boolean
  ledger_number_code: Nullable<string> | undefined
  ledger_number_name: Nullable<string> | undefined
  name: string
  provider: Nullable<number> | undefined
  paidthrough_type: Nullable<PaidThroughType>
  value_date?: Nullable<string>
}

export interface PaidThroughEditorFormContentProps {
  currencies: Currency[]
  bankProviders: BankProvider[]
  isEdit: boolean
  onClose: (event?: React.MouseEvent<HTMLElement>) => void
  detailsData?: PaidThroughDetailData | null
  isAdvancedAccounting: boolean
  isBankTransactionsAllowed: boolean
}

export interface PurePaidThroughEditorDialogProps {
  callCreateListItem: AsyncFunction<PaidThroughFormValues, PaidThroughDetailData>
  callCreateSelectOption: AsyncFunction<PaidThroughFormValues, PaidThroughDetailData>
  callUpdate: AsyncFunction<PaidThroughFormValues, PaidThroughDetailData>
  editor: EditorStateProps<number, { name: string }>
  loadDetails: AsyncFunction<number, PaidThroughDetailData>
  onClose: VoidFunction
  onSubmitSuccess?: (results: PaidThroughDetailData) => void
  variant?: PaidThroughDialogVariant
}
