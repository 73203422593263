import React from 'react'

import styled from 'styled-components'

function BaseNotEnterSolidIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M10 0.3125C4.47702 0.3125 0 4.78952 0 10.3125C0 15.8355 4.47702 20.3125 10 20.3125C15.523 20.3125 20 15.8355 20 10.3125C20 4.78952 15.523 0.3125 10 0.3125ZM17.0968 12.248H2.90323C2.54677 12.248 2.25806 11.9593 2.25806 11.6028V9.02218C2.25806 8.66573 2.54677 8.37702 2.90323 8.37702H17.0968C17.4532 8.37702 17.7419 8.66573 17.7419 9.02218V11.6028C17.7419 11.9593 17.4532 12.248 17.0968 12.248Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const NotEnterSolidIcon = styled(BaseNotEnterSolidIcon)``
