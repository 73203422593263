import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { Dialog, DialogActions, DialogContent, DialogTitle } from './DialogElements'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    paddingLeft: 65,
    paddingRight: 65,
    textAlign: 'center',
  },
  listBox: {
    margin: '20px 0 40px',
    padding: 20,
    textAlign: 'left',
    backgroundColor: theme.palette.grayscale.xxxLight,
    borderRadius: 4,
    '& > ul': {
      minWidth: 320,
      margin: 0,
      padding: 0,
      paddingLeft: 20,
      display: 'inline-block',
      textAlign: 'left',
    },
  },
  partner: { fontWeight: 'bold' },
}))

export function DeniedFilingInvoicesDialog({ data, onClose, open }) {
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="denied-filing-dialog-title">
      <DialogTitle id="denied-filing-dialog-title">
        <FormattedMessage id="dialogs.deniedFilingInvoicesDialog.title" defaultMessage="Sajnáljuk" />
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <FormattedMessage
          id="dialogs.deniedFilingInvoicesDialog.content"
          defaultMessage="Az alábbi számlákon az iktatás nem végrehajtható, amíg összerendelésre várnak egy NAV-os számlával vagy duplikációk.
        Kérünk végezd el az összerendelést vagy szüntesd meg a duplikációt."
        />

        <div className={classes.listBox}>
          <ul>
            {data.map(invoice => (
              <li key={invoice.invoiceNumber}>
                <div>
                  <div className={classes.partner}>{invoice.partner}</div>
                  <div>{invoice.invoiceNumber}</div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained" size="small">
          <FormattedMessage id="dialogs.deniedFilingInvoicesDialog.buttonText" defaultMessage="Ok" />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeniedFilingInvoicesDialog.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      partner: PropTypes.string.isRequired,
      invoiceNumber: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}
