import React from 'react'

import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { shortenString } from '@helpers'

import { FlexColumn, Typography } from '@components/ui'
import { ConfirmDialog } from '@oldComponents/dialogs'

import { AttachmentConfirmDialogView } from './reducer'

import { ConfirmDialogTitleMessage } from '@messages'

const ContentWrapper = styled(FlexColumn)`
  margin: 30px 0;
  gap: 20px;
`

export const DIALOG_ID = 'attachment-confirm-dialog'

interface AttachmentConfirmDialogProps {
  attachmentFile: Nullable<BackendAttachmentFileData>
  error: BackendError
  loading: boolean
  onClose: VoidFunction
  onSubmit: VoidFunction
  view: AttachmentConfirmDialogView
}

export function AttachmentConfirmDialog({ attachmentFile, view, ...rest }: AttachmentConfirmDialogProps) {
  const [fileName, setFileName] = React.useState<string>('')

  React.useEffect(() => {
    const attachmentFileName =
      attachmentFile?.title || shortenString(attachmentFile?.link) || shortenString(attachmentFile?.filename)
    if (attachmentFileName) {
      setFileName(attachmentFileName)
    }
  }, [attachmentFile])

  const submitButtonText =
    view === AttachmentConfirmDialogView.DELETE ? (
      <FormattedMessage id="dialogs.confirm.buttons.deleteConfirm" defaultMessage="Igen, töröljük" />
    ) : (
      <FormattedMessage id="dialogs.confirm.buttons.detachConfirm" defaultMessage="Igen, leválasztom" />
    )

  const submitButtonLoadingText =
    view === AttachmentConfirmDialogView.DELETE ? (
      <FormattedMessage id="dialogs.confirm.buttons.deleteLoading" defaultMessage="Törlés folyamatban..." />
    ) : (
      <FormattedMessage id="dialogs.confirm.buttons.detachLoading" defaultMessage="Leválasztás folyamatban..." />
    )

  return (
    <ConfirmDialog
      {...rest}
      data-testid={DIALOG_ID}
      id={DIALOG_ID}
      open={Boolean(attachmentFile)}
      submitButtonText={submitButtonText}
      submitButtonLoadingText={submitButtonLoadingText}
      title={ConfirmDialogTitleMessage}
    >
      <ContentWrapper>
        {view === AttachmentConfirmDialogView.DELETE && (
          <>
            <Typography size="700-md" color="gray-80">
              <FormattedMessage
                id="attachments.confirmDialog.removeText"
                defaultMessage='Biztosan törli a "{fileName}" csatolt dokumentumot?'
                values={{
                  fileName,
                }}
              />
            </Typography>
            <Typography size="400-sm" color="gray-70">
              <FormattedMessage
                id="attachments.confirmDialog.removeInfoText"
                defaultMessage="A művelet végrahajtását követően a dokumentum törlésre kerül a rendszerből."
              />
            </Typography>
          </>
        )}
        {view === AttachmentConfirmDialogView.DETACH && (
          <>
            <Typography size="700-md" color="gray-80">
              <FormattedMessage
                id="attachments.confirmDialog.detachText"
                defaultMessage='Biztosan leválasztja a "{fileName}" csatolt dokumentumot?'
                values={{
                  fileName,
                }}
              />
            </Typography>
            <Typography size="400-sm" color="gray-70">
              <FormattedMessage
                id="attachments.confirmDialog.detachInfoText"
                defaultMessage="A művelet végrahajtását követően a dokumentum leválasztásra kerül és a dokumentumok listában lesz elérhető."
              />
            </Typography>
          </>
        )}
      </ContentWrapper>
    </ConfirmDialog>
  )
}
