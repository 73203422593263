import React from 'react'

import styled from 'styled-components'

function BaseDetachDokumentIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path
        d="M6.57143 13.9615L8.57795 11.9132L10.5964 14.1839L8.69286 16.1271C6.4375 18.4295 6.4375 22.1592 8.69286 24.4615C10.9482 26.7639 14.6018 26.7639 16.8571 24.4615L18.3644 22.9229L20.3828 25.1937L18.9786 26.6271C15.55 30.1271 10 30.1271 6.57143 26.6271C3.14286 23.1271 3.14286 17.4615 6.57143 13.9615ZM10.4286 17.024L11.8401 15.5831L13.8585 17.8539L12.55 19.1896C12.1911 19.5561 12.1911 20.1576 12.55 20.524C12.9089 20.8904 13.4982 20.8904 13.8571 20.524L15.1022 19.253L17.1207 21.5238L15.9786 22.6896C14.4464 24.2537 11.9607 24.2537 10.4286 22.6896C8.89643 21.1256 8.89643 18.5881 10.4286 17.024ZM15.3474 12.0028L17.3658 14.2735L20.2643 11.3146C20.8482 10.7186 20.8482 9.74512 20.2643 9.14902C19.6804 8.55293 18.7268 8.55293 18.1429 9.14902L15.3474 12.0028ZM23.2857 10.899L18.6095 15.6727L20.6279 17.9434L25.4071 13.0646C27.8875 10.5326 27.8875 6.43106 25.4071 3.89902C22.9268 1.36699 18.9089 1.36699 16.4286 3.89902L12.0852 8.33287L14.1037 10.6036L18.55 6.06465C19.8571 4.73027 21.9786 4.73027 23.2857 6.06465C24.5929 7.39902 24.5929 9.56465 23.2857 10.899ZM23.8901 21.6133L21.8716 19.3426L25 16.149C25.5839 15.5529 26.5375 15.5529 27.1214 16.149C27.7054 16.7451 27.7054 17.7186 27.1214 18.3146L23.8901 21.6133ZM7.13191 4.01573C6.58831 3.3906 5.64087 3.3245 5.01573 3.86809C4.3906 4.41169 4.3245 5.35913 4.86809 5.98427L24.8681 28.9843C25.4117 29.6094 26.3591 29.6755 26.9843 29.1319C27.6094 28.5883 27.6755 27.6409 27.1319 27.0157L7.13191 4.01573Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const DetachDokumentIcon = styled(BaseDetachDokumentIcon)``
