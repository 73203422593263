import { ADVANCED_ACCOUNTING_CLASS_NAME, THEME } from '@constants'

export const selectFormStyles = {
  container: (provided: any) => ({
    ...provided,
    '&.error>div': {
      borderColor: `${THEME.palette.error.main} !important`,
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    minHeight: 36,
    // height: 36,
    backgroundColor: state.isDisabled
      ? THEME.palette.grayscale.disabledInputBackground
      : THEME.palette.grayscale.inputBackground,
    borderColor: state.isFocused ? THEME.palette.tertiary.outlineBlue : THEME.palette.grayscale.light,
    boxShadow: 'none',

    '&:hover': {
      borderColor: state.isFocused ? THEME.palette.tertiary.outlineBlue : THEME.palette.grayscale.light,
    },

    '.highlighted > &': {
      borderColor: state.isFocused ? THEME.palette.tertiary.outlineBlue : THEME.palette.primary.main,
    },

    [`.${ADVANCED_ACCOUNTING_CLASS_NAME} .highlighted > &`]: {
      borderColor: state.isFocused ? THEME.palette.tertiary.outlineBlue : THEME.palette.tertiary.purple,
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 10,
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled ? THEME.palette.grayscale.inputLabel : THEME.palette.neutral.mDark,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    color: state.isDisabled ? THEME.palette.grayscale.inputLabel : THEME.palette.neutral.mDark,
  }),
  input: (provided: any) => ({
    ...provided,
    visibility: 'visible',
    color: 'inherit',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    '&>svg': {
      width: 16,
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    paddingTop: 0,
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#AAA',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: '#ebf5ff',
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: THEME.palette.tertiary.oceanBlue,
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: state.isFocused ? THEME.palette.grayscale.xxxDark : THEME.palette.tertiary.oceanBlue,
    '&:hover': {
      backgroundColor: 'transparent',
      color: THEME.palette.grayscale.xxxDark,
      cursor: 'pointer',
    },
  }),
  groupHeading: (provided: any) => ({
    ...provided,
    fontWeight: 600,
    textTransform: 'none',
    color: THEME.palette.primary.main,
  }),
  group: (provided: any) => ({
    ...provided,
    paddingBottom: 0,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? state.isFocused
        ? 'rgba(0, 126, 255, 0.2)'
        : 'rgba(0, 126, 255, 0.14)'
      : state.isFocused
      ? 'rgba(0, 126, 255, 0.04)'
      : 'transparent',
    color: THEME.palette.neutral.mDark,
    padding: '4px 12px',

    '&:active': {
      backgroundColor: 'rgba(0, 126, 255, 0.2)',
    },
    '.custom-option': {
      display: 'block',
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& span.badge': {
      fontSize: '75%',
      color: THEME.palette.grayscale.inputLabel,
    },
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  noOptionsMessage: (provided: any) => ({
    ...provided,
    fontSize: 12,
    textAlign: 'left',
  }),
  loadingMessage: (provided: any) => ({
    ...provided,
    fontSize: 12,
    textAlign: 'left',
  }),
}

// used in InvoiceListFilterBar as tags, expense or revenue type filter
export const searchStyles = {
  container: (provided: any) => ({
    ...provided,
    padding: 0,
  }),
  control: (provided: any) => ({
    ...provided,
    padding: 2,
    minHeight: 0,
    height: 26,
    whiteSpace: 'nowrap',
    lineHeight: '24px',
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',

    '&:hover': {
      boxShadow: 'none',
      cursor: 'pointer',
    },
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: 0,
    alignItems: 'flex-start',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: THEME.palette.tertiary.oceanBlue,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    color: state.hasValue ? THEME.palette.tertiary.oceanBlue : THEME.palette.grayscale.xDark,
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
      color: THEME.palette.grayscale.xxxDark,
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? state.isFocused
        ? 'rgba(0, 126, 255, 0.2)'
        : 'rgba(0, 126, 255, 0.14)'
      : state.isFocused
      ? 'rgba(0, 126, 255, 0.04)'
      : 'transparent',
    color: THEME.palette.neutral.mDark,
    '&:active': {
      backgroundColor: 'rgba(0, 126, 255, 0.2)',
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: THEME.palette.grayscale.xDark,
    '&:hover': {
      color: THEME.palette.grayscale.xxxDark,
    },
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: THEME.palette.tertiary.oceanBlue,
    lineHeight: '12px',
    border: `1px solid ${THEME.palette.secondary.main}`,

    '& + input': {
      height: 0,
    },
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: '#fff',
    padding: 2,
    fontSize: 12,
  }),
  multiValueRemove: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: 'transparent',
    color: state.isFocused ? THEME.palette.grayscale.xxxDark : '#fff',
    '&:hover': {
      backgroundColor: 'transparent',
      color: THEME.palette.grayscale.xxxDark,
      cursor: 'pointer',
    },
  }),
  input: (provided: any) => ({
    ...provided,
    display: 'inline-flex !important',
    margin: 2,
    border: '1px solid transparent',
    height: 18,
    '&>div': {
      display: 'inline-flex !important',
    },
    '&>div>input': {
      margin: 0,
    },
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 4000 }),
}

// used in FilterDialog as TagsFilter
export const selectFilterFormStyles = {
  container: (provided: any) => ({
    ...provided,
    padding: 0,
    height: 'auto',
  }),
  control: () => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    minHeight: 0,
    whiteSpace: 'nowrap',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '4px 5px 4px 20px',
    alignItems: 'flex-start',
  }),
  clearIndicator: (provided: any) => ({
    ...provided,
    color: THEME.palette.grayscale.grey70,
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      color: THEME.palette.grayscale.black,
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: THEME.palette.grayscale.grey70,
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      color: THEME.palette.grayscale.black,
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    padding: '8px 20px',
    fontSize: 14,
    lineHeight: 1,
    backgroundColor: state.isSelected || state.isFocused ? `${THEME.palette.tertiary.oceanBlue}10` : 'transparent',
    color: THEME.palette.grayscale.black,
    '&:active': {
      backgroundColor: `${THEME.palette.tertiary.oceanBlue}10`,
    },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    margin: 0,
    fontWeight: 400,
    fontSize: 'inherit',
    color: THEME.palette.grayscale.grey70,
    opacity: 0.5,
    fontStyle: 'italic',
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: 'unset',
    lineHeight: '12px',
    color: THEME.palette.grayscale.grey70,
    border: `1px solid ${THEME.palette.grayscale.grey70}`,
    borderRadius: 4,

    '& + input': {
      height: 0,
    },
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    backgroundColor: 'unset',
    color: 'inherit',
    padding: 2,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: '16px',
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    backgroundColor: 'unset',
    color: 'inherit',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'unset',
      color: THEME.palette.grayscale.black,
    },
  }),
  menuPortal: (provided: any) => ({ ...provided, zIndex: 9999 }),
  input: () => ({
    fontSize: 'inherit',
    display: 'inline-flex !important',
    margin: 2,
    padding: 0,
    border: '1px solid transparent',
    height: 22,
    '&>div': {
      display: 'inline-flex !important',
    },
    '&>div>input': {
      margin: 0,
    },
  }),
}
