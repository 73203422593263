import React from 'react'

import { FormattedDate, FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { StrongTextValues } from '@helpers/translation'

import { CustomInfo, InfoText } from '@components/ui/InfoText'
import { Typography } from '@components/ui/Typography'
import { FlexRow } from '@components/ui/Wrappers'

import { QUICK_TYPOGRAPHY } from '@constants/theme'

import { ApprovedIcon, PendingApprovalIcon } from './styles'

const ICON_SIZE = 16

const StyledInfoList = styled.ul`
  margin: 0;
`

const ApprrovalGrid = styled.div`
  display: grid;
  column-gap: 60px;
  row-gap: 8px;
  align-items: center;
  grid-template-columns: max-content auto;
`

const StyledFlexRow = styled(FlexRow)`
  gap: 12px;
  align-items: center;
`

interface ApprovalsListProps {
  data: Array<{ email: string; approved_at: Nullable<string> }>
  showEditorInfoWhenEmpty?: boolean
}

export function ApprovalsList({ data, showEditorInfoWhenEmpty = false }: ApprovalsListProps) {
  if (data.length === 0) {
    if (showEditorInfoWhenEmpty) {
      return (
        <CustomInfo iconColor="gray-40" firstRowLineHeight={QUICK_TYPOGRAPHY['400-xs'].lineHeight}>
          <Typography color="gray-80" size="400-xs">
            <FormattedMessage
              id="expense.approvalsList.editorText"
              defaultMessage="A számla jóváhagyókat a teljes számlára vonatkozó általános infóknál, a címke és autócímke mezőiben tudod megadni, <b>kezd a gépelést a @ beírásával</b> és listázzuk a jóváhagyásra jogosultakat:"
              values={StrongTextValues}
            />
          </Typography>
          <StyledInfoList>
            <li>
              <Typography color="gray-80" size="400-xs">
                <FormattedMessage
                  id="expense.approvalsList.editorText.optionOne"
                  defaultMessage="A <b>címke</b> mezőben megadott felhasználók csak erre a számlára,"
                  values={StrongTextValues}
                />
              </Typography>
            </li>
            <li>
              <Typography color="gray-80" size="400-xs">
                <FormattedMessage
                  id="expense.approvalsList.editorText.optionTwo"
                  defaultMessage="Az <b>autócímke</b> mezőben megadott felhasználók rákerülnek jóváhagyóként a partner következő számlájára is."
                  values={StrongTextValues}
                />
              </Typography>
            </li>
          </StyledInfoList>
        </CustomInfo>
      )
    }

    return (
      <InfoText iconColor="gray-40" color="gray-80" size="400-xs">
        <FormattedMessage
          id="expense.approvalsList.viewerText"
          defaultMessage="Nincsenek megadva jóváhagyó felhasználók."
        />
      </InfoText>
    )
  }

  return (
    <ApprrovalGrid>
      {data.map(({ email, approved_at }) => (
        <React.Fragment key={`approval-list-item-${email}`}>
          <Typography size="400-xs" color="gray-80" tag="span">
            {email}
          </Typography>
          <StyledFlexRow as="span">
            {approved_at ? (
              <>
                <ApprovedIcon size={ICON_SIZE} />
                <Typography size="400-xs" color="gray-80" tag="span">
                  <FormattedMessage
                    id="approvalBlock.status.approved"
                    defaultMessage="Jóváhagyta: {date}"
                    values={{ date: <FormattedDate value={approved_at} /> }}
                  />
                </Typography>
              </>
            ) : (
              <>
                <PendingApprovalIcon size={ICON_SIZE} />
                <Typography size="400-xs" color="gray-80" tag="span">
                  <FormattedMessage id="approvalBlock.status.pending" defaultMessage="Jóváhagyásra vár" />
                </Typography>
              </>
            )}
          </StyledFlexRow>
        </React.Fragment>
      ))}
    </ApprrovalGrid>
  )
}
