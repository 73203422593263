//! NOTE This helper is render checkbox for new react-final-form forms

import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormControlLabelProps, FormHelperText } from '@material-ui/core'
import cx from 'classnames'
import { FieldRenderProps } from 'react-final-form'

import { CustomCheckbox, CustomFormControlLabel } from './elements'

interface RenderCheckboxProps extends FieldRenderProps<boolean | string | number, HTMLInputElement> {
  color?: 'primary' | 'secondary' | 'default'
}

export function RenderRffCheckbox({
  className,
  color = 'default',
  disabled = false,
  displayError = true,
  fullWidth = true,
  input: { name, value, onChange, checked = false, ...restInput },
  labelClassName,
  meta: { dirtySinceLastSubmit, error, submitError, touched, modifiedSinceLastSubmit },
  required = false,
  ...rest
}: RenderCheckboxProps) {
  const displayedError = touched && (error || (!dirtySinceLastSubmit && !modifiedSinceLastSubmit && submitError))
  const hasError = Boolean(displayedError)

  return (
    <FormControl
      className={cx(className, 'form-control', { 'form-control-error': hasError })}
      fullWidth={fullWidth}
      error={hasError}
      data-testid={`${name}_checkbox-rff-field-form-control`}
    >
      <CustomFormControlLabel
        className={labelClassName}
        control={
          <CustomCheckbox
            checked={checked}
            color={color}
            data-testid={`${name}_checkbox-rff-field-input`}
            disabled={disabled}
            id={name}
            inputProps={{ required, ...restInput }}
            name={name}
            onChange={onChange}
            value={value}
          />
        }
        {...(rest as Omit<FormControlLabelProps, 'control'>)}
        data-testid={`${name}_checkbox-rff-field-label`}
      />
      {hasError && displayError && <FormHelperText>{displayedError}</FormHelperText>}
    </FormControl>
  )
}

RenderRffCheckbox.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.bool.isRequired, PropTypes.string.isRequired, PropTypes.number.isRequired])
      .isRequired,
  }).isRequired,
  label: PropTypes.node.isRequired,
  labelClassName: PropTypes.string,
  meta: PropTypes.shape({
    dirtySinceLastSubmit: PropTypes.bool as React.Validator<boolean | undefined>,
    touched: PropTypes.bool.isRequired,
    error: PropTypes.node,
    submitError: PropTypes.node,
  }).isRequired,
}
