import React from 'react'
import PropTypes from 'prop-types'

import { useDebouncedCallUrl } from '@hooks'

import { PaidStatusHistory, PaidStatusIndicator, PaidStatusIndicatorProps } from './elements'

export interface PureAsyncPaidStatusProps extends Pick<PaidStatusIndicatorProps, 'placedInModal' | 'value'> {
  historyUrl: string
  invoiceCurrencyId: number
}

export function AsyncPaidStatus({ historyUrl, invoiceCurrencyId, value, ...rest }: PureAsyncPaidStatusProps) {
  const { fetched, loading, results, handleOpen, handleClose } = useDebouncedCallUrl<{
    history: BackendInvoicePaymentHistoryResults
  }>(historyUrl)

  return (
    <PaidStatusIndicator
      {...rest}
      onClose={handleClose}
      onOpen={handleOpen}
      title={
        <PaidStatusHistory
          fetched={fetched}
          data={results?.history ?? []}
          invoiceCurrencyId={invoiceCurrencyId}
          loading={loading}
          value={value}
        />
      }
      value={value}
    />
  )
}

AsyncPaidStatus.propTypes = {
  historyUrl: PropTypes.string.isRequired,
  invoiceCurrencyId: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired as React.Validator<InvoicePaidStatusValue>,
}
