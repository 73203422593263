import React from 'react'
import PropTypes from 'prop-types'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { Controller, useFormContext } from 'react-hook-form'

import { isFieldHighlighted } from '@helpers'

import { AsyncSelectInput, AsyncSelectInputProps } from './SelectInputs'

import { formStyles } from '@styles'

const useStyles = makeStyles(formStyles)

export interface ReactHookFormSearchSelectFieldProps<Option extends Record<string, unknown>>
  extends Omit<AsyncSelectInputProps<Option>, 'value' | 'onChange' | 'onBlur'> {
  disabled?: boolean
  highlighted?: boolean
  isLabelHighlighted?: boolean
  label?: React.ReactNode
  required?: boolean
  SelectComponent?: React.ComponentType<AsyncSelectInputProps<Option>>
}

export function ReactHookFormSearchSelectField<Option extends Record<string, unknown>>({
  className,
  disabled = false,
  highlighted = false,
  isLabelHighlighted = false,
  label,
  name,
  required,
  SelectComponent = AsyncSelectInput,
  ...rest
}: ReactHookFormSearchSelectFieldProps<Option>) {
  const {
    control,
    formState: { isSubmitting },
  } = useFormContext()
  const classes = useStyles()
  const isFieldDisabled = disabled || isSubmitting

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
        const hasError = !!error
        return (
          <FormControl
            className={cx(className, classes.selectRoot, 'form-control', { 'form-control-error': hasError })}
            disabled={isFieldDisabled}
            error={hasError}
            fullWidth
            margin="normal"
            required={required}
          >
            {label && (
              <InputLabel
                className={cx(classes.bootstrapFormLabel, { [classes.highlightedLabel]: isLabelHighlighted })}
                htmlFor={name}
                shrink
              >
                {label}
              </InputLabel>
            )}
            <div className={classes.selectInput}>
              <SelectComponent
                className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
                isDisabled={isFieldDisabled}
                name={name}
                // input controls
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                {...rest}
              />
            </div>
            {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        )
      }}
    />
  )
}

ReactHookFormSearchSelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLabelHighlighted: PropTypes.bool,
  label: PropTypes.node,
  labelKey: PropTypes.string,
  menuShouldBlockScroll: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onCreateCallback: PropTypes.func,
  onCreateOption: PropTypes.func,
  onEmptyCreateText: PropTypes.string,
  OptionComponent: PropTypes.elementType,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  SelectComponent: PropTypes.elementType,
  valueKey: PropTypes.string,
}
