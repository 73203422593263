export enum QuickUserPagePermissions {
  denyAnalyticsPermission = 'deny_analytics_permission',
  denyCashflowPermission = 'deny_cashflow_permission',
  denyLiquidityPermission = 'deny_liquidity_permission',
  denyPartnerPermission = 'deny_partner_permission',
  denyReportPermission = 'deny_report_permission',
  // transactions page tabs
  denyDocumentPermission = 'deny_document_permission',
  denyExpensePermission = 'deny_expense_permission',
  denyIncomePermission = 'deny_income_permission',
  denyNavPermission = 'deny_nav_permission',
  denySalaryPermission = 'deny_salary_permission',
  denyTaxPermission = 'deny_tax_permission',
}

export enum QuickUserFeaturePermissions {
  denyAccountingPermission = 'deny_accounting_permission',
  denyApproveExpensePermission = 'deny_approve_expense_permission',
  denyBankTransactionsPermission = 'deny_bank_transactions_permission',
  denyDeletePermission = 'deny_delete_permission',
  denyExpenseTypeWritePermission = 'deny_expense_type_write_permission',
  denyJobNumberWritePermission = 'deny_job_number_write_permission',
  denyRevenueTypeWritePermission = 'deny_revenue_type_write_permission',
  denyTagPermission = 'deny_tag_permission',
  denyTagWritePermission = 'deny_tag_write_permission',
  denyExpenseFilePermission = 'deny_expense_file_permission',
  denyDocumentFilePermission = 'deny_document_file_permission',
}
