import React from 'react'
import PropTypes from 'prop-types'

import cx from 'classnames'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { TASK_STATUS } from '@constants'

import { TaskListCheckedIcon } from '../svgIcons'
import { Typography } from '../Typography'
import { Accordion } from './Accordion'

const CHECKED_CLASSES = {
  checked: 'checked',
  disabled: 'disabled',
  unchecked: 'unchecked',
}

const StyledTaskListCheckedIcon = styled(TaskListCheckedIcon)`
  --unchecked-color: ${({ theme }) => theme.colors.primary[50]};
  --checked-color: ${({ theme }) => theme.colors.success[40]};

  & rect {
    transition: all 0.3s ease-in-out;
  }

  /* increased specificity required due to Accordion's transition overwrite */
  && path {
    stroke-linejoin: round;
    stroke-linecap: round;
    stroke: var(--checked-color);
    stroke-dasharray: 31;
    stroke-dashoffset: 31;
    transition: all 0.3s ease-out;
  }

  &.${CHECKED_CLASSES.checked} {
    rect {
      stroke: var(--checked-color);
    }

    path {
      stroke-dashoffset: 0;
    }
  }

  &.${CHECKED_CLASSES.unchecked} {
    rect {
      stroke: var(--unchecked-color);
    }

    path {
      stroke-opacity: 0;
      stroke-dashoffset: -31;
    }
  }

  &.${CHECKED_CLASSES.disabled} {
    color: inherit;

    rect {
      stroke: currentColor;
    }
  }
`

const HeaderWrapperTypography = styled(Typography)<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  height: 59px;
  padding: 0 16px;
  gap: 20px;
  color: ${({ theme, $disabled }) => ($disabled ? 'inherit' : theme.colors.gray[175])};
`

const ContentWrapperDiv = styled.div`
  padding: 6px 16px 16px;
  background-color: ${({ theme }) => theme.colors.gray[0]};
`

interface BaseTaskListAccordionProps {
  children: React.ReactChild
  index: number
  name: string
  open: boolean
  openHandler?: (name: string, isExpanding: boolean) => void
  status: 'current' | 'skipped' | 'completed' | 'disabled'
  title: StringOrMessage
}

/**
 * Accordion component for a task list design with checkbox icon display representing the state of the task.
 *
 * @param {BaseTaskListAccordionProps} { index, title, open, children, status }
 */
export function TaskListAccordion({
  children,
  index,
  name,
  open,
  openHandler: openHandlerProp,
  status,
  title,
}: BaseTaskListAccordionProps) {
  const isCompleted = status === TASK_STATUS.completed || status === TASK_STATUS.skipped
  const isDisabled = status === TASK_STATUS.disabled

  function openHandler(isExpanding: boolean) {
    openHandlerProp?.(name, isExpanding)
  }

  return (
    <Accordion
      disabled={isDisabled}
      headerContent={
        <HeaderWrapperTypography size="700-md" tag="span" $disabled={isDisabled}>
          <StyledTaskListCheckedIcon
            className={cx({
              [CHECKED_CLASSES.checked]: isCompleted,
              [CHECKED_CLASSES.unchecked]: !isCompleted,
              [CHECKED_CLASSES.disabled]: isDisabled,
            })}
          />
          <FormattedMessage
            id="accordion.taskList.title"
            defaultMessage="{index}. {title}"
            values={{
              index,
              title,
            }}
          />
        </HeaderWrapperTypography>
      }
      open={open}
      {...(openHandlerProp && { openHandler })}
      variant="card"
    >
      {props => {
        return (
          <ContentWrapperDiv>
            {React.Children.map(children, child => {
              // Checking isValidElement is the safe way and avoids a typescript error too.
              if (React.isValidElement(child)) {
                return React.cloneElement(child, props)
              }
              return child
            })}
          </ContentWrapperDiv>
        )
      }}
    </Accordion>
  )
}

TaskListAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  openHandler: PropTypes.func,
  status: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
}
