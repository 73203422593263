import React from 'react'
import { bool, node, number, shape } from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { FormattedMessage } from 'react-intl'

import OverflowTextWithTooltip from '@oldComponents/OverflowTextWithTooltip'

import { LightTooltip } from './LightTooltip'
import LinkIcon from './SvgIcons/LinkIcon'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  badge: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.neutral.xxxLight,
    borderRadius: 4,
    border: '1px solid rgba(0,0,0,.05)',
    padding: '2px 6px',
    lineHeight: 1,
    '* + &': {
      marginLeft: 4,
    },
  },
  notSeenYet: { backgroundColor: theme.palette.tertiary.oceanBlue },
  expire: { backgroundColor: theme.palette.primary.dark },

  partner: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '& + .missing-warning': {
      marginLeft: 2,
    },
  },
  szlahu: {
    marginLeft: 4,
    '&>svg': { transform: 'translate(0, 2px)' },
  },
  nav: {
    backgroundColor: theme.palette.tertiary.navBadge,
    whiteSpace: 'nowrap',
  },
}))

export default function ValueWithBadges({
  children,
  differentWarning,
  badges: {
    expireSoon = false,
    expired = false,
    notSeenYet = false,
    updated = false,
    isDeficient = false,
    isNavLink = false, // TODO "számlakép hiányos badge"
    isNavNoPreview = false,
  },
  maxWidth = 240,
}) {
  const classes = useStyles()
  return (
    <div className={classes.root} style={{ maxWidth }}>
      {children && <OverflowTextWithTooltip className={classes.partner}>{children}</OverflowTextWithTooltip>}
      {differentWarning}
      {isDeficient && (
        <div className={cx(classes.badge, classes.expire)}>
          <FormattedMessage id="badge.deficient.data" defaultMessage="Adathiányos" />
        </div>
      )}
      {notSeenYet && (
        <div className={cx(classes.badge, classes.notSeenYet)}>
          <FormattedMessage id="badges.notSeenYet" defaultMessage="Új" />
        </div>
      )}
      {updated && (
        <div className={cx(classes.badge, classes.nav)}>
          <FormattedMessage id="badges.updated" defaultMessage="Frissült" />
        </div>
      )}
      {(expireSoon || expired) && (
        <div className={cx(classes.badge, classes.expire)}>
          {expired ? (
            <FormattedMessage id="badges.expired" defaultMessage="Lejárt" />
          ) : (
            <FormattedMessage id="badges.expireSoon" defaultMessage="Lejár" />
          )}
        </div>
      )}
      {(isNavLink || isNavNoPreview) && (
        <LightTooltip
          title={
            isNavLink ? (
              <FormattedMessage
                id="badges.tooltips.navLink"
                defaultMessage="Ezt a számlát már a NAV rendszeréből is szinkronizáltuk"
              />
            ) : (
              <FormattedMessage
                id="badges.tooltips.navPreview"
                defaultMessage="Ehhez a NAV-tól származó számlához még nem tartozik számlakép"
              />
            )
          }
        >
          <div className={cx(classes.badge, classes.nav)}>
            {isNavLink ? (
              <>
                NAV&nbsp;
                <LinkIcon />
              </>
            ) : (
              // <InsertDriveFileOutlinedIcon fontSize="inherit" />
              <FormattedMessage id="badges.nav.noArtifact" defaultMessage="Nincs számlakép" />
            )}
          </div>
        </LightTooltip>
      )}
    </div>
  )
}

ValueWithBadges.propTypes = {
  children: node,
  differentWarning: node,
  badges: shape({
    expireSoon: bool,
    expired: bool,
    notSeenYet: bool,
    updated: bool,
    isDeficient: bool,
    isNavNoPreview: bool,
    isNavLink: bool,
  }).isRequired,
  maxWidth: number,
}
