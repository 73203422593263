import React from 'react'

import { useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { dashboardActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { useCreateTagHandler } from '@hooks'

import { ReactHookFormSelectField, ReactHookFormSelectFieldProps } from '@components/ui/FormElements'

import { QuickUserFeaturePermissions } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { formSelectMessages } from '@messages'

export interface ReactHookFormTagFieldProps extends ReactHookFormSelectFieldProps<Tag> {
  company: number
  createNewTag: CreateCommonIdAndNameTypeCallback
  isTagCreateDeniedForUser: boolean
  isTagsDeniedForUser: boolean
}

export function PureReactHookFormTagField({
  company,
  createNewTag,
  disabled,
  isTagCreateDeniedForUser,
  isTagsDeniedForUser,
  ...rest
}: ReactHookFormTagFieldProps) {
  const { formatMessage } = useIntl()
  const { isLoading, onCreateHandler } = useCreateTagHandler(company, createNewTag)

  return (
    <ReactHookFormSelectField
      {...rest}
      disabled={isTagsDeniedForUser || disabled}
      isClearable
      isMulti
      onEmptyCreateText={formatMessage(formSelectMessages.tagsEmptyCreateText)}
      // controlled creatable behavior
      {...(!isTagCreateDeniedForUser && { isLoading, onCreate: onCreateHandler })}
    />
  )
}

export const ReactHookFormTagField = connect(
  (state: Store) => ({
    company: state.auth.company.data.id,
    isTagsDeniedForUser: permissionDeniedForUser(state, QuickUserFeaturePermissions.denyTagPermission),
    isTagCreateDeniedForUser: permissionDeniedForUser(state, QuickUserFeaturePermissions.denyTagWritePermission),
    options: state.dashboard.tags.data,
  }),
  dispatch => ({
    createNewTag: bindActionToPromise(dispatch, dashboardActions.createTag.request),
  })
)(PureReactHookFormTagField)

ReactHookFormTagField.displayName = 'ReactHookFormTagField'
