import React from 'react'

import { TooltipProps } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { LightTooltip } from './LightTooltip'

const InteractiveTooltip = withStyles(theme => ({
  tooltip: {
    padding: 0,
    maxWidth: 'unset',
  },
  popper: {
    zIndex: ({ placedInModal }: { placedInModal?: boolean }) =>
      placedInModal ? theme.zIndex.modal + 1 : theme.zIndex.modal - 1,
  },
}))(LightTooltip)

const IndicatorWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;

  &:hover {
    cursor: help;
  }
`

export interface InteractiveLightTooltipProps
  extends Pick<TooltipProps, 'children' | 'className' | 'title' | 'onClose' | 'onOpen'> {
  placedInModal?: boolean
}

export function InteractiveLightTooltip({ className, children, ...rest }: InteractiveLightTooltipProps) {
  return (
    <InteractiveTooltip {...rest} interactive placement="bottom" enterDelay={200} leaveDelay={100}>
      <IndicatorWrapper className={className}>{children}</IndicatorWrapper>
    </InteractiveTooltip>
  )
}
