import React from 'react'

import styled from 'styled-components'

function BaseAttachDokumentIcon({ className, size = 32 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
    >
      <path
        d="M23.5107 6.58281C22.2036 5.24843 20.0821 5.24843 18.775 6.58281L8.91786 16.6453C6.66251 18.9477 6.66251 22.6773 8.91786 24.9797C11.1732 27.282 14.8268 27.282 17.0821 24.9797L25.225 16.6672C25.8089 16.0711 26.7625 16.0711 27.3464 16.6672C27.9304 17.2633 27.9304 18.2367 27.3464 18.8328L19.2036 27.1453C15.775 30.6453 10.225 30.6453 6.79643 27.1453C3.36786 23.6453 3.36786 17.9797 6.79643 14.4797L16.6536 4.41718C19.1339 1.88515 23.1518 1.88515 25.6321 4.41718C28.1125 6.94921 28.1125 11.0508 25.6321 13.5828L16.2036 23.2078C14.6714 24.7719 12.1857 24.7719 10.6536 23.2078C9.12144 21.6437 9.12144 19.1062 10.6536 17.5422L18.3679 9.66718C18.9518 9.07109 19.9054 9.07109 20.4893 9.66718C21.0732 10.2633 21.0732 11.2367 20.4893 11.8328L12.775 19.7078C12.4161 20.0742 12.4161 20.6758 12.775 21.0422C13.1339 21.4086 13.7232 21.4086 14.0821 21.0422L23.5107 11.4172C24.8179 10.0828 24.8179 7.91718 23.5107 6.58281Z"
        fill="currentColor"
      />
    </svg>
  )
}

export const AttachDokumentIcon = styled(BaseAttachDokumentIcon)``
