import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { IntercomLauncherButton } from '@components/Intercom'
import { LinkButton } from '@components/ui/Buttons'
import { Typography } from '@components/ui/Typography'

import {
  CardBodySection,
  CardFooter,
  CardHeader,
  CardHeaderDescriptionTypography,
  CardPaper,
  FeatureList,
  StyledPriceBox,
  StyledPriceBoxPaper,
} from './elements'

const CustomPlanTitleMessage = (
  <FormattedMessage id="subscription.plan.enterprise.title" defaultMessage="Minden Multibox funkció, plusz:" />
)

const CustomButtonTextMessage = <FormattedMessage id="subscription-card.button.custom" defaultMessage="Érdekel" />

interface CustomSubscriptionCardProps {
  children: React.ReactNode
  description?: StringOrMessage
  features: StringOrMessage[]
  name: string
  price: React.ReactNode
  title: StringOrMessage
}

function CustomSubscriptionCard({ children, description, features, name, price, title }: CustomSubscriptionCardProps) {
  return (
    <CardPaper as="article" elevation={3}>
      <CardHeader>
        <Typography align="center" size="heading-3" tag="h2">
          {name}
        </Typography>
        {description && (
          <CardHeaderDescriptionTypography align="center" size="700-sm">
            {description}
          </CardHeaderDescriptionTypography>
        )}
      </CardHeader>
      <CardBodySection>
        {price}
        <FeatureList featureKey="custom" features={features} title={title} />
      </CardBodySection>
      <CardFooter>{children}</CardFooter>
    </CardPaper>
  )
}

CustomSubscriptionCard.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.node,
  features: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]).isRequired)
    .isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]).isRequired,
}

function PureZeroHeroSubscriptionCard({ companyId }: { companyId: Company['id]'] }) {
  return (
    <CustomSubscriptionCard
      description={
        <FormattedMessage
          id="subscription.plan.custom.description"
          defaultMessage="Ingyenes QUiCK fiók az összes funkcióval, ha olyan könyvelővel szerződsz, akit mi ajánlunk."
        />
      }
      features={[
        <FormattedMessage
          id="subscription.plan.custom.feature1"
          defaultMessage="Garantáljuk, hogy találunk neked egy olyan könyvelőt, aki hatékonyan építi folyamataiba a QUiCK-et, ezért a jelenleginél kedvezőbb díjon nyújt számodra könyvelési szolgáltatást"
          key="custom-feature-1"
        />,
        <FormattedMessage
          id="subscription.plan.custom.feature2"
          defaultMessage="Sikeres szerződés esetén a QUiCK használata teljesen ingyenes marad a könyvelési szerződés végéig"
          key="custom-feature-2"
        />,
        <FormattedMessage
          id="subscription.plan.custom.feature3"
          defaultMessage="Ha nem sikerül megfelelő könyvelőt találnunk neked, akkor a 30 napos próbaidőszakon túl + 1 hónap ingyen Multibox előfizetést kapsz"
          key="custom-feature-3"
        />,
      ]}
      name="Zero the Hero"
      price={
        <StyledPriceBox
          currency="HUF"
          isCurrent={false}
          isSubscriptionActive={false}
          price={0}
          secondaryText={
            <FormattedMessage
              id="subscription.plan.custom.price.secondaryText"
              defaultMessage="QUiCK fiókod ingyenes"
            />
          }
          subscriptionPeriod="monthly"
        />
      }
      title={CustomPlanTitleMessage}
    >
      <LinkButton
        fullWidth
        href={`https://form.typeform.com/to/bVj11hEp#company_id=${companyId}`}
        size="large"
        target="_blank"
        variant="primaryContained"
        withoutIcon
      >
        {CustomButtonTextMessage}
      </LinkButton>
    </CustomSubscriptionCard>
  )
}

export const ZeroHeroSubscriptionCard = connect((state: Store) => ({ companyId: state.auth.company.data.id }))(
  PureZeroHeroSubscriptionCard
)

ZeroHeroSubscriptionCard.displayName = 'ZeroHeroSubscriptionCard'

export function EnterpiseSubscriptionCard() {
  return (
    <CustomSubscriptionCard
      description={
        <FormattedMessage
          id="subscription.plan.enterprise.description"
          defaultMessage="Korlátok nélküli pénzügy, szakértői támogatással kis- és középvállalatoknak."
        />
      }
      features={[
        <FormattedMessage
          id="subscription.plan.enterprise.feature1"
          defaultMessage="Korlátlan számú számla kezelése"
          key="enterprise-feature-1"
        />,
        <FormattedMessage
          id="subscription.plan.enterprise.feature2"
          defaultMessage="Dedikált kapcsolattartó"
          key="enterprise-feature-2"
        />,
        <FormattedMessage
          id="subscription.plan.enterprise.feature3"
          defaultMessage="Ingyenes bevezetés"
          key="enterprise-feature-3"
        />,
      ]}
      name="Enterprise"
      price={
        <StyledPriceBoxPaper>
          <Typography size="heading-5">
            <FormattedMessage id="subscription.plan.enterprise.price" defaultMessage="Lépj kapcsolatba velünk!" />
          </Typography>
        </StyledPriceBoxPaper>
      }
      title={CustomPlanTitleMessage}
    >
      <IntercomLauncherButton variant="primaryContained" size="large" fullWidth>
        {CustomButtonTextMessage}
      </IntercomLauncherButton>
    </CustomSubscriptionCard>
  )
}
