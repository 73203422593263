import React from 'react'

import { MultiValue } from 'react-select'

import { getSelectableOptions, orderSelectOptions } from '../helpers'
import { BaseSelectInput } from './BaseSelectInput'
import { DefaultSelectInputOption, SelectInputProps } from './types'

/**
 * SelectInput component is a customizable select input field that supports both single and multi-select options.
 * It can also handle creatable options, allowing users to add new options dynamically.
 *
 * @template Option - The type of the options used in the select input.
 *
 * @param {SelectInputProps<Option>} props - The properties for the SelectInput component.
 * @param {string} [props.className] - (Optional) Additional class name for the select input.
 * @param {boolean} [props.hasError=false] - (Optional) Indicates if the select input has an error. (Default: false)
 * @param {boolean} [props.isClearable=false] - (Optional) Indicates if the select input is clearable. (Default: false)
 * @param {boolean} [props.isDisabled=false] - (Optional) Indicates if the select input is disabled. (Default: false)
 * @param {boolean} [props.isLoading=false] - (Optional) Indicates if the select input is loading. (Default: false)
 * @param {boolean} [props.isMulti=false] - (Optional) Indicates if the select input supports multiple selections. (Default: false)
 * @param {OptionKeys} [props.labelKey='name'] - (Optional) The key used to display the label of the options. (Default: 'name')
 * @param {boolean} [props.menuShouldBlockScroll=false] - (Optional) Indicates if the menu should block scroll. (Default: false)
 * @param {string} props.name - The name of the select input.
 * @param {string} [props.noResultsText] - (Optional) Custom text to display when no results are found.
 * @param {VoidFunction} [props.onFocus] - (Optional) Callback function to handle focus event.
 * @param {VoidFunction} [props.onBlur] - (Optional) Callback function to handle blur event.
 * @param {(value: any) => void} props.onChange - Callback function to handle change event.
 * @param {(name: string, value: any) => void} [props.onChangeCallback] - (Optional) Additional callback function to handle change event.
 * @param {(payload: Option, selectRef: SelectInstance<Option, boolean, GroupBase<Option>>) => void} [props.onCreate] - (Optional) Callback function to handle creation of new options.
 * @param {string} [props.onEmptyCreateText] - (Optional) Custom text to display when creating an empty option.
 * @param {(props: CustomReactSelectOptionProps<Option>) => JSX.Element} [props.OptionComponent=CustomReactSelectOption] - (Optional) Custom component to render options. (Default: CustomReactSelectOption)
 * @param {Option[]} props.options - The list of options for the select input.
 * @param {string} [props.placeholder] - (Optional) Custom placeholder text for the select input.
 * @param {boolean} [props.skipSorting=false] - (Optional) Indicates if the options should skip sorting. (Default: false)
 * @param {any} props.value - The current value of the select input.
 * @param {OptionKeys} [props.valueKey='id'] - (Optional) The key used to identify the value of the options. (Default: 'id')
 *
 * @returns {JSX.Element} The rendered SelectInput component.
 */
export function SelectInput<Option extends DefaultSelectInputOption>({
  isMulti = false,
  labelKey = 'name',
  options,
  skipSorting = false,
  value,
  valueKey = 'id',
  ...rest
}: SelectInputProps<Option>) {
  const selectOptions = React.useMemo(() => {
    // incasesensitive order
    const orderedOptions = orderSelectOptions(options, labelKey, skipSorting)

    // TODO later need
    // if (recommendation) {
    //   const { options: recommendations, optionsLabel, recommendationsLabel } = recommendation

    //   if (recommendations.length > 0) {
    //     return [
    //       {
    //         label: recommendationsLabel,
    //         options: orderSelectOptions(
    //           recommendations,
    //           labelKey,
    //           skipSorting
    //         ),
    //       },
    //       {
    //         label: optionsLabel,
    //         options: orderedOptions,
    //       },
    //     ]
    //   }
    // }

    return orderedOptions
  }, [labelKey, options, skipSorting])

  const getValue = React.useCallback<(value: unknown) => Option[]>(
    value => {
      const selectableOptions = getSelectableOptions(options)
      if (isMulti) {
        return orderSelectOptions(
          selectableOptions.filter(option => (value as MultiValue<Option>).includes(option[valueKey])),
          labelKey,
          skipSorting
        )
      } else {
        return selectableOptions.filter(option => option[valueKey] === value)
      }
    },
    [isMulti, labelKey, options, skipSorting, valueKey]
  )

  return (
    <BaseSelectInput
      {...rest}
      isMulti={isMulti}
      labelKey={labelKey}
      selectOptions={selectOptions}
      selectValue={getValue(value)}
      useWindowedMenuList={options.length > 9}
      valueKey={valueKey}
    />
  )
}
