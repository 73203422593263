import React from 'react'
import PropTypes from 'prop-types'

import ChatBubbleIcon from '@material-ui/icons/ChatBubble'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { postitActions } from '@services'

import { bindActionToPromise } from '@helpers'

import { DebounceLoadConfig, useDebouncedLoadData } from '@hooks'

import { InteractiveLightTooltip } from '@oldComponents/ui/InteractiveLightTooltip'

import { StatusIconWrapper } from '../StatusIconWrapper'
import { AsyncPostitHistory, PostItHistoryData } from './AsyncPostitHistory'

const Count = styled.span`
  position: absolute;
  color: ${({ theme }) => theme.colors.gray[102]};
  font-size: 10px;
  font-weight: 700;
  line-height: 1.6;
  width: 100%;
  text-align: center;
`

const StyledStatusIconWrapper = styled(StatusIconWrapper)`
  position: relative;
`

const LOAD_CONFIG: DebounceLoadConfig = { loadBehavior: 'firstOpen' }

interface InvoicePostItStatusProps {
  count: number
  documentType: 'expense' | 'income'
  invoiceId: ItemIdType
  loadData: AsyncFunction<{ documentType: 'expense' | 'income'; documentId: ItemIdType }, PostItHistoryData[]>
}

function PureInvoicePostItStatus({ invoiceId, count, documentType, loadData }: InvoicePostItStatusProps) {
  const asyncLoadData = React.useCallback(
    async () => await loadData({ documentType, documentId: invoiceId }),
    [documentType, invoiceId, loadData]
  )

  const { fetched, loading, results, handleOpen, handleClose } = useDebouncedLoadData(asyncLoadData, LOAD_CONFIG)

  return (
    <InteractiveLightTooltip
      title={<AsyncPostitHistory data={results} fetched={fetched} loading={loading} />}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <StyledStatusIconWrapper>
        <Count>{count}</Count>
        <ChatBubbleIcon fontSize="inherit" color="inherit" />
      </StyledStatusIconWrapper>
    </InteractiveLightTooltip>
  )
}

PureInvoicePostItStatus.propTypes = {
  count: PropTypes.number.isRequired,
  documentType: PropTypes.oneOf(['expense', 'income']).isRequired as React.Validator<
    InvoicePostItStatusProps['documentType']
  >,
  invoiceId: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]).isRequired,
  loadData: PropTypes.func.isRequired,
}

export const AsyncInvoicePostItStatus = connect(null, dispatch => ({
  loadData: bindActionToPromise(dispatch, postitActions.fetchInvoicePostIts.request),
}))(PureInvoicePostItStatus)

AsyncInvoicePostItStatus.displayName = 'AsyncInvoicePostItStatus'
