import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

// these types copied from @styles/formStyles
export const filterAttachmentStyles: Styles<Theme, Record<never, never>> = theme => ({
  textFieldRoot: {
    padding: 0,
    margin: 0,
  },
  inputRoot: {
    height: 36,
    boxSizing: 'border-box',
    borderRadius: 4,
    backgroundColor: theme.palette.grayscale.grey02,
    border: `1px solid ${theme.palette.grayscale.grey20}`,
    fontSize: 14,
    padding: '6px 10px',
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),

    '&.Mui-focused': { borderColor: theme.palette.tertiary.outlineBlue },
    '&.Mui-disabled': { backgroundColor: theme.palette.grayscale.inputBackground },

    '& input::placeholder': {
      fontStyle: 'italic',
    },

    'label + &': {
      marginTop: 25, // label's lineHeight + gap
    },
  },
  label: {
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.grayscale.grey70,
    whiteSpace: 'nowrap',
    transform: 'none',
  },
  inputClearButton: {
    width: 16,
    height: 16,
    display: 'flex',
    fontSize: 16,
    border: 'none',
    background: 'unset',
    padding: 0,
    color: theme.palette.grayscale.grey70,

    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.grayscale.black,
    },
  },
  searchIcon: {
    fontSize: 16,
    color: '#777',
  },
})
// these types copied from @styles/formStyles
export const filterFormStyles: Styles<Theme, Record<never, never>> = theme => ({
  textFieldRoot: {
    padding: 0,
    margin: 0,
  },
  inputRoot: {
    height: 36,
    boxSizing: 'border-box',
    borderRadius: 4,
    color: theme.palette.grayscale.black,
    backgroundColor: theme.palette.grayscale.inputBackground,
    border: `1px solid ${theme.palette.grayscale.light}`,
    fontSize: 14,
    padding: '8px 10px 8px 20px',
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),

    '&.Mui-focused': { borderColor: theme.palette.tertiary.outlineBlue },
    '&.Mui-disabled': { backgroundColor: theme.palette.grayscale.disabledInputBackground },

    '& input::placeholder': {
      fontStyle: 'italic',
      color: theme.palette.grayscale.grey70,
    },

    '& input:placeholder-shown': {
      textOverflow: 'ellipsis',
    },

    'label + &': {
      marginTop: 25, // label's lineHeight + gap
    },
  },
  selectInputRoot: {
    minHeight: 36,
    boxSizing: 'border-box',
    borderRadius: 4,
    color: theme.palette.grayscale.black,
    backgroundColor: theme.palette.grayscale.inputBackground,
    border: `1px solid ${theme.palette.grayscale.light}`,
    fontSize: 14,
    padding: '0 10px 0 0',
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),

    '&.Mui-focused': { borderColor: theme.palette.tertiary.outlineBlue },
    '&.Mui-disabled': { backgroundColor: theme.palette.grayscale.disabledInputBackground },

    '& input::placeholder': {
      fontStyle: 'italic',
      color: theme.palette.grayscale.grey70,
    },

    'label + &': {
      marginTop: 25, // label's lineHeight + gap
    },
  },
  formControlLabel: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    color: theme.palette.grayscale.black,
  },
  label: {
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    color: theme.palette.grayscale.black,
    whiteSpace: 'nowrap',
    transform: 'none',
    textTransform: 'uppercase',
  },
  extendedLabel: {
    width: '100%',
  },
  searchIcon: {
    color: '#777',
    fontSize: 16,
  },
})
