import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { downloadFileWithURL, getActiveCompanyId, getErrorMessage, getRFFFormErrors } from '@helpers'

import { AttachmentUploadFormValues } from '@components/Attachments/types'

import actions from './actions'
import * as api from './api'

function* fetchAttachmentsSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ documentId: number; documentType: AttachmentDocumentType }>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackendAttachmentFileData[]> = yield call(api.fetchAttachments, companyId, payload)
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* createAttachmentSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<AttachmentUploadFormValues>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<BackendAttachmentFileData> = yield call(api.createAttachment, companyId, payload)
    if (payload.documentType !== 'partner') {
      yield put(actions.increaseAttachment.request(payload))
    }
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

function* removeAttachmentSaga({ payload, meta: { resolve, reject } }: AsyncSagaAction<AttachmentAsyncActionPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    yield call(api.removeAttachment, companyId, payload)
    if (payload.documentType !== 'partner') {
      yield put(actions.decreaseAttachment.request(payload))
    }
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* downloadAttachmentSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<AttachmentAsyncActionPayload>) {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ path: string }> = yield call(api.downloadAttachment, companyId, payload)
    yield call(downloadFileWithURL, response)
    yield call(resolve)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

//! export download
function* downloadExportSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<{ download: boolean; hash: string }>) {
  try {
    const response: AxiosResponse<{ user_email: string; path: string }> = yield call(api.downloadExport, payload)
    if (payload.download) {
      yield call(downloadFileWithURL, response)
    }
    yield call(resolve, response.data)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchAttachments.REQUEST, fetchAttachmentsSaga)
  yield takeLatest(actions.createAttachment.REQUEST, createAttachmentSaga)
  yield takeLatest(actions.removeAttachment.REQUEST, removeAttachmentSaga)
  yield takeLatest(actions.downloadAttachment.REQUEST, downloadAttachmentSaga)
  yield takeLatest(actions.downloadExport.REQUEST, downloadExportSaga)
}
