import React from 'react'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { isRequireApprovalForPaymentEnabled } from '@helpers'

import { InformationalMessage } from '@components/ui'

import { ALL_PAYMENT_TRANSACTION_TYPES } from '@constants'

interface PaymentMessageProps {
  isEVCompany: boolean
  isRequireApproval: boolean
  transactionType: AllPaymentTransactionType
}

function PurePaymentMessage({ isEVCompany, isRequireApproval, transactionType }: PaymentMessageProps) {
  if (transactionType === ALL_PAYMENT_TRANSACTION_TYPES.income) {
    if (isEVCompany) {
      return (
        <FormattedMessage
          id="paymentEditor.infoText.income.evCompany"
          defaultMessage="A kiválasztott bevételek közül egy vagy több pénzügyileg rendezett, vagy integrációból származik, ezért nem rögzíthető rá kifizetés."
        />
      )
    }
    return (
      <FormattedMessage
        id="paymentEditor.infoText.income"
        defaultMessage="A kiválasztott bevételek közül egy vagy több pénzügyileg rendezett, így a listából kihagyásra került."
      />
    )
  }

  if (isRequireApproval) {
    return (
      <FormattedMessage
        id="paymentEditor.infoText.requireApprovalForPayment"
        defaultMessage="A kiválasztott költségek közül egy vagy több nem tartalmaz fizetendő összeget, hiányosan van kitöltve, NAV-os összerendelésre vár, duplikáció, vagy nincs meg a szükséges jóváhagyás, így nem került bele az utalási csomagba!"
      />
    )
  }

  return (
    <FormattedMessage
      id="paymentEditor.infoText"
      defaultMessage="A kiválasztott költségek közül egy vagy több nem tartalmaz fizetendő összeget, hiányosan van kitöltve, NAV-os összerendelésre vár, vagy duplikáció így nem került bele az utalási csomagba!"
    />
  )
}

const PaymentMessage = connect((state: Store) => ({
  isEVCompany: state.auth.company.data.company_type === 'ev',
  isRequireApproval: isRequireApprovalForPaymentEnabled(state),
}))(PurePaymentMessage)

PaymentMessage.displayName = 'PaymentMessage'

export function PaymentInformationalMessage(props: Pick<PaymentMessageProps, 'transactionType'>) {
  return (
    <InformationalMessage iconSize={16} typographySize="700-xs">
      <PaymentMessage {...props} />
    </InformationalMessage>
  )
}
