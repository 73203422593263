import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, List } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { PreviewProvider } from '@contexts/index'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { Typography } from '@components/ui'

import { ActionSuccessHandlerFunction } from '../types'
import { Attachment } from './Attachment'
import { AttachmentConfirmDialog } from './AttachmentConfirmDialog'
import { ActionType, AttachmentConfirmDialogView, INITIAL_STATE, reducer } from './reducer'

import { DataLoadingMessage } from '@messages'

const style = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: 15,
}

interface AttachmentsListProps {
  data: BackendAttachmentFileData[]
  documentType: AttachmentDocumentType
  error: BackendError
  loading: boolean
  onActionSuccess: ActionSuccessHandlerFunction
  onDelete: AsyncFunction<BackendAttachmentFileData>
  onDetach: AsyncFunction<BackendAttachmentFileData>
  onDownload: AsyncFunction<BackendAttachmentFileData>
}

export function AttachmentsList({
  data,
  documentType,
  error,
  loading,
  onDelete,
  onActionSuccess,
  onDetach,
  onDownload,
}: AttachmentsListProps) {
  const [{ attachmentFile, actionLoading, actionError, view }, dispatch] = React.useReducer(reducer, INITIAL_STATE)
  const cPromiseRef = useCancellablePromiseRef()

  const handleConfirm = React.useCallback<AsyncFunction>(async () => {
    if (attachmentFile) {
      const callAction = view === AttachmentConfirmDialogView.DELETE ? onDelete : onDetach
      dispatch({ type: ActionType.REQUEST })
      try {
        cPromiseRef.current = cancellablePromise(callAction(attachmentFile))
        await cPromiseRef.current.promise
        onActionSuccess(attachmentFile)
        dispatch({ type: ActionType.SUCCESS })
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          dispatch({ type: ActionType.FAILED, payload: errorMessage })
        }
      }
    }
  }, [attachmentFile, view, onDelete, onDetach, cPromiseRef, onActionSuccess])

  React.useEffect(() => {
    if (actionError) {
      const timer = window.setTimeout(() => {
        dispatch({ type: ActionType.CLEAR_ERROR })
      }, 4000)

      return () => clearTimeout(timer)
    }
  }, [actionError])

  function handleClose() {
    dispatch({ type: ActionType.CLOSE_DIALOG })
  }

  function onDownloadHandler(fileData: BackendAttachmentFileData) {
    return function handler() {
      return onDownload(fileData)
    }
  }

  function onDeleteHandler(fileData: BackendAttachmentFileData) {
    return function handler() {
      dispatch({ type: ActionType.DELETE_ACTION_REQUEST, payload: fileData })
    }
  }

  function onDetachHandler(fileData: BackendAttachmentFileData) {
    return function handler() {
      dispatch({ type: ActionType.DETACH_ACTION_REQUEST, payload: fileData })
    }
  }

  if (loading) {
    return (
      <div style={style}>
        <div
          style={{
            display: 'inline-flex',
            alignItems: 'inherit',
            marginRight: 10,
          }}
        >
          <CircularProgress color="primary" size={20} />
        </div>
        <Typography size="400-sm">{DataLoadingMessage}</Typography>
      </div>
    )
  }
  if (error) {
    return (
      <div style={style}>
        <Typography color="error">{error}</Typography>
      </div>
    )
  }
  if (!data.length) {
    return (
      <div style={style}>
        {documentType === 'partner' ? (
          <FormattedMessage
            id="attachments.partner.noResult"
            defaultMessage="Még egyetlen csatolt fájl sem tartozik a partnerhez"
          />
        ) : (
          <FormattedMessage
            id="attachments.noResult"
            defaultMessage="Még egyetlen csatolt fájl sem tartozik a számlához"
          />
        )}
      </div>
    )
  }
  return (
    <React.Fragment>
      <PreviewProvider>
        <List data-testid="attachment-list">
          {data.map((fileData, index) => (
            <Attachment
              data={fileData}
              divider={index < data.length - 1}
              key={fileData.id}
              onDelete={onDeleteHandler(fileData)}
              onDetach={onDetachHandler(fileData)}
              onDownload={onDownloadHandler(fileData)}
            />
          ))}
        </List>
      </PreviewProvider>
      <AttachmentConfirmDialog
        attachmentFile={attachmentFile}
        error={actionError}
        loading={actionLoading}
        onClose={handleClose}
        onSubmit={handleConfirm}
        view={view}
      />
    </React.Fragment>
  )
}

AttachmentsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string,
    }).isRequired
  ).isRequired,
  documentType: PropTypes.oneOf(['expense', 'income', 'partner']).isRequired,
  error: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  onActionSuccess: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDetach: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
}
