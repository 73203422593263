import { SubscriptionPlanTiers } from '@constants/subscriptions'

export function serializePlans(
  plans: SubscriptionPlan[]
): Record<SubscriptionPeriod, Record<SubscriptionTier, SubscriptionPlan>> {
  return plans
    .sort(function (a, b) {
      const weight = {
        pro: 3,
        finance: 2,
        base: 1,
      }
      const wa = weight[a.tier]
      const wb = weight[b.tier]
      return wa > wb ? 1 : wa < wb ? -1 : 0
    })
    .reduce((values, plan) => {
      const { tier, period } = plan

      if (!values[period]) {
        values[period] = {} as Record<SubscriptionTier, SubscriptionPlan>
      }
      values[period][tier] = plan

      return values
    }, {} as Record<SubscriptionPeriod, Record<SubscriptionTier, SubscriptionPlan>>)
}

type ReducerActions = { type: 'request' } | { type: 'failure'; payload: string }

export function reducer(state: { loading: boolean; error: Nullable<string> }, action: ReducerActions) {
  switch (action.type) {
    case 'request':
      return {
        loading: true,
        error: null,
      }
    case 'failure':
      return {
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}

const TIER_RANKS: SubscriptionTier[] = [
  SubscriptionPlanTiers.BASE,
  SubscriptionPlanTiers.FINANCE,
  SubscriptionPlanTiers.PRO,
]
/**
 * Helper function to get subscription plan change direction (downgrade or upgrade)
 * @param {SubscriptionTier} currentTier - currently active subscription plan's tier
 * @param {SubscriptionTier} selectedPlanTier - newly selected subscription plan's tier
 * @returns - isDowgrade boolean flag
 */
export function isDowngradeFlow(currentTier: SubscriptionTier, selectedPlanTier: SubscriptionTier) {
  if (!currentTier || !selectedPlanTier) {
    return false
  }
  return TIER_RANKS.indexOf(currentTier) > TIER_RANKS.indexOf(selectedPlanTier)
}
