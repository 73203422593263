import { fork } from 'redux-saga/effects'

import apiKeysSaga from './apiKeys/sagas'
import attachmentsSaga from './attachment/sagas'
import authSaga from './auth/sagas'
import cegjelzoSaga from './cegjelzo/sagas'
import commonSaga from './common/sagas'
import controlsSaga from './controls/sagas'
import dashboardSaga from './dashboard/sagas'
import dashboardSagaV2 from './dashboard/sagas-v2'
import dokumentsSaga from './dokuments/sagas'
import expenseSaga from './expense/sagas'
import expenseSagaV2 from './expense/sagas-v2'
import filtersSaga from './filters/sagas'
import incomeSaga from './income/sagas'
import liquiditySaga from './liquidity/sagas'
import monthlyStatementSaga from './monthlyStatement/sagas'
import onboardingSaga from './onboarding/sagas'
import partnersSaga from './partners/sagas'
import paymentSaga from './payment/sagas'
import postItSaga from './postit/sagas'
import quarantineSaga from './quarantine/sagas'
import reportsSaga from './reports/sagas'
import salarySaga from './salary/sagas'
import subscriptionSaga from './subscription/sagas'
import taxSaga from './tax/sagas'

function* rootSaga() {
  yield fork(apiKeysSaga)
  yield fork(attachmentsSaga)
  yield fork(authSaga)
  yield fork(cegjelzoSaga)
  yield fork(commonSaga)
  yield fork(controlsSaga)
  yield fork(dashboardSaga)
  yield fork(dashboardSagaV2)
  yield fork(dokumentsSaga)
  yield fork(expenseSaga)
  yield fork(expenseSagaV2)
  yield fork(incomeSaga)
  yield fork(liquiditySaga)
  yield fork(onboardingSaga)
  yield fork(partnersSaga)
  yield fork(paymentSaga)
  yield fork(postItSaga)
  yield fork(quarantineSaga)
  yield fork(reportsSaga)
  yield fork(monthlyStatementSaga)
  yield fork(subscriptionSaga)
  yield fork(taxSaga)
  yield fork(salarySaga)
  yield fork(filtersSaga)
}

export default rootSaga
