import { AttachmentsContainerState } from './types'

export const initialState: AttachmentsContainerState = {
  data: [],
  error: null,
  fetched: false,
  loading: true,
}

export enum Actions {
  FETCH_SUCCESS = 'FETCH_SUCCESS',
  FETCH_FAILED = 'FETCH_FAILED',
  CREATE_SUCCESS = 'CREATE_SUCCESS',
  REMOVE_SUCCESS = 'REMOVE_SUCCESS',
}

type ReducerAction =
  | {
      type: Actions.FETCH_SUCCESS
      payload: BackendAttachmentFileData[]
    }
  | { type: Actions.FETCH_FAILED; payload: BackendError }
  | { type: Actions.CREATE_SUCCESS; payload: BackendAttachmentFileData }
  | { type: Actions.REMOVE_SUCCESS; payload: BackendAttachmentFileData }

export function reducer(state: AttachmentsContainerState, action: ReducerAction): AttachmentsContainerState {
  switch (action.type) {
    case Actions.FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        fetched: true,
        data: action.payload,
      }
    case Actions.FETCH_FAILED:
      return {
        ...state,
        loading: false,
        fetched: false,
        error: action.payload,
      }
    case Actions.CREATE_SUCCESS:
      return {
        ...state,
        data: [action.payload].concat(state.data),
      }
    case Actions.REMOVE_SUCCESS:
      return {
        ...state,
        data: state.data.filter(({ id }) => id !== action.payload.id),
      }
    default:
      return state
  }
}
