import React from 'react'

import { useIntercom } from 'react-use-intercom'

import { Button, ButtonProps } from '@components/ui/Buttons'

import { useIntercomProps } from './useIntercomProps'

function IntercomButton({ onClick, ...rest }: ButtonProps) {
  const { show } = useIntercom()

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    show()
    onClick?.(event)
  }

  return <Button {...rest} onClick={handleClick} />
}

export function IntercomLauncherButton({
  children,
  className,
  onClick,
  variant = 'primaryContained',
  ...rest
}: ButtonProps) {
  const Component = window.intercomAppId ? IntercomButton : Button
  const props = useIntercomProps(className, onClick)
  return (
    <Component variant={variant} {...rest} {...props}>
      {children}
    </Component>
  )
}
