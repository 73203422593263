import { Theme } from '@material-ui/core'
import { Styles } from '@material-ui/styles'

export const searchFilterStyles: Styles<Theme, Record<never, never>> = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexShrink: 0,
    height: '100%',
    padding: '15px 20px',
  },
  formControlRoot: {
    margin: 0,
    whiteSpace: 'nowrap',
  },
  formControlLabel: {
    fontSize: 14,
    color: theme.palette.grayscale.black,
    height: 30,
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    minWidth: 275,
  },
  checkbox: {
    marginRight: 10,

    '&.onlySelected + .MuiFormControlLabel-label.Mui-disabled': {
      color: theme.palette.grayscale.black,
    },
  },
  button: {
    padding: 5,
    marginRight: -5,
    minWidth: 0,
    color: theme.palette.tertiary.oceanBlue,
    height: 30,
    fontSize: 12,
    borderRadius: 4,

    '&:hover': {
      backgroundColor: `${theme.palette.tertiary.oceanBlue}10`,
    },
  },
  inputAdornment: {
    fontSize: 18,
  },
  inputLabel: {
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.grayscale.grey70,
    textTransform: 'uppercase',
  },
  input: {
    height: 30,
    boxSizing: 'border-box',
    borderRadius: 4,
    border: 'none',
    fontSize: 12,
    fontWeight: 700,
    color: theme.palette.grayscale.black,
    padding: 5,
    marginLeft: -5,
    marginBottom: -5,
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),

    '&.Mui-focused, &:hover': { backgroundColor: `${theme.palette.tertiary.oceanBlue}10` },
    '&.Mui-disabled': { backgroundColor: theme.palette.grayscale.disabledInputBackground },

    '& input::placeholder': {
      fontWeight: 400,
      color: theme.palette.grayscale.grey70,
      fontStyle: 'italic',
    },

    '& input:placeholder-shown': {
      textOverflow: 'ellipsis',
    },

    'label + &': {
      marginTop: 25, // label's lineHeight + gap
    },
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: -5,
    height: 30,
    flexShrink: 0,
  },
  list: {
    padding: '8px 0',
    listStyle: 'none',
    borderTop: `1px solid ${theme.palette.tertiary.paperStroke}`,
    '&.list': {
      padding: 0,
      border: 'none',
    },
  },
  listItem: {
    padding: '5px 20px',
    height: 30,
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.grayscale.black,
    boxSizing: 'border-box',

    '&:hover': {
      backgroundColor: `${theme.palette.tertiary.oceanBlue}10`,
    },
  },
})

export const filterSelectStyles: Styles<Theme, Record<never, never>> = theme => ({
  button: {
    height: '100%',
    display: 'flex',
    padding: '15px 20px',
    flexWrap: 'wrap',
    minWidth: 210,
    justifyContent: 'flex-start',
    color: theme.palette.grayscale.grey70,
    fontSize: 12,
    fontWeight: 700,
    lineHeight: 'inherit',
    flex: 1,

    '&:hover': {
      backgroundColor: `${theme.palette.tertiary.oceanBlue}10`,
    },
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
    marginTop: -5,
    textTransform: 'uppercase',
  },
  buttonValue: {
    textAlign: 'left',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    height: 30,
    lineHeight: '30px',
    width: '100%',
  },
  menuContainer: {
    padding: 20,
  },
  inputContainer: {
    padding: '20px 20px 15px',
    borderTop: `1px solid ${theme.palette.tertiary.paperStroke}`,
  },
  menuPaper: {
    width: 350,
  },
  list: {
    listStyle: 'none',
    maxHeight: 180,
    overflow: 'auto',
    marginBottom: 20,
    padding: 0,
    margin: 0,
  },
  listItem: {
    padding: '5px 20px',
    height: 30,
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 14,
    lineHeight: '20px',
    color: theme.palette.grayscale.black,
    boxSizing: 'border-box',

    '&:hover': {
      backgroundColor: `${theme.palette.tertiary.oceanBlue}10`,
    },
  },
  heading: {
    fontWeight: 700,
    color: theme.palette.grayscale.black,
    fontSize: 12,
    lineHeight: 1.4,
    textTransform: 'uppercase',
  },
  searchInput: {
    height: 36,
    boxSizing: 'border-box',
    borderRadius: 4,
    border: `1px solid ${theme.palette.grayscale.grey20}`,
    fontSize: 12,
    lineHeight: '16px',
    fontWeight: 700,
    color: theme.palette.grayscale.black,
    padding: 10,
    marginBottom: 20,
    transition: theme.transitions.create(['border-color']),
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    backgroundColor: theme.palette.grayscale.grey02,

    '&.Mui-focused, &:hover': { borderColor: theme.palette.tertiary.oceanBlue },
    '&.Mui-disabled': { backgroundColor: theme.palette.grayscale.disabledInputBackground },

    '& input::placeholder': {
      fontWeight: 400,
      color: theme.palette.grayscale.grey70,
      fontStyle: 'italic',
    },
    '& .MuiInputBase-input': {
      padding: '6px 0',
    },
  },
  selectedFiltersContainer: {
    display: 'flex',
    gap: 5,
    flexWrap: 'wrap',
    marginTop: 10,
  },
  inputClearButton: {
    width: 16,
    height: 16,
    display: 'flex',
    fontSize: 16,
    border: 'none',
    background: 'unset',
    padding: 0,
    color: theme.palette.grayscale.grey70,

    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.grayscale.black,
    },
  },
  searchIcon: {
    fontSize: 16,
    color: '#777',
  },
})
