export const SUBSCRIPTION_PERIODS = ['monthly', 'yearly'] as const
export const SUBSCRIPTION_PERIODS_OBJ = {
  monthly: SUBSCRIPTION_PERIODS[0],
  yearly: SUBSCRIPTION_PERIODS[1],
} as const

export const SUBSCRIPTION_PLANS = ['base', 'finance', 'pro']

export enum SubscriptionBlockedReasons {
  SZAMLAZZ = 'szamlazz',
  DEBT = 'debt',
}

export enum SubscriptionPlanTiers {
  BASE = 'base',
  FINANCE = 'finance',
  PRO = 'pro',
  // custom tiers
  CUSTOM = 'custom',
  ENTERPRISE = 'enterprise',
}
