import React from 'react'
import PropTypes from 'prop-types'

import __uniqueId from 'lodash/uniqueId'

import { Typography } from '@components/ui'
import { SelectInput } from '@components/ui/FormElements'

interface MultiSelectFilterProps {
  label: StringOrMessage
  onChange: (selectedValueKeys: Array<CommonIdAndNameType['id']>) => void
  options: CommonIdAndNameType[]
  value: Array<CommonIdAndNameType['id']>
}

export default function MultiSelectFilter({ label, onChange, options, value }: MultiSelectFilterProps) {
  return (
    <div>
      <Typography size="700-xs" color="gray-80">
        {label}
      </Typography>
      <SelectInput
        name={__uniqueId('ms-filter')}
        onChange={onChange}
        value={value}
        isMulti
        options={options}
        menuShouldBlockScroll
        isClearable
      />
    </div>
  )
}

MultiSelectFilter.propTypes = {
  label: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
}
