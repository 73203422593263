//! This is a copy and modified version of react-table > useRowSelect plugin
import React from 'react'

import { ensurePluginOrder, makePropGetter, useGetLatest, useMountedLayoutEffect } from 'react-table'

const defaultGetToggleRowSelectedProps = (props, { instance, row }) => {
  const { manualRowSelectedKey = 'isSelected' } = instance
  let checked = false

  if (row.original && row.original[manualRowSelectedKey]) {
    checked = true
  } else {
    checked = row.isSelected
  }

  return [
    props,
    {
      onChange: e => {
        row.onRowSelected(e.target.checked)
      },
      style: {
        cursor: 'pointer',
      },
      checked,
      title: 'Toggle Row Selected',
    },
  ]
}

function useInstance(instance) {
  const { data, rows, plugins, autoResetSelectedRows = true, selectedRowId, onSingleSelectChange } = instance

  ensurePluginOrder(
    plugins,
    ['useFilters', 'useGroupBy', 'useSortBy', 'useExpanded', 'usePagination'],
    'useControlledSingleRowSelect'
  )

  const getAutoResetSelectedRows = useGetLatest(autoResetSelectedRows)

  useMountedLayoutEffect(() => {
    if (getAutoResetSelectedRows()) {
      onSingleSelectChange({ selectedRowId: undefined })
    }
  }, [data])

  const selectedFlatRows = React.useMemo(() => {
    const selectedFlatRows = []

    rows.forEach(row => {
      const isSelected = row.id === selectedRowId
      row.isSelected = isSelected

      if (isSelected) {
        selectedFlatRows.push(row)
      }
    })

    return selectedFlatRows
  }, [rows, selectedRowId])

  const onRowSelected = React.useCallback(
    (id, value) => {
      const isSelected = id === selectedRowId
      const shouldExist = typeof value !== 'undefined' ? value : !isSelected

      if (isSelected === shouldExist) {
        return
      }

      if (shouldExist) {
        onSingleSelectChange({ selectedRowId: id })
      } else {
        onSingleSelectChange({ selectedRowId: undefined })
      }
    },
    [onSingleSelectChange, selectedRowId]
  )

  Object.assign(instance, {
    selectedFlatRows,
    onRowSelected,
  })
}

function prepareRow(row, { instance }) {
  row.onRowSelected = set => instance.onRowSelected(row.id, set)

  row.getToggleRowSelectedProps = makePropGetter(instance.getHooks().getToggleRowSelectedProps, {
    instance: instance,
    row,
  })
}

export default function useControlledSingleRowSelect(hooks) {
  hooks.getToggleRowSelectedProps = [defaultGetToggleRowSelectedProps]
  hooks.useInstance.push(useInstance)
  hooks.prepareRow.push(prepareRow)
}

useControlledSingleRowSelect.pluginName = 'useControlledSingleRowSelect'
