import React from 'react'

import { FormattedMessage } from 'react-intl'

import { useDebouncedCallUrl } from '@hooks'

import { InteractiveLightTooltip } from '@oldComponents/ui'

import { AsyncTwoColumnDataTable } from '../AsyncTwoColumnDataTable'
import { ApprovedIcon, getStatusComponents, PendingApprovalIcon } from './elements'

interface AsyncAdvancedApprovalStatusProps {
  approvalsUrl: string
  status: InvoiceApprovalStateValue
}

export function AsyncAdvancedApprovalStatus({ approvalsUrl, status }: AsyncAdvancedApprovalStatusProps) {
  const { fetched, loading, results, handleOpen, handleClose } =
    useDebouncedCallUrl<Array<{ email: string; approvedAt: Nullable<string> }>>(approvalsUrl)

  const [IconComponent, headingText] = React.useMemo(() => getStatusComponents({ status }), [status])

  const tableData = React.useMemo(
    () =>
      results?.map(({ email, approvedAt }) => ({
        label: email,
        value: approvedAt ? <ApprovedIcon size={16} /> : <PendingApprovalIcon size={16} />,
      })) ?? [],
    [results]
  )

  return (
    <InteractiveLightTooltip
      onClose={handleClose}
      onOpen={handleOpen}
      title={
        <AsyncTwoColumnDataTable
          data={tableData}
          emptyDataText={<FormattedMessage id="statuses.approval.noData" defaultMessage="Nincs megadva jóváhagyó." />}
          fetched={fetched}
          headingText={headingText}
          loading={loading}
        />
      }
    >
      <IconComponent size={16} />
    </InteractiveLightTooltip>
  )
}
