import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { fetchCompanyMembers } from '@services/auth/api'

import { getActiveCompanyId, getErrorMessage } from '@helpers'

import actions from './actions'

function* fetchApproverUsersSaga() {
  try {
    const companyId: Company['id'] = yield select(getActiveCompanyId)
    const response: AxiosResponse<{ users: CompanyUser[] }> = yield call(fetchCompanyMembers, companyId)
    yield put(actions.fetchApproverUsers.success(response.data.users))
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    // do nothing
    console.error('Fetch approver users failed:', errorMsg)
  }
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.fetchApproverUsers.REQUEST, fetchApproverUsersSaga)
}
