import React from 'react'

import { FormControl, FormHelperText, InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { useController, useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import AsyncCreatableSelect from 'react-select/async-creatable'

import { partnersActions } from '@services'

import { bindActionToPromise, cancellablePromise, customFilterOption, getDecimal, isFieldHighlighted } from '@helpers'
import { serializeAssignment } from '@oldComponents/forms/DetailsForm/helpers'

import { useCancellablePromiseRef, usePromptTextCreator } from '@hooks'

import { useIsValidNewOption } from '@components/ui/FormElements/useIsValidNewOption'

import { EMPTY_EXPENSE_ASSIGNMENT, INVOICE_AMOUNT_INPUT_DECIMAL_PLACES, InvoiceType } from '@constants'

import { COMPONENT_OVERRIDES } from './componentOverrides'
import { generateOption } from './helpers'
import { PartnerOptionData, PartnerSearchFieldProps } from './types'
import { useRecommendation } from './useRecommendation'

import { formStyles, selectFormStyles } from '@styles'
import { formSelectMessages } from '@messages'

const useStyles = makeStyles(formStyles)

const START_TO_SEARCH_CHAR_LIMIT = 2

function getOptionLabel(option: PartnerOptionData) {
  return option.name
}

function getOptionValue(option: PartnerOptionData) {
  return String(option.id)
}

function getNewOptionData(inputValue: string, optionLabel: React.ReactNode) {
  return {
    id: inputValue,
    name: optionLabel,
  } as PartnerOptionData
}

function getValue(
  value: Nullable<PartnerOptionData['id']>,
  options: PartnerOptionData[]
): PartnerOptionData | undefined {
  if (!value) {
    return
  }
  return options.find(({ id }) => id === value)
}

const FIELD_NAME = 'partner_id'

function PurePartnerSearchField({
  company,
  invoiceId,
  updateFormWithRecommendation,
  calculationBase = 'gross_amount',
  className,
  defaultValue,
  disabled,
  emptyAssignment = EMPTY_EXPENSE_ASSIGNMENT,
  highlighted = false,
  invoiceType,
  isAdvancedAccounting = false,
  isLabelHighlighted = false,
  label,
  onBlur: onBlurProp,
  onChange: onChangeProp,
  onCreate,
  recommendationConfig,
  required = false,
  searchPartners,
  setCalculationBase,
}: PartnerSearchFieldProps) {
  const { formatMessage } = useIntl()
  const cPromiseRef = useCancellablePromiseRef<FormRecommendationsData>()
  const {
    control,
    formState: { isSubmitting },
    setValue,
  } = useFormContext<IncomeDetailsFormInitialValues | ExpenseDetailsFormInitialValues>()
  const {
    field: { onBlur, onChange, value, ...fieldProps },
    fieldState: { error },
  } = useController({ name: FIELD_NAME, control })
  const [options, setOptions] = React.useState<PartnerOptionData[]>([])

  const classes = useStyles()
  const promptTextCreator = usePromptTextCreator()
  const isValidNewOption = useIsValidNewOption('name')
  const { recommendations: recommendedOptionGroup, clear: clearRecommendation } =
    useRecommendation(recommendationConfig)

  //* Options initialization with default value
  React.useEffect(() => {
    if (defaultValue?.id) {
      setOptions([generateOption(defaultValue)])
    }
  }, [defaultValue])

  // update calculation_base in options for selected value when calculationBase changes
  React.useEffect(() => {
    setOptions(prevOptions =>
      prevOptions.map(option => {
        if (option.id === value && option.calculation_base !== calculationBase) {
          return { ...option, calculation_base: calculationBase }
        }
        return option
      })
    )
  }, [calculationBase, setOptions, value])

  // update form with partner's recommendations
  const updateForm = React.useCallback(
    async (partner: number) => {
      if (!updateFormWithRecommendation || !partner) {
        return
      }

      try {
        cPromiseRef.current = cancellablePromise(updateFormWithRecommendation({ partner, company, id: invoiceId }))
        const { cash_account, tags, vat_area, payment_method, assignments } = await cPromiseRef.current.promise

        if (vat_area) {
          setValue('vat_area', vat_area)
        }
        if (payment_method) {
          setValue('payment_method', payment_method)
        }
        if (tags) {
          setValue('tags', tags)
        }

        if (invoiceType === InvoiceType.EXPENSE && assignments) {
          // TODO for income invoice assignments does not work with expense_type (BE response)
          const newValues = assignments.map(({ vat_amount, gross_amount, net_amount, ...rest }) =>
            serializeAssignment(
              {
                ...emptyAssignment,
                ...rest,
                vat_amount:
                  vat_amount != null
                    ? getDecimal(vat_amount, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: INVOICE_AMOUNT_INPUT_DECIMAL_PLACES,
                      })
                    : vat_amount,
                gross_amount:
                  gross_amount != null
                    ? getDecimal(gross_amount, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: INVOICE_AMOUNT_INPUT_DECIMAL_PLACES,
                      })
                    : gross_amount,
                net_amount:
                  net_amount != null
                    ? getDecimal(net_amount, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: INVOICE_AMOUNT_INPUT_DECIMAL_PLACES,
                      })
                    : net_amount,
              },
              isAdvancedAccounting
            )
          )
          setValue('assignments', newValues)
        }
        if (isAdvancedAccounting && cash_account != null) {
          setValue('cash_account', cash_account)
        }
      } catch (error) {
        // silently catch error
      }
    },
    [
      cPromiseRef,
      company,
      emptyAssignment,
      invoiceId,
      invoiceType,
      isAdvancedAccounting,
      setValue,
      updateFormWithRecommendation,
    ]
  )

  const handleChange = React.useCallback(
    option => {
      const newValue = option.id
      if (newValue !== value) {
        onChange(newValue)
        onChangeProp?.(newValue)

        // form's calculation base control
        setCalculationBase?.(option.calculation_base ?? 'gross_amount')

        // fill partner fields in form
        setValue('partner_name', option.name || '', { shouldValidate: true })
        setValue('partner_account_number', option.account_number || '')
        setValue('partner_tax_number', option.tax_number || '') // TODO formatted value, should we keep or parse it?
        setValue('partner_city', option.original_address.city || '')
        setValue('partner_country', option.original_address.country || '')
        setValue('partner_zip_code', option.original_address.zip_code || '')
        setValue('partner_address', option.original_address.address || '')
        // setValue('partner_is_kata_subject', option.is_kata_subject || '')

        // updateFormWithRecommendation
        updateForm(newValue)
      }
    },
    [value, onChange, onChangeProp, setCalculationBase, setValue, updateForm]
  )

  const onCreateSuccess = React.useCallback(
    partner => {
      const newPartnerOption = generateOption(partner)
      clearRecommendation(partner.name)
      setOptions([...options, newPartnerOption])
      handleChange(newPartnerOption)
    },
    [handleChange, options, setOptions, clearRecommendation]
  )

  const onCreateOptionHandler = React.useCallback(
    name => {
      onCreate({ name }, onCreateSuccess)
    },
    [onCreate, onCreateSuccess]
  )

  const onChangeHandler = React.useCallback(
    (option, { action }) => {
      if (action === 'select-option') {
        // check "prefix" option:
        // truthy: new recommendation call onCreate
        // falsy - existing recommendation call onChange
        // simple options has no prefix
        if (option?.prefix) {
          // [true, false, null] === false
          onCreateOptionHandler(option.name)
        } else {
          // (creatable) select without or existing recommendations
          handleChange(option)
        }
      }
    },
    [onCreateOptionHandler, handleChange]
  )

  //* OPTIONS
  const defaultOptions = React.useMemo(() => {
    const results = []

    if (
      recommendedOptionGroup &&
      Array.isArray(recommendedOptionGroup.options) &&
      recommendedOptionGroup.options.length
    ) {
      results.push(recommendedOptionGroup)
    }

    if (options.length) {
      results.unshift({
        label: recommendationConfig.texts.optionsLabel,
        options,
      })
    }

    // this is not a real option, just a message, so it's not selectable (custom component will handle this)
    results.push({
      name: formatMessage(formSelectMessages.asyncNoResultsText),
      isMsg: true,
    } as PartnerOptionData)

    return results
  }, [recommendationConfig, recommendedOptionGroup, options, formatMessage])

  const loadOptions = React.useCallback(
    async (inputValue: string) => {
      const {
        texts: { optionsLabel },
      } = recommendationConfig

      if (inputValue.length >= START_TO_SEARCH_CHAR_LIMIT) {
        try {
          const partners = await searchPartners({ name: inputValue })
          const mappedPartners = partners.map(generateOption)

          if (recommendedOptionGroup && recommendedOptionGroup.options.length) {
            return [
              {
                label: optionsLabel,
                options: mappedPartners,
              },
              recommendedOptionGroup,
            ]
          }

          return [
            {
              label: optionsLabel,
              options: mappedPartners,
            },
          ]
        } catch (error) {
          console.error('Error while loading partners', error)
        }
      }

      if (recommendedOptionGroup && recommendedOptionGroup.options.length) {
        return [
          {
            label: optionsLabel,
            options: options,
          },
          recommendedOptionGroup,
        ]
      }
      return options
    },
    [options, recommendationConfig, recommendedOptionGroup, searchPartners]
  )

  const noOptionsMessage = React.useCallback(
    () => formatMessage(formSelectMessages.asyncNoResultsText),
    [formatMessage]
  )

  const loadingMessage = React.useCallback(() => formatMessage(formSelectMessages.selectLoadingText), [formatMessage])

  const isFieldDisabled = disabled || isSubmitting
  const hasError = Boolean(error)

  return (
    <FormControl
      fullWidth
      margin="normal"
      className={cx(className, classes.selectRoot, 'form-control', {
        'form-control-error': hasError,
      })}
      error={hasError}
      disabled={isFieldDisabled}
      required={required}
    >
      {label && (
        <InputLabel
          htmlFor={FIELD_NAME}
          shrink
          className={cx(classes.bootstrapFormLabel, { [classes.highlightedLabel]: isLabelHighlighted })}
        >
          {label}
        </InputLabel>
      )}
      <div className={classes.selectInput}>
        <AsyncCreatableSelect
          {...fieldProps}
          className={cx({ error: hasError, highlighted: isFieldHighlighted(highlighted, value) })}
          classNamePrefix="react-select"
          defaultOptions={defaultOptions} // initially loaded
          filterOption={customFilterOption}
          formatCreateLabel={promptTextCreator}
          getNewOptionData={getNewOptionData}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          inputId={FIELD_NAME}
          instanceId={`ars-${FIELD_NAME}`}
          isDisabled={isFieldDisabled}
          isValidNewOption={isValidNewOption}
          loadingMessage={loadingMessage}
          loadOptions={loadOptions}
          noOptionsMessage={noOptionsMessage}
          onBlur={onBlurProp}
          onChange={onChangeHandler}
          onCreateOption={onCreateOptionHandler}
          placeholder={formatMessage(formSelectMessages.creatableSelectPlaceholder)}
          value={getValue(value, options)}
          styles={selectFormStyles}
          components={COMPONENT_OVERRIDES}
        />
      </div>
      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  )
}

export const PartnerSearchField = connect(
  (state: Store) => ({
    company: state.auth.company.data.id,
  }),
  dispatch => ({
    searchPartners: bindActionToPromise(dispatch, partnersActions.searchPartners.request),
  })
)(PurePartnerSearchField)

PartnerSearchField.displayName = 'PartnerSearchField'
