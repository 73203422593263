import React from 'react'
import PropTypes from 'prop-types'

import { components as reactSelectComponents, GroupBase, OptionProps } from 'react-select'

export type DefaultSelectInputOption = Record<string, any> & { isExist?: boolean }

type CustomSelectInputOption = DefaultSelectInputOption & { needToCreate?: boolean }

export interface CustomReactSelectOptionProps<Option extends CustomSelectInputOption>
  extends OptionProps<Option, boolean, GroupBase<Option>> {
  texts?: { newOptionPrefix: string; [x: string]: string }
}

// need to customize the recommended option
const ReactSelectOption = reactSelectComponents.Option

// react-select custom options
export function CustomReactSelectOption<Option extends CustomSelectInputOption>({
  children,
  texts,
  ...rest
}: CustomReactSelectOptionProps<Option>) {
  // const {
  //   data: { needToCreate },
  // } = rest
  return (
    <ReactSelectOption {...rest}>
      <div className="custom-option">
        <span {...(typeof children === 'string' ? { title: children } : {})}>{children}</span>
      </div>
    </ReactSelectOption>
  )
}

CustomReactSelectOption.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({ needToCreate: PropTypes.bool }).isRequired,
  texts: PropTypes.object,
}
