import styled from 'styled-components'

import { CircleCheckSolidIcon, HourGlassStartIcon, NotEnterSolidIcon } from '@components/ui/svgIcons'

export const ApprovedIcon = styled(CircleCheckSolidIcon)`
  color: ${({ theme }) => theme.colors.common.warningMessageBackground};
`

export const PendingApprovalIcon = styled(HourGlassStartIcon)`
  color: ${({ theme }) => theme.colors.common.inactive};
`

export const NotApprovedIcon = styled(NotEnterSolidIcon)`
  color: ${({ theme }) => theme.colors.common.inactive};
`
