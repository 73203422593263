import React from 'react'

import styled from 'styled-components'

import { FlexRow } from '@components/ui/Wrappers'

import { getStatusComponents } from './helpers'

const StyledFlexRow = styled(FlexRow)`
  gap: 8px;
  align-items: center;
`

interface AdvancedApprovingStatusProps {
  isApproved: boolean
  status: ExpenseApprovalStatus | undefined
}

export function AdvancedApprovingStatus(props: AdvancedApprovingStatusProps) {
  const [IconComponent, label] = getStatusComponents(props)

  return (
    <StyledFlexRow as="span">
      <IconComponent size={20} />
      <strong>{label}</strong>
    </StyledFlexRow>
  )
}
