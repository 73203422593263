import { AxiosResponse } from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { parseFiltersFromUrlWorkerRunner, SyncFiltersPayload } from '@webWorkers'
import { CommonAxiosPayload, InitPageLoadSagaPayload } from '@services'
import { callUrl } from '@services/common/api'

import {
  getActiveCompanyId,
  getErrorMessage,
  getMonthlyStatementFiltersFromStore,
  getRFFFormErrors,
  getUrlFilterOptionsFromStore,
} from '@helpers'

import { BackgroundProcessActions } from '@constants'

import dashboardActions from '../dashboard/actions'
import { fetchTags as fetchTagsApi } from '../dashboard/api'
import filtersActions from '../filters/actions'
import actions from './actions'
import * as api from './api'
import { ExportBackgroundAction } from './backgroundActions'

function* initMonthlyStatementPageLoadSaga({
  payload: { config, filtersStateKey, location, navigate },
  meta: { resolve, reject },
}: AsyncSagaAction<InitPageLoadSagaPayload>) {
  try {
    //* >> sync from url to store
    const companyId: number = yield select(getActiveCompanyId)
    const tagsResponse: AxiosResponse<Tag[]> = yield call(fetchTagsApi, companyId)
    yield put(dashboardActions.fetchTags.success(tagsResponse.data))

    const filterOptions: ReturnType<typeof getUrlFilterOptionsFromStore> = yield select(
      getUrlFilterOptionsFromStore,
      filtersStateKey
    )

    //* call worker
    const { filters, validationLevel }: SyncFiltersPayload = yield call(parseFiltersFromUrlWorkerRunner, {
      config,
      filterOptions,
      location,
    })
    yield put(filtersActions.initMonthlyStatementFiltersFromUrl.request({ filters }))
    //* << sync from store to url
    const storedFilters: SalaryListStoreFilters = yield select(getMonthlyStatementFiltersFromStore)
    yield put(filtersActions.syncFiltersToUrl.request({ navigate, filters: storedFilters }))
    yield call(resolve, validationLevel)
    yield put(actions.fetchMonthlyStatementAnalytics.request())
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
    yield put(actions.fetchMonthlyStatementAnalytics.failure(errorMsg))
  }
}

function* fetchMonthlyStatementAnalyticsSaga() {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: MonthlyStatementStoreFilters = yield select(getMonthlyStatementFiltersFromStore)
    const response: AxiosResponse<BackendMonthlyStatementResult> = yield call(
      api.fetchMonthlyStatementAnalytics,
      companyId,
      filters
    )
    yield put(actions.fetchMonthlyStatementAnalytics.success(response.data))
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield put(actions.fetchMonthlyStatementAnalytics.failure(errorMsg))
  }
}

function* updateExpenseOrRevenueTypeSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CommonAxiosPayload<unknown>>) {
  try {
    const response: AxiosResponse<unknown> = yield call(callUrl, payload)
    yield put(actions.updateExpenseOrRevenueType.success())
    yield call(resolve, response.data)
  } catch (error) {
    const formErrors = getRFFFormErrors(error)
    yield call(reject, formErrors)
  }
}

export function* removeExpenseOrRevenueTypeSaga({
  payload,
  meta: { resolve, reject },
}: AsyncSagaAction<CommonAxiosPayload<unknown>>) {
  try {
    yield call(callUrl, payload)
    yield put(actions.removeExpenseOrRevenueType.success())
    yield call(resolve)
  } catch (error) {
    // @ts-expect-error not yet TS function
    const errorMsg = getErrorMessage(error)
    yield call(reject, errorMsg)
  }
}

function* startExportSaga({ meta: { resolve, reject } }: AsyncSagaAction) {
  try {
    const companyId: number = yield select(getActiveCompanyId)
    const filters: MonthlyStatementStoreFilters = yield select(getMonthlyStatementFiltersFromStore)
    const response: AxiosResponse<BackgroundActionResponse> = yield call(ExportBackgroundAction.start, companyId, {
      action: BackgroundProcessActions.EXPORT_MONTHLY_STATEMENT,
      ...filters,
    })
    yield call(resolve, response)
  } catch (error) {
    const errorMsg = getErrorMessage(error as any)
    yield call(reject, errorMsg)
  }
}

function* stopExportSaga() {
  yield call(ExportBackgroundAction.stop)
}

// watcher Saga
export default function* commonSaga() {
  yield takeLatest(actions.initMonthlyStatementPageLoad.REQUEST, initMonthlyStatementPageLoadSaga)
  yield takeLatest(
    [
      actions.fetchMonthlyStatementAnalytics.REQUEST,
      filtersActions.resetMonthlyStatementFilters.REQUEST,
      filtersActions.updateMonthlyStatementFilters.REQUEST,
      actions.updateExpenseOrRevenueType.SUCCESS,
      actions.removeExpenseOrRevenueType.SUCCESS,
    ],
    fetchMonthlyStatementAnalyticsSaga
  )
  //* edit and delete actions on expense or revenue types
  yield takeLatest(actions.updateExpenseOrRevenueType.REQUEST, updateExpenseOrRevenueTypeSaga)
  yield takeLatest(actions.removeExpenseOrRevenueType.REQUEST, removeExpenseOrRevenueTypeSaga)
  // yield takeLatest(actions.loadMonthlyStatementDetails.REQUEST, loadMonthlyStatementDetailsSaga)
  //* export
  yield takeLatest(actions.startExport.REQUEST, startExportSaga)
  yield takeLatest(actions.stopExport.REQUEST, stopExportSaga)
}
