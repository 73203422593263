import React from 'react'
import PropTypes from 'prop-types'

import __uniqueId from 'lodash/uniqueId'
import { defineMessages, useIntl } from 'react-intl'
import { connect } from 'react-redux'

import { Typography } from '@components/ui'
import { SelectInput } from '@components/ui/FormElements'

import { CurrencyLabelMessage } from './messages'
import { FilterWrapper } from './styles'

const messages = defineMessages({
  placeholder: {
    id: 'dialogs.filters.currencyPlaceholder',
    defaultMessage: 'Minden devizanem',
  },
})

interface CurrencySelectFilterProps {
  onChange: (value: CurrencyFilterProps) => void
  options: CommonIdAndNameType[]
  value?: CurrencyFilterProps
}

function PureCurrencySelectFilter({ value, onChange, options }: CurrencySelectFilterProps) {
  const { formatMessage } = useIntl()

  function handleChange(valueKey: CommonIdAndNameType['id']) {
    // need to store CommonIdAndNameType object for display in ActiveFilters
    onChange({ currencyId: options.find(({ id }) => id === valueKey) })
  }

  return (
    <FilterWrapper>
      <Typography size="700-xs" color="gray-80">
        {CurrencyLabelMessage}
      </Typography>
      <SelectInput
        name={__uniqueId('currencyId')}
        onChange={handleChange}
        value={value?.currencyId?.id}
        options={options}
        placeholder={formatMessage(messages.placeholder)}
        menuShouldBlockScroll
      />
    </FilterWrapper>
  )
}

PureCurrencySelectFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  value: PropTypes.shape({
    currencyId: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }) as React.Validator<CurrencyFilterProps | undefined>,
}

const CurrencySelectFilter = connect(
  ({
    dashboard: {
      common: { currencies },
    },
  }: Store) => ({
    options: currencies,
  })
)(PureCurrencySelectFilter)

CurrencySelectFilter.displayName = 'CurrencySelectFilter'

export default CurrencySelectFilter
