import React from 'react'

import styled from 'styled-components'

import { QUICK_COLORS } from '@constants'

function BasePartiallyApprovedIcon({ className, size = 20 }: IconComponentProps) {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
        fill={QUICK_COLORS.common.inactive}
      />
      <path
        d="M0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C4.8043 18.9464 7.34784 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10"
        fill={QUICK_COLORS.common.warningMessageBackground}
      />
    </svg>
  )
}

export const PartiallyApprovedIcon = styled(BasePartiallyApprovedIcon)``
