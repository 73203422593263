import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { isVisibleForAccountantOrAdmin } from '@helpers'
import { permissionDeniedForUser } from '@permissions/helpers'

import { QuickUserFeaturePermissions, SUBSCRIPTION_PLANS } from '@constants'

interface FeatureDeniedForUserOwnProps {
  permission: QuickUserFeaturePermissions
}
interface FeatureDeniedForUserProps extends FeatureDeniedForUserOwnProps {
  children: React.ReactNode
  isFeatureDeniedForUser: boolean
}

function BaseFeatureDeniedForUser({ children, isFeatureDeniedForUser }: FeatureDeniedForUserProps) {
  if (isFeatureDeniedForUser) {
    return null
  }

  return <>{children}</>
}

BaseFeatureDeniedForUser.propTypes = {
  children: PropTypes.node.isRequired,
  isFeatureDeniedForUser: PropTypes.bool.isRequired,
  permission: PropTypes.oneOf(Object.values(QuickUserFeaturePermissions)).isRequired,
}

export const FeatureDeniedForUser = connect((state: Store, { permission }: FeatureDeniedForUserOwnProps) => ({
  isFeatureDeniedForUser: permissionDeniedForUser(state, permission),
}))(BaseFeatureDeniedForUser)

FeatureDeniedForUser.displayName = 'FeatureDeniedForUser'

interface BaseAccountingButtonVisibilityControlProps {
  children: React.ReactNode
  company: Company
}

/**
 * A könyvelő role-nak mindig ott a könyvelés gomb - csomagtól függetlenül
 * Az admin alapból nem látja a könyvelés gombot, de ha akarja, bekapcsolhatja magának - csomagtól függetlenül
 */
function BaseAccountingButtonVisibilityControl({ children, company }: BaseAccountingButtonVisibilityControlProps) {
  if (isVisibleForAccountantOrAdmin(company)) {
    return <>{children}</>
  }

  return null
}

BaseAccountingButtonVisibilityControl.propTypes = {
  children: PropTypes.node.isRequired,
  company: PropTypes.shape({
    role: PropTypes.string.isRequired,
    subscription: PropTypes.shape({
      plan: PropTypes.shape({
        tier: PropTypes.oneOf(SUBSCRIPTION_PLANS).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired as React.Validator<BaseAccountingButtonVisibilityControlProps['company']>,
}

export const AccountingButtonVisibilityControl = connect((state: Store) => ({
  company: state.auth.company.data,
}))(BaseAccountingButtonVisibilityControl)

AccountingButtonVisibilityControl.displayName = 'AccountingButtonVisibilityControl'
