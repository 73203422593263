export function serializeAssignment(
  {
    company_vat_category_code,
    company_vat_category_id,
    company_vat_category_name,
    expense_type,
    gross_amount,
    id,
    job_number,
    ledger_number_code,
    ledger_number_id,
    ledger_number_name,
    net_amount,
    note,
    tags,
    vat_amount,
    vat_ledger_number_code,
    vat_ledger_number_id,
    vat_ledger_number_name,
    vat,
  }: ExpenseDetailsAssignment,
  isAdvancedAccounting: boolean
) {
  return {
    expense_type,
    gross_amount: gross_amount == null ? '' : gross_amount,
    id,
    net_amount: net_amount == null ? '' : net_amount,
    tags,
    vat_amount: vat_amount == null ? '' : vat_amount,
    vat,
    ...(isAdvancedAccounting
      ? {
          company_vat_category_code,
          company_vat_category_id,
          company_vat_category_name,
          job_number: job_number == null ? '' : job_number,
          ledger_number_code,
          ledger_number_id,
          ledger_number_name,
          note: note == null ? '' : note,
          vat_ledger_number_code,
          vat_ledger_number_id,
          vat_ledger_number_name,
        }
      : {}),
  }
}
