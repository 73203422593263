import React from 'react'
import PropTypes from 'prop-types'

import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined'
import PublicIcon from '@material-ui/icons/Public'
import { defineMessages, FormattedDate, FormattedMessage, FormattedTime, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import {
  cancellablePromise,
  conditionalRequiredPropType,
  getRouteUrl,
  parseApiErrorMessage,
  userHasEditorPermission,
} from '@helpers'

import { PreviewVariant } from '@contexts'

import { useCancellablePromiseRef } from '@hooks/useCancellablePromiseRef'

import { ArtifactPreviewOpener } from '@components/DocViewer'
import { OverflowText, TrashRegularIcon, Typography } from '@components/ui'
import { CircleArrowRegularIcon, DetachDokumentIcon } from '@components/ui/svgIcons'
import { LightTooltip } from '@oldComponents/ui'
import { FeatureDeniedForUser } from '@oldComponents/VisibilityControls'

import { QuickUserFeaturePermissions, RouteKeys } from '@constants'

import { PagePermissions, PlanPermission } from '@permissions'

import { GoToLinkMessage } from '@messages'
import {
  ErrorTypography,
  IconWrapper,
  ListItem,
  ListItemActionsContainer,
  ListItemBody,
  ListItemContent,
  ListItemText,
  StyledFileDownloadIcon,
  StyledIconButton,
  StyledLink,
  StyledLinkButton,
} from './styles'

const messages = defineMessages({
  attachmentTitlePlaceholder: {
    id: 'attachments.title.placeholder',
    defaultMessage: 'Csatolmány',
  },
  deleteAttachment: {
    id: 'attachments.buttons.title.delete',
    defaultMessage: 'Dokumentum törlése',
  },
  detachAttachment: {
    id: 'attachments.buttons.title.detach',
    defaultMessage: 'Dokumentum leválasztása',
  },
  downloadAttachment: {
    id: 'attachments.buttons.title.download',
    defaultMessage: 'Dokumentum letöltése',
  },
})

interface AttachmentProps {
  companyId: number
  data: BackendAttachmentFileData
  divider: boolean
  onDelete: VoidFunction
  onDetach: VoidFunction
  onDownload: AsyncFunction
  isEditorUser: boolean
}

function PureAttachment({ companyId, data, divider, onDelete, onDetach, onDownload, isEditorUser }: AttachmentProps) {
  const { formatMessage } = useIntl()
  const location = useLocation()
  const [error, setError] = React.useState<BackendError>(null)
  const cPromiseRef = useCancellablePromiseRef()

  const handleDownload = React.useCallback(async () => {
    try {
      cPromiseRef.current = cancellablePromise(onDownload())
      await cPromiseRef.current.promise
    } catch (error) {
      const errorMessage = parseApiErrorMessage(error)
      if (errorMessage) {
        setError(errorMessage)
      }
    }
  }, [cPromiseRef, onDownload])

  React.useEffect(() => {
    let timer: number | undefined = undefined
    if (error) {
      timer = window.setTimeout(setError, 3000, null)
    }
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [error])

  const createdAt = (
    <>
      <FormattedDate value={data.created_at} /> <FormattedTime value={data.created_at} />
    </>
  )

  return (
    <ListItem $divider={divider}>
      <IconWrapper $isLink={Boolean(data.link)}>
        {data.link ? (
          <PublicIcon fontSize="inherit" color="inherit" data-testid="attachment-item-link-icon" />
        ) : (
          <InsertDriveFileOutlinedIcon fontSize="inherit" color="inherit" data-testid="attachment-item-file-icon" />
        )}
      </IconWrapper>
      <ListItemContent>
        <ListItemBody>
          <ListItemText>
            <Typography color="gray-80" size="700-md" tag="span">
              <PlanPermission
                perform={PagePermissions.DOKUMENTS}
                yes={() => (
                  <StyledLink
                    to={`${getRouteUrl(RouteKeys.DOKUMENT, companyId)}/${data.id}`}
                    state={{ previousPath: location.pathname }}
                  >
                    <OverflowText>
                      {data.title ||
                        (data.link
                          ? data.link
                          : formatMessage(messages.attachmentTitlePlaceholder, { documentId: data.id }))}
                    </OverflowText>
                  </StyledLink>
                )}
                no={() => (
                  <OverflowText>
                    {data.title ||
                      (data.link
                        ? data.link
                        : formatMessage(messages.attachmentTitlePlaceholder, { documentId: data.id }))}
                  </OverflowText>
                )}
              />
            </Typography>
            {data.filename && (
              <Typography size="400-xs" color="gray-40">
                <FormattedMessage
                  id="attachment.originalFileName"
                  defaultMessage="(Eredeti fájlnév: {fileName})"
                  values={{ fileName: data.filename }}
                />
              </Typography>
            )}
          </ListItemText>
          <ListItemActionsContainer>
            {data.link && (
              <LightTooltip title={GoToLinkMessage} PopperProps={{ disablePortal: true }}>
                <StyledLinkButton href={data.link} size="small">
                  <CircleArrowRegularIcon size={18} />
                </StyledLinkButton>
              </LightTooltip>
            )}
            {data.file && data.filename && (
              <ArtifactPreviewOpener
                detailsUrl={`${getRouteUrl(RouteKeys.DOKUMENT, companyId)}/${data.id}`}
                fileName={data.filename}
                path={data.file}
                previewVariant={PreviewVariant.DOKUMENT}
                title={data.title || formatMessage(messages.attachmentTitlePlaceholder, { documentId: data.id })}
              />
            )}
            {data.file && (
              <LightTooltip title={formatMessage(messages.downloadAttachment)} PopperProps={{ disablePortal: true }}>
                <StyledIconButton onClick={handleDownload} size="small">
                  <StyledFileDownloadIcon />
                </StyledIconButton>
              </LightTooltip>
            )}
            {isEditorUser && (
              <>
                <LightTooltip title={formatMessage(messages.detachAttachment)} PopperProps={{ disablePortal: true }}>
                  <StyledIconButton onClick={onDetach} size="small">
                    <DetachDokumentIcon size={18} />
                  </StyledIconButton>
                </LightTooltip>
                <FeatureDeniedForUser permission={QuickUserFeaturePermissions.denyDeletePermission}>
                  <LightTooltip title={formatMessage(messages.deleteAttachment)} PopperProps={{ disablePortal: true }}>
                    <StyledIconButton data-testid="attachment-delete-button" onClick={onDelete} size="small">
                      <TrashRegularIcon />
                    </StyledIconButton>
                  </LightTooltip>
                </FeatureDeniedForUser>
              </>
            )}
          </ListItemActionsContainer>
        </ListItemBody>
        <Typography color="gray-40" size="400-sm">
          {data.link ? (
            <FormattedMessage
              id="attachments.link.createdText"
              defaultMessage="Létrehozta {user_email} {created_at}-kor"
              values={{
                user_email: data.user_email,
                created_at: createdAt,
              }}
            />
          ) : (
            <FormattedMessage
              id="attachments.file.uploadedText"
              defaultMessage="Feltöltötte {user_email} {uploaded_at}-kor"
              values={{
                user_email: data.user_email,
                uploaded_at: createdAt,
              }}
            />
          )}
        </Typography>
      </ListItemContent>
      {error && (
        <ErrorTypography tag="span" color="error" size="400-xs">
          {error}
        </ErrorTypography>
      )}
    </ListItem>
  )
}

PureAttachment.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string,
    created_at: PropTypes.string.isRequired,
    user_email: PropTypes.string.isRequired,
    file: conditionalRequiredPropType(PropTypes.string, props => !props.link),
    link: conditionalRequiredPropType(PropTypes.string, props => !props.file),
  }).isRequired as React.Validator<BackendAttachmentFileData>,
  divider: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDetach: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  isEditorUser: PropTypes.bool.isRequired,
}

export const Attachment = connect((state: Store) => ({
  companyId: state.auth.company.data.id,
  isEditorUser: userHasEditorPermission(state.auth.company.data.role),
}))(PureAttachment)

Attachment.displayName = 'Attachment'
