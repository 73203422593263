import React from 'react'

import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import { ArrayPath, FieldValues, useFieldArray, useFormContext } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { dashboardActions } from '@services'

import { bindActionToPromise } from '@helpers'

// import { isAdvancedAccountingAvailableForUser } from '@helpers'
import {
  AddCircleIcon,
  ButtonWithIcon,
  CopyRegularIcon,
  IconButton,
  ReactHookFormAmountField,
  ReactHookFormRecommendationTextField,
  ReactHookFormSelectField,
  TrashRegularIcon,
  WarningText,
} from '@components/ui'
import { ContentWithHighlightBlock } from '@oldComponents/forms/DetailsForm/elements'

import { EMPTY_EXPENSE_ASSIGNMENT, EMPTY_INCOME_ASSIGNMENT, InvoiceType, QuickUserFeaturePermissions } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { AssignmentCategoryField } from '../AssignmentCategoryField'
import { CalculationBaseControlIconButton } from '../CalculationBaseControlIconButton'
import { CalculationBaseInputIndicator } from '../CalculationBaseInputIndicator'
import { CompanyVatCategoryField } from '../CompanyVatCategoryField'
import { JobNumberSearchField } from '../JobNumberSearchField'
import { AssignmentLedgerNumberField, VatLedgerNumberField } from '../LedgerNumberFields'
import { ReactHookFormTagField } from '../ReactHookFormTagField'
import { AssignmentsBreakdown } from './AssignmentsBreakdown'
import { InvoiceAssignmentsProps } from './types'
import { useAssignmentControls } from './useAssignmentControls'
import { useAssignmentNotes } from './useAssignmentNotes'
import { useChangeHandlers } from './useChangeHandlers'

import { CalculationBaseGrossAmountIndicatorMessage, CalculationBaseNetAmountIndicatorMessage } from '@messages'
import messages from '@oldComponents/forms/DetailsForm/messages'
import styles, { SPACING } from '@oldComponents/forms/DetailsForm/styles'

const AssignmentsWrapper = styled.div`
  margin-bottom: 1rem;
`

const StyledWarningText = styled(WarningText)`
  margin-top: 1rem;
`

type VatLabel = CompanyVatType & {
  label: string
}

function createLabelForCompanyVats(vats: CompanyVatType[]): VatLabel[] {
  return vats.map(vat => ({ ...vat, label: vat.name || `${vat.percent}%` }))
}

const useStyles = makeStyles(styles)

function PureInvoiceAssignments<FormValues extends FieldValues>({
  // initialValues, // need to determine the type of FormValues
  isMissingForAccounting = false,
  categoryTypeOptions,
  className,
  companyVats,
  hasIntegration,
  invoiceIntegrationItems,
  invoiceType,
  isAdvancedAccounting = false,
  isCategoryCreateDeniedForUser,
  isEditDisabled,
  isMissing = false,
  isNavInvoice,
  isTagsDeniedForUser,
  isUploading,
  // jobNumberOptions = [],
  loadAssignmentNoteRecommendation,
  onCreateCategoryType,
  readOnly,
}: InvoiceAssignmentsProps<FormValues>) {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const {
    control,
    formState: { isSubmitting, errors },
    getValues,
  } = useFormContext<FormValues>()
  const { fields, append, remove } = useFieldArray<FormValues>({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'assignments' as ArrayPath<FormValues>, // unique name for your Field Array
  })

  const vatOptions = React.useMemo(() => createLabelForCompanyVats(companyVats), [companyVats])

  const {
    onChangeAssignmentNetAmountHandler,
    onChangeAssignmentGrossAmountHandler,
    onChangeAssignmentVatAmountHandler,
  } = useChangeHandlers(companyVats, isAdvancedAccounting)

  const { addAssignmentHandler, getCopyAssignmentHandler } = useAssignmentControls(
    append,
    companyVats,
    getValues,
    invoiceType
  )

  const assignmentNoteRecommendations = useAssignmentNotes({
    isEnabled: isAdvancedAccounting,
    loadAssignmentNoteRecommendation,
  })

  // display controllers
  const isFieldDisabled = isSubmitting || isUploading
  const isAllFieldDisabled = readOnly || isEditDisabled || isFieldDisabled
  const isProcessedInvoice = !readOnly && isEditDisabled

  // helper for messages
  const assignmentType = formatMessage(
    messages[invoiceType === InvoiceType.INCOME ? 'revenueAssignmentType' : 'expenseAssignmentType']
  )

  return (
    <AssignmentsWrapper className={className} data-testid="invoice-assignments">
      <AssignmentsBreakdown
        companyVats={companyVats}
        emptyValues={invoiceType === InvoiceType.EXPENSE ? EMPTY_EXPENSE_ASSIGNMENT : EMPTY_INCOME_ASSIGNMENT}
        hasIntegration={hasIntegration}
        isEditDisabled={readOnly || isEditDisabled}
        isNavInvoice={isNavInvoice}
        items={invoiceIntegrationItems}
      />
      {fields.map((field, index) => (
        <div
          key={field.id}
          className={cx(classes.asssignmentBlock, {
            disabled: isEditDisabled,
            highlighted: hasIntegration,
          })}
        >
          <Grid container className={classes.assignmentBlockTitle}>
            <Grid item xs={6}>
              <h4
                className={cx({
                  [classes.isEditDisabled]: !readOnly && isEditDisabled,
                })}
              >
                <FormattedMessage
                  id="form.assignmentBlock.title"
                  defaultMessage="{type} {value}"
                  values={{
                    value: fields.length && fields.length > 1 ? index + 1 : '',
                    type: assignmentType,
                  }}
                />
              </h4>
            </Grid>
            {!readOnly && !isEditDisabled && (
              <Grid item xs={6} className={classes.blockDeleteContainer}>
                <CalculationBaseControlIconButton />
                <IconButton
                  data-testid="copy-assignment"
                  disabled={isAllFieldDisabled}
                  onClick={getCopyAssignmentHandler(index)}
                  size="small"
                  title={formatMessage(messages.itemCopyButtonTitle, {
                    type: assignmentType,
                  })}
                  variant="primaryText"
                >
                  <CopyRegularIcon />
                </IconButton>
                {fields.length && fields.length > 1 && (
                  <IconButton
                    className={classes.clearButton}
                    data-testid="remove-assignment"
                    disabled={isAllFieldDisabled}
                    onClick={() => {
                      remove(index)
                    }}
                    size="small"
                    title={formatMessage(messages.itemRemoveButtonTitle, {
                      type: assignmentType,
                    })}
                    variant="primaryText"
                  >
                    <TrashRegularIcon />
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>
          <ContentWithHighlightBlock needHighlight={isProcessedInvoice}>
            <Grid container spacing={SPACING}>
              <Grid item xs={6}>
                <AssignmentCategoryField
                  assignmentPrefix={`assignments.${index}.`}
                  assignmentType={assignmentType}
                  disabled={readOnly || isUploading}
                  invoiceType={invoiceType}
                  isCreateDeniedForUser={isCategoryCreateDeniedForUser}
                  isLabelHighlighted={!readOnly && (isProcessedInvoice || hasIntegration)}
                  onCreateCategoryType={onCreateCategoryType}
                  options={categoryTypeOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <ReactHookFormTagField
                  disabled={readOnly || isUploading}
                  name={`assignments.${index}.tags`}
                  isLabelHighlighted={!isTagsDeniedForUser && (isProcessedInvoice || hasIntegration)}
                  label={formatMessage(messages.itemTagsLabel)}
                  selectVariant="purple"
                />
              </Grid>
            </Grid>
          </ContentWithHighlightBlock>
          <Grid container spacing={SPACING}>
            <Grid item xs={4}>
              <ReactHookFormAmountField
                disabled={isAllFieldDisabled}
                endAdornment={
                  <CalculationBaseInputIndicator
                    type="gross_amount"
                    tooltip={CalculationBaseGrossAmountIndicatorMessage}
                  />
                }
                highlighted={isMissing}
                label={formatMessage(messages.grossAmountLabel)}
                isLabelHighlighted={
                  (invoiceType === InvoiceType.EXPENSE && !readOnly && hasIntegration && !isProcessedInvoice) ||
                  (invoiceType === InvoiceType.INCOME && !readOnly && (isProcessedInvoice || hasIntegration))
                }
                name={`assignments.${index}.gross_amount`}
                onChange={onChangeAssignmentGrossAmountHandler}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <ReactHookFormSelectField
                disabled={isAllFieldDisabled}
                highlighted={isMissing}
                isLabelHighlighted={
                  (invoiceType === InvoiceType.EXPENSE && !readOnly && hasIntegration && !isProcessedInvoice) ||
                  (invoiceType === InvoiceType.INCOME && !readOnly && (isProcessedInvoice || hasIntegration))
                }
                label={formatMessage(messages.vatRateLabel)}
                labelKey="label"
                name={`assignments.${index}.vat`}
                onChange={onChangeAssignmentVatAmountHandler}
                options={vatOptions}
                required
                valueKey="id"
              />
            </Grid>
            <Grid item xs={4}>
              <ReactHookFormAmountField
                disabled={isAllFieldDisabled}
                endAdornment={
                  <CalculationBaseInputIndicator type="net_amount" tooltip={CalculationBaseNetAmountIndicatorMessage} />
                }
                highlighted={isMissing}
                label={formatMessage(messages.netAmountLabel)}
                isLabelHighlighted={
                  (invoiceType === InvoiceType.EXPENSE && !readOnly && hasIntegration && !isProcessedInvoice) ||
                  (invoiceType === InvoiceType.INCOME && !readOnly && (isProcessedInvoice || hasIntegration))
                }
                name={`assignments.${index}.net_amount`}
                onChange={onChangeAssignmentNetAmountHandler}
                required
              />
            </Grid>
          </Grid>

          <Grid container spacing={SPACING} justifyContent="flex-end">
            {isAdvancedAccounting && (
              <Grid item xs={4}>
                <CompanyVatCategoryField
                  assignmentPrefix={`assignments.${index}.`}
                  disabled={isAllFieldDisabled}
                  highlighted={isMissingForAccounting}
                  invoiceType={invoiceType}
                  isLabelHighlighted={
                    (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration))
                  }
                  label={formatMessage(messages.vatCategoryLabel)}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <ReactHookFormAmountField
                disabled={isAllFieldDisabled}
                highlighted={isMissing}
                label={formatMessage(messages.vatAmountLabel)}
                isLabelHighlighted={
                  (invoiceType === InvoiceType.EXPENSE && !readOnly && hasIntegration && !isProcessedInvoice) ||
                  (invoiceType === InvoiceType.INCOME && !readOnly && (isProcessedInvoice || hasIntegration))
                }
                name={`assignments.${index}.vat_amount`}
                required
              />
            </Grid>
          </Grid>
          {isAdvancedAccounting && (
            <Grid container spacing={SPACING}>
              <Grid item xs={4}>
                <JobNumberSearchField
                  isLabelHighlighted={
                    (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration))
                  }
                  name={`assignments.${index}.job_number`}
                  disabled={isAllFieldDisabled}
                />
              </Grid>
              <Grid item xs={4}>
                <AssignmentLedgerNumberField
                  assignmentPrefix={`assignments.${index}.`}
                  categoryTypeOptions={categoryTypeOptions}
                  disabled={isAllFieldDisabled}
                  highlighted={isMissingForAccounting}
                  invoiceType={invoiceType}
                  isLabelHighlighted={
                    (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration))
                  }
                  label={formatMessage(messages.ledgerNumberLabel)}
                />
              </Grid>
              <Grid item xs={4}>
                <VatLedgerNumberField
                  assignmentPrefix={`assignments.${index}.`}
                  disabled={isAllFieldDisabled}
                  highlighted={isMissingForAccounting}
                  invoiceType={invoiceType}
                  isLabelHighlighted={
                    (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration))
                  }
                  label={formatMessage(messages.vatLedgerNumberLabel)}
                />
              </Grid>
            </Grid>
          )}
          {isAdvancedAccounting && (
            <Grid container spacing={SPACING}>
              <Grid item xs={12}>
                <ReactHookFormRecommendationTextField
                  disabled={isAllFieldDisabled}
                  isLabelHighlighted={
                    (invoiceType === InvoiceType.EXPENSE && hasIntegration && !isProcessedInvoice) ||
                    (invoiceType === InvoiceType.INCOME && (isProcessedInvoice || hasIntegration))
                  }
                  label={formatMessage(messages.commentLabel)}
                  name={`assignments.${index}.note`}
                  recommendations={assignmentNoteRecommendations}
                />
              </Grid>
            </Grid>
          )}
        </div>
      ))}
      {!readOnly && !isEditDisabled && (
        <div
          className={cx(classes.assignmentButtonBlock, {
            highlighted: hasIntegration,
          })}
        >
          <ButtonWithIcon
            data-testid="add-assignment"
            disabled={isFieldDisabled}
            icon={<AddCircleIcon />}
            onClick={addAssignmentHandler}
            size="small"
            type="button"
            variant="primaryText"
          >
            {formatMessage(messages.newItemButtonText, {
              type: assignmentType.toLowerCase(),
            })}
          </ButtonWithIcon>
        </div>
      )}
      {errors.assignments && <StyledWarningText hideIcon>{errors.assignments.message}</StyledWarningText>}
    </AssignmentsWrapper>
  )
}

export const InvoiceAssignments = connect(
  (state: Store) => ({
    // isAdvancedAccounting: isAdvancedAccountingAvailableForUser(state), // TODO need when income is open for accounting
    companyVats: state.auth.companyVats.data,
    isTagsDeniedForUser: permissionDeniedForUser(state, QuickUserFeaturePermissions.denyTagPermission),
    isUploading: state.expense.details.uploading || state.income.details.uploading,
  }),
  dispatch => ({
    loadAssignmentNoteRecommendation: bindActionToPromise(
      dispatch,
      dashboardActions.fetchAssignmentNoteRecommendations.request
    ),
  })
)(PureInvoiceAssignments)

InvoiceAssignments.displayName = 'InvoiceAssignments'
