export enum AttachmentConfirmDialogView {
  DELETE = 'DELETE',
  DETACH = 'DETACH',
}

type State = {
  actionError: BackendError
  actionLoading: boolean
  attachmentFile: Nullable<BackendAttachmentFileData>
  view: AttachmentConfirmDialogView
}

export const INITIAL_STATE: State = {
  actionError: null,
  actionLoading: false,
  attachmentFile: null,
  view: AttachmentConfirmDialogView.DELETE,
}

export enum ActionType {
  CLEAR_ERROR = 'CLEAR_ERROR',
  CLOSE_DIALOG = 'CLOSE_DIALOG',
  DELETE_ACTION_REQUEST = 'DELETE_ACTION_REQUEST',
  DETACH_ACTION_REQUEST = 'DETACH_ACTION_REQUEST',
  FAILED = 'FAILED',
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
}

type Action =
  | { type: ActionType.CLEAR_ERROR }
  | { type: ActionType.CLOSE_DIALOG }
  | { type: ActionType.DELETE_ACTION_REQUEST; payload: BackendAttachmentFileData }
  | { type: ActionType.DETACH_ACTION_REQUEST; payload: BackendAttachmentFileData }
  | { type: ActionType.FAILED; payload: BackendError }
  | { type: ActionType.REQUEST }
  | { type: ActionType.SUCCESS }

export function reducer(state: State = INITIAL_STATE, action: Action): State {
  switch (action.type) {
    case ActionType.DELETE_ACTION_REQUEST:
      return {
        ...state,
        attachmentFile: action.payload,
        view: AttachmentConfirmDialogView.DELETE,
      }

    case ActionType.DETACH_ACTION_REQUEST:
      return {
        ...state,
        attachmentFile: action.payload,
        view: AttachmentConfirmDialogView.DETACH,
      }

    //* async flow
    case ActionType.REQUEST:
      return {
        ...state,
        actionLoading: true,
        actionError: null,
      }

    case ActionType.SUCCESS:
      return {
        ...state,
        actionLoading: false,
        attachmentFile: null,
      }

    case ActionType.FAILED:
      return {
        ...state,
        actionLoading: false,
        actionError: action.payload,
      }

    case ActionType.CLEAR_ERROR:
      return {
        ...state,
        actionError: null,
      }

    case ActionType.CLOSE_DIALOG:
      return {
        ...state,
        attachmentFile: null,
      }

    default:
      return state
  }
}
