import React from 'react'

import __debounce from 'lodash/debounce'

import { cancellablePromise, parseApiErrorMessage } from '@helpers'

import { useAlertDispatch } from '@contexts/AlertProvider'

import { useCancellablePromiseRef } from './useCancellablePromiseRef'

export interface DebounceLoadConfig {
  debounceTime?: number
  loadBehavior?: 'everyOpen' | 'firstOpen'
}

/**
 * Custom hook to load data with debounce and cancellable promise support.
 *
 * @template Results - The type of the results returned by the async load function.
 * @param {AsyncFunction<void, Results>} asyncLoadData - The asynchronous function to load data.
 * @param {DebounceLoadConfig} [config] - Configuration object for debounce time and load behavior.
 * @param {number} [config.debounceTime=400] - The debounce time in milliseconds.
 * @param {'everyOpen' | 'firstOpen'} [config.loadBehavior='everyOpen'] - The load behavior, either 'everyOpen' or 'firstOpen'. 'everyOpen' will load data every time the handleOpen function is called, while 'firstOpen' will only load data the first time the handleOpen function is called.
 * @returns {object} - An object containing the fetched state, loading state, results, handleOpen, and handleClose functions.
 * @returns {boolean} return.fetched - Indicates if the data has been fetched.
 * @returns {boolean} return.loading - Indicates if the data is currently being loaded.
 * @returns {Nullable<Results>} return.results - The results of the async load function.
 * @returns {function} return.handleOpen - Function to handle opening and triggering the data load.
 * @returns {function} return.handleClose - Function to handle closing and cancelling the data load.
 */
export function useDebouncedLoadData<Results>(
  asyncLoadData: AsyncFunction<void, Results>,
  { debounceTime = 400, loadBehavior = 'everyOpen' }: DebounceLoadConfig = {}
) {
  const [{ fetched, loading, open, results }, setState] = React.useState<{
    fetched: boolean
    loading: boolean
    open: boolean
    results: Nullable<Results>
  }>({
    fetched: false,
    loading: false,
    open: false,
    results: null,
  })
  const { setErrorAlert } = useAlertDispatch()
  const cPromiseRef = useCancellablePromiseRef<Results>()

  const debouncedLoadHistoryRef = React.useRef(
    __debounce(async () => {
      setState(prevState => ({ ...prevState, loading: true }))
      try {
        cPromiseRef.current = cancellablePromise(asyncLoadData())
        const results = await cPromiseRef.current.promise

        setState(prevState => ({ ...prevState, fetched: true, loading: false, results }))
      } catch (error) {
        const errorMessage = parseApiErrorMessage(error)
        if (errorMessage) {
          setErrorAlert(errorMessage)
        }
      }
    }, debounceTime)
  )

  React.useEffect(
    () => () => {
      debouncedLoadHistoryRef.current.cancel()
    },
    []
  )

  const handleOpen = React.useCallback(() => {
    // to prevent continuous fetching while hovering in interactive tooltip content
    if ((loadBehavior === 'everyOpen' && !open) || (loadBehavior === 'firstOpen' && !fetched)) {
      setState(prevState => ({ ...prevState, open: true }))
      debouncedLoadHistoryRef.current()
    }
  }, [fetched, loadBehavior, open])

  const handleClose = React.useCallback(() => {
    setState(prevState => ({ ...prevState, open: false }))
    debouncedLoadHistoryRef.current.cancel()
  }, [])

  return React.useMemo(
    () => ({ fetched, loading, results, handleOpen, handleClose }),
    [fetched, handleClose, handleOpen, loading, results]
  )
}
