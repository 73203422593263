import React from 'react'

import { FormattedMessage } from 'react-intl'
import { Column } from 'react-table'

import { isInvoiceTransactionType } from './helpers'

import {
  BankAccountNumberTableHeaderMessage,
  DueAtTableHeaderMessage,
  InvoiceNumberTableHeaderMessage,
  PartnerTableHeaderMessage,
  SecondaryIdTableHeaderMessage,
} from '@messages'

export enum CreateBankPaymentTableAccessors {
  AMOUNT = 'amount',
  DUE_AT = 'dueAt',
  GROSS_AMOUNT = 'grossAmount',
  PARTNER = 'partnerName',
  PARTNER_ACCOUNT_NUMBER = 'partnerAccountNumber',
  PREVIEW = 'preview',
  REMAINING = 'remaining',
  ROW_DATA = 'rowData',
  SECONDARY_ID = 'secondaryId',
  TITLE = 'title',
}

export interface CreateBankPaymentTableRow {
  [CreateBankPaymentTableAccessors.AMOUNT]: React.ReactChild
  [CreateBankPaymentTableAccessors.DUE_AT]: React.ReactChild
  [CreateBankPaymentTableAccessors.GROSS_AMOUNT]: React.ReactChild
  [CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER]: React.ReactNode
  [CreateBankPaymentTableAccessors.PARTNER]: React.ReactChild
  [CreateBankPaymentTableAccessors.PREVIEW]: React.ReactNode
  [CreateBankPaymentTableAccessors.REMAINING]: React.ReactChild
  [CreateBankPaymentTableAccessors.ROW_DATA]: PaymentTransaction
  [CreateBankPaymentTableAccessors.SECONDARY_ID]: React.ReactNode
  [CreateBankPaymentTableAccessors.TITLE]: React.ReactNode
}

type CreateBankPaymentTableColumn = Column<CreateBankPaymentTableRow>

const ExpenseAmountHeaderMessage = <FormattedMessage id="payment.table.th.amount" defaultMessage="Fizetendő összeg" />
const IncomeAmountHeaderMessage = (
  <FormattedMessage id="payment.table.th.amount.income" defaultMessage="Fizetett összeg" />
)
const GrossAmountHeaderMessage = <FormattedMessage id="payment.table.th.grossAmount" defaultMessage="Érték" />
const RemainingHeaderMessage = <FormattedMessage id="payment.table.th.remaining" defaultMessage="Még fizetendő" />
const OutstandingHeaderMessage = <FormattedMessage id="payment.table.th.outstanding" defaultMessage="Kintlévőség" />

const AMOUNT_CELL_WIDTH = 180

/**
 * Returns an array of table columns based on the provided parameters.
 *
 * @param {Object} options - The options object.
 * @param {boolean} options.isSecondaryIdVisible - Controls the visibility of CreateBankPaymentTableAccessors.SECONDARY_ID column.
 * @param {boolean} options.isSummary - Indicates whether the table is in summary mode.
 * @param {boolean} options.isTransferFlow - Controls the visibility of CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER column.
 * @param {PaymentTransactionType} options.transactionType - The type of payment transaction (expense | salary | tax).
 *
 * @returns {CreateBankPaymentTableColumn[]} An array of table columns.
 */
export function getTableColumns({
  isSecondaryIdVisible, // control CreateBankPaymentTableAccessors.SECONDARY_ID visibility
  isSummary,
  isTransferFlow, // control CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER visibility
  transactionType,
}: {
  isSecondaryIdVisible: boolean
  isSummary: boolean
  isTransferFlow: boolean
  transactionType: AllPaymentTransactionType
}): CreateBankPaymentTableColumn[] {
  if (isInvoiceTransactionType(transactionType)) {
    // Expense Table Columns
    return [
      {
        accessor: CreateBankPaymentTableAccessors.ROW_DATA,
      },
      {
        accessor: CreateBankPaymentTableAccessors.PREVIEW,
        width: 33,
      },
      {
        Header: PartnerTableHeaderMessage,
        accessor: CreateBankPaymentTableAccessors.PARTNER,
        width: isTransferFlow ? 180 : 270,
      },
      {
        Header: InvoiceNumberTableHeaderMessage,
        accessor: CreateBankPaymentTableAccessors.TITLE,
        width: isTransferFlow ? (isSecondaryIdVisible ? 145 : 210) : isSecondaryIdVisible ? 210 : 260,
      },
      {
        Header: SecondaryIdTableHeaderMessage,
        accessor: CreateBankPaymentTableAccessors.SECONDARY_ID,
        width: isTransferFlow ? 145 : 220, //* isSecondaryIdVisible
      },
      {
        Header: BankAccountNumberTableHeaderMessage,
        accessor: CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER,
        width: isSummary ? 280 : 310, //* isTransferFlow
      },
      {
        Header: DueAtTableHeaderMessage,
        accessor: CreateBankPaymentTableAccessors.DUE_AT,
        width: isSecondaryIdVisible ? 90 : 120,
      },
      {
        Header: GrossAmountHeaderMessage,
        accessor: CreateBankPaymentTableAccessors.GROSS_AMOUNT,
        width: 'auto',
        align: 'right',
      },
      {
        Header: transactionType === 'expense' ? RemainingHeaderMessage : OutstandingHeaderMessage,
        accessor: CreateBankPaymentTableAccessors.REMAINING,
        width: 'auto',
        align: 'right',
      },
      {
        Header: transactionType === 'expense' ? ExpenseAmountHeaderMessage : IncomeAmountHeaderMessage,
        accessor: CreateBankPaymentTableAccessors.AMOUNT,
        width: isSummary ? 'auto' : AMOUNT_CELL_WIDTH,
        align: 'right',
      },
    ]
  }

  // salary and tax tables
  return [
    {
      accessor: CreateBankPaymentTableAccessors.ROW_DATA,
    },
    {
      Header: <FormattedMessage id="payment.salaryTax.table.th.title" defaultMessage="Tárgyhó" />,
      accessor: CreateBankPaymentTableAccessors.TITLE,
      width: isTransferFlow ? 140 : 170,
    },
    {
      Header: <FormattedMessage id="payment.salaryTax.table.th.partnerName" defaultMessage="Név" />,
      accessor: CreateBankPaymentTableAccessors.PARTNER,
      width: 'auto',
    },
    {
      Header: BankAccountNumberTableHeaderMessage,
      accessor: CreateBankPaymentTableAccessors.PARTNER_ACCOUNT_NUMBER,
      width: isSummary ? 280 : 310,
    },
    {
      Header: DueAtTableHeaderMessage,
      accessor: CreateBankPaymentTableAccessors.DUE_AT,
      width: 120,
    },
    {
      Header: GrossAmountHeaderMessage,
      accessor: CreateBankPaymentTableAccessors.GROSS_AMOUNT,
      width: 'auto',
      align: 'right',
    },
    {
      Header: RemainingHeaderMessage,
      accessor: CreateBankPaymentTableAccessors.REMAINING,
      width: 'auto',
      align: 'right',
    },
    {
      Header: ExpenseAmountHeaderMessage,
      accessor: CreateBankPaymentTableAccessors.AMOUNT,
      width: isSummary ? 'auto' : AMOUNT_CELL_WIDTH,
      align: 'right',
    },
  ]
}
