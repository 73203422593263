import React from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import { NavLink, NoLinkTooltip } from '@components/navigations'
import { NavLinkTooltipVariant } from '@components/navigations/constants'

import { QuickUserPagePermissions, RouteKeys, ROUTES } from '@constants'

import { PagePermissions, PlanPermission } from '@permissions'

import { useStyles } from '../styles'

const CashFlowNavLinkMessage = <FormattedMessage id="navLink.paid_through" defaultMessage="Pénzmozgások" />

interface CashFlowNavLinksProps {
  hasSubscription: boolean
  lastVisitedSubPage?: string
}

function PureCashFlowNavLinks({ hasSubscription, lastVisitedSubPage }: CashFlowNavLinksProps) {
  const classes = useStyles()

  return (
    <PlanPermission
      perform={PagePermissions.PAYMENTS}
      yes={() => (
        <NavLink
          to={lastVisitedSubPage ?? ROUTES.paymentOrders}
          className={classes.navLink}
          permission={QuickUserPagePermissions.denyCashflowPermission}
          noSubscription={!hasSubscription}
        >
          <span className={classes.link}>{CashFlowNavLinkMessage}</span>
        </NavLink>
      )}
      no={() => (
        <NoLinkTooltip variant={NavLinkTooltipVariant.UPSELL}>
          <span className={classes.link}>{CashFlowNavLinkMessage}</span>
        </NoLinkTooltip>
      )}
    />
  )
}

PureCashFlowNavLinks.propTypes = {
  hasSubscription: PropTypes.bool.isRequired,
  lastVisitedSubPage: PropTypes.string,
}

export const CashFlowNavLinks = connect((state: Store) => ({
  lastVisitedSubPage: state.controls.lastVisitedSubPage[RouteKeys.CASHFLOW],
}))(PureCashFlowNavLinks)

CashFlowNavLinks.displayName = 'CashFlowNavLinks'
