import React from 'react'

import { CircularProgress } from '@material-ui/core'
import { FormattedDate, FormattedTime } from 'react-intl'
import styled from 'styled-components'

import { Typography } from '@components/ui/Typography'

import ContentWithLoadingOverlay from '../ContentWithLoadingOverlay'

const PostItHistoryWrapperDiv = styled.div`
  white-space: normal;
  overflow-y: auto;
  max-height: 250px;
  min-width: 200px;
  padding: 8px;
`

const CardDiv = styled.div`
  & + & {
    border-top: 1px solid ${({ theme }) => theme.colors.common.paperStroke};
    padding-top: 8px;
    margin-top: 8px;
  }

  ${Typography}:nth-child(2) {
    margin-bottom: 4px;
  }
`

const EmptyTooltipWrapper = styled.div`
  padding: 8px;
`

export interface PostItHistoryData {
  id: number
  created_at: string
  user_email: string
  note: string
}

interface AsyncPostitHistoryProps {
  data: Nullable<PostItHistoryData[]>
  fetched: boolean
  loading: boolean
}

export function AsyncPostitHistory({ data, fetched, loading }: AsyncPostitHistoryProps) {
  if (!data || !fetched) {
    return (
      <EmptyTooltipWrapper>
        <CircularProgress size={16} color="primary" />
      </EmptyTooltipWrapper>
    )
  }
  return (
    <ContentWithLoadingOverlay loading={loading} size={16}>
      <PostItHistoryWrapperDiv>
        {data.map(postit => (
          <CardDiv key={`postit-${postit.id}`}>
            <Typography size="700-xxs" color="gray-80">
              <FormattedDate value={postit.created_at} /> <FormattedTime value={postit.created_at} />
            </Typography>
            <Typography size="700-xxs" color="gray-80">
              {postit.user_email}
            </Typography>
            <Typography size="400-xs">{postit.note}</Typography>
          </CardDiv>
        ))}
      </PostItHistoryWrapperDiv>
    </ContentWithLoadingOverlay>
  )
}
