import React from 'react'
import PropTypes from 'prop-types'

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import styled, { DefaultTheme } from 'styled-components'

import { QUICK_TYPOGRAPHY } from '@constants'

import { Typography, TYPOGRAPHY_COLOR, TypographyProps } from '../Typography'

const INFO_TEXT_COLOR = {
  SECONDARY_40: 'secondary-40',
  GRAY_40: 'gray-40',
} as const

type InfoTextColor = (typeof INFO_TEXT_COLOR)[keyof typeof INFO_TEXT_COLOR]

// Function to get icon color
function getIconColor(theme: DefaultTheme, color?: InfoTextColor) {
  switch (color) {
    case INFO_TEXT_COLOR.GRAY_40:
      return theme.colors.gray[40]
    case INFO_TEXT_COLOR.SECONDARY_40:
    default:
      return theme.colors.blue[40]
  }
}

const ICON_SIZE = '24px'

const Wrapper = styled.div`
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  color: ${({ theme }) => theme.colors.secondary[5]};
`

const StyledTypography = styled(Typography)<{ $typographyLineHeight: string }>`
  margin-top: max(0px, calc((${ICON_SIZE} - ${({ $typographyLineHeight }) => $typographyLineHeight}) / 2));
`

const ContentWrapperDiv = styled.div<{ $firstRowLineHeight?: string }>`
  margin-top: ${({ $firstRowLineHeight }) =>
    $firstRowLineHeight ? `max(0px, calc((${ICON_SIZE} -  ${$firstRowLineHeight}) / 2))` : '0'};
`

const StyledInfoIcon = styled(InfoOutlinedIcon)<{ $colorProp: InfoTextColor }>`
  color: ${({ theme, $colorProp }) => getIconColor(theme, $colorProp)};
  font-size: ${ICON_SIZE};
`

export interface InfoTextProps {
  children: TypographyProps['children']
  className?: string
  color?: TypographyProps['color']
  iconColor?: InfoTextColor
  size?: TypographyProps['size']
}

export function InfoText({
  children,
  className,
  color,
  iconColor = INFO_TEXT_COLOR.SECONDARY_40,
  size = '400-xs',
}: InfoTextProps) {
  return (
    <Wrapper className={className}>
      <StyledInfoIcon $colorProp={iconColor} />
      <StyledTypography size={size} tag="span" color={color} $typographyLineHeight={QUICK_TYPOGRAPHY[size].lineHeight}>
        {children}
      </StyledTypography>
    </Wrapper>
  )
}

InfoText.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.values(TYPOGRAPHY_COLOR)),
  iconColor: PropTypes.oneOf(Object.values(INFO_TEXT_COLOR)),
  size: PropTypes.oneOf(Object.keys(QUICK_TYPOGRAPHY)),
}

export interface CustomInfoProps {
  children: React.ReactNode
  className?: string
  firstRowLineHeight?: string
  iconColor?: InfoTextColor
}

export function CustomInfo({
  children,
  className,
  firstRowLineHeight,
  iconColor = INFO_TEXT_COLOR.SECONDARY_40,
}: CustomInfoProps) {
  return (
    <Wrapper className={className}>
      <StyledInfoIcon $colorProp={iconColor} />
      <ContentWrapperDiv $firstRowLineHeight={firstRowLineHeight}>{children}</ContentWrapperDiv>
    </Wrapper>
  )
}

export interface MultilineInfoTextProps extends Omit<CustomInfoProps, 'children'> {
  children: TypographyProps['children'][]
  color?: TypographyProps['color']
  size?: TypographyProps['size']
}

export function MultilineInfoText({ children, color, size = '400-xs', ...rest }: MultilineInfoTextProps) {
  return (
    <CustomInfo {...rest} firstRowLineHeight={QUICK_TYPOGRAPHY[size].lineHeight}>
      {children.map((child, index) => (
        <Typography key={index} size={size} color={color}>
          {child}
        </Typography>
      ))}
    </CustomInfo>
  )
}
