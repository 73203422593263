import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import { matchPath, NavLink as RouterLink, NavLinkProps, To, useLocation } from 'react-router-dom'
import { Action } from 'redux'

import { replaceCompanyIdInPath } from '@helpers'

import { QuickUserPagePermissions, ROUTES } from '@constants'

import { permissionDeniedForUser } from '@permissions'

import { NavLinkTooltipVariant } from '../constants'
import { NoLinkTooltip } from './NoLinkTooltip'

type PermissionFunction = (payload: Company) => boolean

interface CustomNavLinkProps extends Omit<NavLinkProps, 'to'> {
  activeWhenNoCompany?: boolean
  children: React.ReactNode
  currentCompany?: Company
  dispatch: React.Dispatch<Action>
  hasCompany: boolean
  inactive: boolean
  linkWhenNoCompany?: boolean
  noSubscription?: boolean
  permission?: QuickUserPagePermissions | PermissionFunction
  to: string
}

function PureCustomNavLink({
  activeWhenNoCompany = false,
  currentCompany,
  dispatch, // remove it from rest
  inactive,
  hasCompany,
  linkWhenNoCompany = false,
  noSubscription = false,
  permission,
  to,
  ...rest
}: CustomNavLinkProps) {
  const location = useLocation()
  const match = matchPath(to, location.pathname)

  if (inactive && !linkWhenNoCompany) {
    return <NoLinkTooltip variant={NavLinkTooltipVariant.SELECT}>{rest.children}</NoLinkTooltip>
  }

  const companyId = currentCompany?.id
  const navLinkTo = linkWhenNoCompany ? to : companyId ? replaceCompanyIdInPath(to, companyId) : ROUTES.profile

  let routerLinkTo: To = navLinkTo
  if (match) {
    // when route match build a complete Path object as routerLinkTo to copy searchParams (filters) and hash (optional) to remember the full url
    // otherwise link forgets the UrlSearchParams when repeatedly click on it
    routerLinkTo = {
      pathname: navLinkTo,
      search: location.search,
      hash: location.hash,
    }
  }

  if (permission && currentCompany) {
    // it calls denyTransactionsPermission: hide menu item when it returns true
    let isMenuItemHidden = false
    if (typeof permission === 'function') {
      isMenuItemHidden = permission(currentCompany)
    } else {
      isMenuItemHidden = permissionDeniedForUser(currentCompany, permission as QuickUserPagePermissions, true)
    }
    if (isMenuItemHidden) {
      return null
    }
  }

  if (noSubscription) {
    return <NoLinkTooltip variant={NavLinkTooltipVariant.SUBSCRIPTION}>{rest.children}</NoLinkTooltip>
  }

  if (companyId || linkWhenNoCompany) {
    return <RouterLink {...rest} to={routerLinkTo} />
  }
  if (hasCompany) {
    if (activeWhenNoCompany) {
      return <RouterLink {...rest} to={ROUTES.root} />
    }
    return <NoLinkTooltip variant={NavLinkTooltipVariant.SELECT}>{rest.children}</NoLinkTooltip>
  }
  if (activeWhenNoCompany) {
    return <RouterLink {...rest} to={ROUTES.noCompany} />
  }
  return <NoLinkTooltip variant={NavLinkTooltipVariant.COMPANY}>{rest.children}</NoLinkTooltip>
}

PureCustomNavLink.propTypes = {
  activeWhenNoCompany: PropTypes.bool,
  children: PropTypes.node.isRequired,
  currentCompany: PropTypes.shape({
    id: PropTypes.number.isRequired,
    deny_expense_permission: PropTypes.bool.isRequired,
    deny_nav_permission: PropTypes.bool.isRequired,
    deny_income_permission: PropTypes.bool.isRequired,
    deny_salary_permission: PropTypes.bool.isRequired,
    deny_tax_permission: PropTypes.bool.isRequired,
    deny_liquidity_permission: PropTypes.bool.isRequired,
    deny_partner_permission: PropTypes.bool.isRequired,
    deny_analytics_permission: PropTypes.bool.isRequired,
    deny_cashflow_permission: PropTypes.bool.isRequired,
    deny_report_permission: PropTypes.bool.isRequired,
  }) as React.Validator<Company | undefined>,
  inactive: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  hasCompany: PropTypes.bool.isRequired,
  linkWhenNoCompany: PropTypes.bool,
  noSubscription: PropTypes.bool,
  permission: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.oneOf(Object.values(QuickUserPagePermissions)).isRequired,
  ]),
  to: PropTypes.string.isRequired,
}

export const NavLink = connect(
  ({
    auth: {
      companies,
      company: { data: currentCompany, fetched },
    },
  }: Store) => ({
    currentCompany,
    inactive: !fetched,
    hasCompany: Boolean(companies.length),
  })
)(PureCustomNavLink)

NavLink.displayName = 'CustomNavLink'
