import React from 'react'

import { connect } from 'react-redux'
import styled from 'styled-components'

import { userHasEditorPermission } from '@helpers/authorization'

import { Accordion, AdvancedApprovingStatus, ApprovalsList, FormBlock } from '@components/ui'

const StyledFormBlock = styled(FormBlock)`
  background-color: ${({ theme }) => theme.colors.gray[0]};
  margin-top: -1rem;
`

const AccordionContentWrapper = styled.div`
  padding: 12px 0 0 28px;
`

interface AdvancedApprovalFormBlockProps {
  approvals: Array<{ email: string; approved_at: Nullable<string> }> | undefined
  approvalStatus: ExpenseApprovalStatus | undefined
  isApproved: boolean
  isEditorUser: boolean
}

function PureAdvancedApprovalFormBlock({
  approvals = [],
  approvalStatus,
  isApproved,
  isEditorUser,
}: AdvancedApprovalFormBlockProps) {
  return (
    <StyledFormBlock data-testid="advanced-approval-form-block">
      <Accordion
        variant="plain"
        headerContent={<AdvancedApprovingStatus isApproved={isApproved} status={approvalStatus} />}
      >
        <AccordionContentWrapper>
          <ApprovalsList data={approvals} showEditorInfoWhenEmpty={isEditorUser && !isApproved} />
        </AccordionContentWrapper>
      </Accordion>
    </StyledFormBlock>
  )
}

export const AdvancedApprovalFormBlock = connect((state: Store) => ({
  approvals: state.expense.details.meta.approvals,
  approvalStatus: state.expense.details.meta.approval_status,
  isApproved: Boolean(state.expense.details.meta.is_approved),
  isEditorUser: userHasEditorPermission(state.auth.company.data.role),
}))(PureAdvancedApprovalFormBlock)

AdvancedApprovalFormBlock.displayName = 'AdvancedApprovalFormBlock'
