import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'

import { SzamlazzHuIcon } from '@components/ui/svgIcons'

import { PARTNER_SOURCES } from '@constants'

import { LightTooltip } from './LightTooltip'
import { BillingoIcon, ManualSourceIcon, QuickSourceIcon } from './SvgIcons'

const useStyles = makeStyles(() => ({
  root: {
    '&>svg': { transform: 'translate(0, 2px)' },
  },
}))

type PartnerSourceVariant = Nullable<(typeof PARTNER_SOURCES)[keyof typeof PARTNER_SOURCES]>

function getTooltipContent(variant: PartnerSourceVariant) {
  switch (variant) {
    case PARTNER_SOURCES.SOURCE_SZAMLAZZ:
      return {
        title: (
          <FormattedMessage
            id="partnerSource.tooltips.szlahu"
            defaultMessage="Ezt a partnert a Számlázz.hu rendszeréből szinkronizáltuk"
          />
        ),
        icon: <SzamlazzHuIcon />,
      }

    case PARTNER_SOURCES.SOURCE_BILLINGO:
      return {
        title: (
          <FormattedMessage
            id="partnerSource.tooltips.billingo"
            defaultMessage="Ezt a partnert a Billingo rendszeréből szinkronizáltuk"
          />
        ),
        icon: <BillingoIcon />,
      }

    case PARTNER_SOURCES.SOURCE_QUICK:
      return {
        title: (
          <FormattedMessage
            id="partnerSource.tooltips.quickAutoGenerated"
            defaultMessage="Ezt a partnert a QUiCK automatikusan hozta létre"
          />
        ),
        icon: <QuickSourceIcon />,
      }

    default:
      return {
        title: <FormattedMessage id="partnerSource.tooltips.manual" defaultMessage="Kézzel rögzített partner" />,
        icon: <ManualSourceIcon />,
      }
  }
}

export function PartnerSource({ variant }: { variant: PartnerSourceVariant }) {
  const classes = useStyles()
  const { title, icon } = getTooltipContent(variant)

  return (
    <LightTooltip title={title} PopperProps={{ disablePortal: true }}>
      <div className={classes.root}>{icon}</div>
    </LightTooltip>
  )
}

PartnerSource.propTypes = {
  variant: PropTypes.oneOf(Object.values(PARTNER_SOURCES)),
}
